import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loader from "../../components/Loader";
import OTPInput from "../../components/OTPInput";
import FlagsSelectField from "../../components/FlagsSelectField";
import CountdownTimer from "../../components/CountdownTimer";
import OTPMessage from "../../components/core/OTPMessage";
import { removeSnackbarDetails } from "../../features/snackbar/snackbarSlice";
import { userByMobile, generateOTP, verifyOTP } from "../../APIs/auth";
import { OTPLength } from "../../constants/enum";
import styles from "./Signup.module.scss";

const Signup = () => {
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOTPScreenLoading, setOTPScreenLoading] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [isOTPReceived, setIsOTPReceived] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleOTP = (otp) => setOTP(otp);

  const backToHome = () => {
    dispatch(
      removeSnackbarDetails({
        open: false,
        message: "",
        hideDuration: 0,
        severity: "",
      })
    );
    history.push("/");
  };

  const checkUserExist = () => {
    const query = `${encodeURIComponent("+")}${phone}`;

    setLoading(true);
    userByMobile(query)
      .then((res) => {
        if (res?.status === 200 && Object.keys(res.data).length > 0) {
          setLoading(false);
          setUserExist(true);
        }
      })
      .catch((e) => {
        const response = e.response;

        if (
          response?.status === 400 &&
          response?.data.message === "User does not exist"
        ) {
          setLoading(false);
          setUserExist(false);
          handleOTPGeneration();
        }
      });
  };

  const handleOTPGeneration = () => {
    const payload = {
      userName: `+${phone}`,
      isTwilio: false,
    };

    if (isOTPReceived) {
      setIsOTPReceived(false);
    }

    setOTPScreenLoading(true);
    generateOTP(payload)
      .then((res) => {
        if (res?.status === 200) {
          setIsOTPReceived(true);
          setOTPScreenLoading(false);
        }
      })
      .catch((_e) => setOTPScreenLoading(false));
  };

  const handleOTPVerification = () => {
    const payload = {
      userName: `+${phone}`,
      otp: OTP,
    };

    setOTPScreenLoading(true);
    verifyOTP(payload)
      .then((res) => {
        if (res?.status === 200) {
          setOTPScreenLoading(false);

          history.push(`/profile`, {
            mobileNumber: phone,
          });
        }
      })
      .catch((e) => {
        const response = e.response;

        if (response?.status === 400) {
          setOTPScreenLoading(false);
        }
      });
  };

  const isTimerFinished = (status) => setTimerFinished(status);

  useEffect(() => {
    if (OTP.length === 6) {
      handleOTPVerification();
    }
  }, [OTP]);

  return (
    <div className={styles.SignupWrapper}>
      <Loader loading={isOTPScreenLoading} />
      <IconButton color="primary" onClick={backToHome}>
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <div className={styles.SignupContainer}>
        {!isOTPReceived && !isOTPScreenLoading ? (
          <>
            <Typography color="primary" variant="h2" className={styles.Heading}>
              Mobile Number
            </Typography>
            <FlagsSelectField phone={phone} setPhone={setPhone} />
            {isUserExist && (
              <small className={`${styles.WarningMsg} ${styles.UserExist}`}>
                User already exists with this number
              </small>
            )}
            <div className={styles.ButtonContainer}>
              <Button
                disabled={!(phone?.length > 0) || loading}
                color="primary"
                variant="contained"
                onClick={checkUserExist}
                className={styles.SignupNxtBtn}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <>
            <Typography color="primary" variant="h2" className={styles.Heading}>
              Enter OTP
            </Typography>
            <div>
              <OTPInput
                autoFocus
                isNumberInput
                length={OTPLength}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(otp) => handleOTP(otp)}
              />
              {isOTPReceived && (
                <small className={styles.WarningMsg}>
                  OTP has been sent to your number
                </small>
              )}
              {!isOTPScreenLoading && (
                <div className={styles.OTPTimerContainer}>
                  <CountdownTimer isTimerFinished={isTimerFinished} />
                  <OTPMessage
                    handleOTPGeneration={handleOTPGeneration}
                    timerFinished={timerFinished}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Signup;
