import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  useMap,
  // useMapEvent,
} from "react-leaflet";
import { cellToBoundary } from "h3-js";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

const Search = (props) => {
  const map = useMap(); // access to leaflet map
  const { provider } = props;

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
    });

    map.addControl(searchControl); // this is how you add a control in vanilla leaflet
    return () => map.removeControl(searchControl);
  }, [props]);

  return null; // don't want anything to show up from this comp
};

const Hexagon = (props) => {
  const { H3index, selected, onStateChange, clickable, id } = props;
  const [isSelected, setIsSelected] = useState(false);
  const coordinates = cellToBoundary(H3index);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <Polygon
      pathOptions={
        clickable
          ? {
              fillColor: "#FF6600",
              color: "#1176AE",
              fillOpacity: isSelected ? 0.3 : 0,
            }
          : { fillColor: "#F34964", color: "#1176AE", fillOpacity: 0.3 }
      }
      positions={coordinates}
      eventHandlers={{
        click: () => {
          if (clickable) {
            onStateChange(!isSelected, H3index, selected, id);
            setIsSelected(!isSelected);
          }
        },
      }}
    />
  );
};

const MyComponent = (props) => {
  // const { changeEvent } = props;
  // const map = useMap();
  // const mapMove = useMapEvent("moveend", changeEvent);
  // const mapZoom = useMapEvent('zoomend',changeEvent)
  return null;
};

const H3Mapped = (props) => {
  const {
    hexArray,
    onHexClick,
    intialCoordinates = [31.3524343807061, 74.500075416248],
    initalZoom = 12,
    scrollZoom = false,
    changeEvent = () => {},
  } = props;

  return (
    <MapContainer
      center={intialCoordinates}
      minZoom={11}
      zoom={initalZoom}
      scrollWheelZoom={scrollZoom}
      style={{ height: "70vh" }}
      whenReady={changeEvent}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
      />
      {hexArray.map(({ H3index, selected, _clickable, id }) => (
        <Hexagon
          H3index={H3index}
          selected={selected}
          onStateChange={onHexClick}
          clickable={true}
          id={id}
        ></Hexagon>
      ))}
      <Search provider={new OpenStreetMapProvider()} />
      <MyComponent changeEvent={changeEvent} />
    </MapContainer>
  );
};

export default H3Mapped;
