import axios from "axios";
import CONSTANT from "../constants";
import { store } from "../store";
import { setSnackbarDetails } from "../features/snackbar/snackbarSlice";
import { logout, getLocalStorage } from "../Utilities";

const axiosInstance = axios.create({
  baseURL: CONSTANT.baseURL,
  headers: {
    SecretKey: process.env.REACT_APP_SECRET_KEY,
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = getLocalStorage("auth-token");

    if (token) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    if (!error?.response) {
      store.dispatch(
        setSnackbarDetails({
          open: true,
          // message: error.message,
          message: "Please check your internet connection",
          severity: "error",
        })
      );

      return Promise.reject(error);
    }

    if (error?.response) {
      const errorResponse = error.response.data;
      let errorMessage;

      if (error.response.status === 401) return logout();

      if (
        error.response?.request?.responseURL.includes(
          "SellerPayment/SellerPaymentDueDetails"
        )
      ) {
        return;
      }

      if (
        error.response?.request?.responseURL.includes(
          "SellerLeadTimeH3/SellerLeadTimesH3"
        )
      ) {
        return;
      }

      if (typeof errorResponse === "object") {
        if (typeof errorResponse["error"] === "object") {
          errorMessage = errorResponse["error"]["response"]["message"] || "";
        } else {
          errorMessage =
            errorResponse["message"] ||
            errorResponse["Exception"] ||
            errorResponse["error"];
        }
      } else {
        errorMessage = errorResponse;
      }

      store.dispatch(
        setSnackbarDetails({
          open: true,
          message: errorMessage,
          severity: "error",
        })
      );

      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
