import {
  GET_SELLER_INVOICES,
  GET_INVOICE_DETAILS,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getSellerInvoices = (query) => {
  const { startDate, endDate } = query;

  return axiosInstance.get(
    `api${GET_SELLER_INVOICES}?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getSellerInvoice = (query) => {
  const { invoiceId } = query;

  return axiosInstance.get(`api${GET_INVOICE_DETAILS}?invoiceId=${invoiceId}`);
};
