import config from "../config";

const Env = {
  baseURL: config.baseURL,
  tradeURL: config.tradeURL,
  logisticURL: config.logisticURL,
  growthURL: config.growthURL,
  backofficeURL: config.backofficeURL,
};

export default Env;
