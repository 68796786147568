import TextField from "@material-ui/core/TextField";
import style from "../Profile.module.scss";

const BankDetail = (props) => {
  const { bank, setBank, errors } = props;

  const handleChange = (type, value) => {
    setBank((previousBank) => {
      return {
        ...previousBank,
        [type]: value,
      };
    });
  };

  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Bank Details</h1>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          className={style.TextField}
          name="accountTitle"
          value={bank.accountTitle}
          label="Account Title"
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.accountTitle)}
          helperText={errors && errors.accountTitle}
        />
        <TextField
          required
          className={style.TextField}
          name="ibanNo"
          value={bank.ibanNo}
          label="IBAN Number"
          variant="outlined"
          max={24}
          onChange={(e) => {
            const type = e.target.name;
            const value = e.target.value;

            if (value.length <= 24) {
              handleChange(type, value);
            }
          }}
          error={Boolean(errors && errors.ibanNo)}
          helperText={errors && errors.ibanNo}
        />
      </form>
    </div>
  );
};

export default BankDetail;
