import { useState } from "react";
import {
  Button,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import BackupIcon from "@material-ui/icons/Backup";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import { importBulkListing } from "../../../APIs/back-office";
import { exportCSV, getLocalStorage } from "../../../Utilities";
import styles from "./Modal.module.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const bulkOperationType = {
  1: "BulkUpload",
  2: "BulkDelete",
  3: "BulkEdit",
};

const BulkListingUploadModal = ({
  isOpen,
  modalSize,
  modalTitle,
  closeModal,
  renderViewListing,
}) => {
  const [file, setFile] = useState({ selectedFile: null });
  const [summaryReport, setSummaryReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [_dragActive, setDragActive] = useState(false);
  const [isSnackbarOpen, setSnackbarVisibility] = useState(false);
  const [message, setMessages] = useState("");
  const [msg, setMsg] = useState("");
  const [listingType, setListingType] = useState("");
  const [invalidSKUs, setInvalidSKUs] = useState([]);
  const classes = useStyles();
  const userDetails = getLocalStorage("user-details");

  const checkFileType = (e, eventType) => {
    let extension;

    if (eventType === "drop") {
      extension = e.dataTransfer.files[0].name.match(/\.([^.]+)$/)[1];
    } else {
      extension = e.target.value.match(/\.([^.]+)$/)[1];
    }

    switch (extension) {
      case "csv":
        eventType !== "drop"
          ? setFile({ selectedFile: e.target.files[0] })
          : setFile({ selectedFile: e.dataTransfer.files[0] });
        setMsg("");
        break;
      default:
        setFile({ selectedFile: null });
        setMsg(`.${extension} format is not supported.`);
    }
  };

  const checkSize = (e, eventType) => {
    let size;
    if (eventType === "drop") {
      size = e.dataTransfer.files[0].size / 8;
    } else {
      size = e.target.files[0].size / 8;
    }

    if (size <= 1048576) {
      checkFileType(e, eventType);
    } else {
      setMsg("Size should be less than or equal 1MB");
    }
  };

  const chooseFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      checkSize(e);
      e.target.value = "";
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkSize(e, "drop");
    }
  };

  const modifyError = (error) => {
    let err = "";
    let count = 1;

    for (const key in error) {
      err += `${count}. ${error[key].split(".").join("")} `;
      count++;
    }

    return err;
  };

  const manageListingFile = (manageListingType, type) => {
    const formData = new FormData();

    formData.append("filetemplate", file.selectedFile);
    formData.append("templateName", manageListingType);
    formData.append("userId", userDetails.id);

    setLoading(true);
    setListingType(bulkOperationType[type]);

    importBulkListing(formData)
      .then((res) => {
        if (res?.status === 200) {
          const { data: bulkResponse } = res?.data ?? {};

          if (bulkResponse?.data?.length > 0) {
            const invalidSKUs = bulkResponse.data;
            const modifiedInvalidSKUs = invalidSKUs.map((SKU) => {
              const { error, ...rest } = SKU;
              const errorReasons =
                error && Object.keys(error).length > 0
                  ? modifyError(error)
                  : "";

              return { ...rest, reason: errorReasons?.replace(/,/g, "") };
            });

            setInvalidSKUs([...modifiedInvalidSKUs]);
          }

          if (bulkResponse) {
            const reportSummary = {
              TotalSkus: bulkResponse.TotalSkus,
              TotalSkusProcessed: bulkResponse.TotalSkusProcessed,
              autoApprovedSkus: bulkResponse.autoApprovedSkus,
              invalidSkus: bulkResponse.invalidSkus,
              pendingSkus: bulkResponse.pendingSkus,
              TotalSkuDeleted: bulkResponse.TotalSkuDeleted,
            };

            setLoading(false);
            setSnackbarVisibility(true);
            setSummaryReport(reportSummary);
          }

          setMessages("File processed completed");
          renderViewListing();
        }
      })
      .catch((_err) => setLoading(false));
  };

  const downloadInvalidSKUs = () => exportCSV(invalidSKUs);

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;
    setSnackbarVisibility(false);
  };

  const reset = () => {
    setMsg("");
    setDragActive(false);
    setSnackbarVisibility(false);
    setFile({ selectedFile: null });
    setLoading(false);
    setMessages("");
    setListingType("");
    setInvalidSKUs([]);
    setSummaryReport(null);
  };

  const BulkListingDelete = ({ summaryReport }) => (
    <div className={styles.TableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                <Typography variant="h4">Bulk Delete Summary Report</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Total SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.TotalSkus ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Deleted SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.TotalSkuDeleted ?? 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const ManageBulkListing = ({ summaryReport }) => (
    <div className={styles.TableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                <Typography variant="h4">Bulk Upload Summary Report</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Total SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.TotalSkus ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total SKUs Processed</TableCell>
              <TableCell align="left">
                {summaryReport?.TotalSkusProcessed ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Auto Approved SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.autoApprovedSkus ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Pending SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.pendingSkus ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Invalid SKUs</TableCell>
              <TableCell align="left">
                {summaryReport?.invalidSkus ?? 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={modalSize}
      onExited={reset}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title" onClose={closeModal}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.BulkListingUploadContainer}>
          {!summaryReport && (
            <form
              className={styles.UploadBox}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              onSubmit={(e) => e.preventDefault()}
            >
              {file.selectedFile !== null ? (
                <p className={styles.Filename}>{file.selectedFile.name}</p>
              ) : msg !== "" ? (
                <p data-testid="invalid-file">{msg}</p>
              ) : (
                <BackupIcon fontSize="large" />
              )}
              <div>
                <div className={styles.Drag}>
                  Drop your file here or{" "}
                  <div className={styles.Browse}>
                    <label
                      htmlFor="img"
                      className={styles.FileLabel}
                      onClick={() =>
                        document.getElementById("bulk-listing-upload").click()
                      }
                    >
                      <strong>Browse</strong>
                      <input
                        type="file"
                        accept=".csv"
                        id="bulk-listing-upload"
                        data-testid="bulk-listing-upload"
                        className={styles.FileIcon}
                        onChange={chooseFile}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <p className={styles.Info}>
                Supported files: <strong>CSV</strong>{" "}
              </p>
            </form>
          )}
          {summaryReport &&
            (listingType === "BulkUpload" || listingType === "BulkEdit") && (
              <>
                <ManageBulkListing summaryReport={summaryReport} />
                {invalidSKUs.length > 0 && (
                  <div className={styles.InvalidSKUs}>
                    <Typography variant="h5">
                      *Download file to see all non processed or invalid SKUs
                    </Typography>
                    <IconButton onClick={downloadInvalidSKUs}>
                      <GetAppIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          {summaryReport && listingType === "BulkDelete" && (
            <BulkListingDelete summaryReport={summaryReport} />
          )}
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {message}
            </Alert>
          </Snackbar>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.ActionBtnContainer}>
          {!summaryReport && (
            <>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                data-testid="bulk-delete-btn"
                disabled={loading || !file.selectedFile}
                onClick={() =>
                  manageListingFile("BULK_DELETE_SELLER_LISTING", 2)
                }
                className={`${styles.ManageListingButton} ${
                  !loading && file.selectedFile ? styles.DeleteButton : null
                }`}
              >
                {loading && (
                  <CircularProgress
                    size={17}
                    color="primary"
                    style={{ marginRight: "5px", color: "#696767" }}
                  />
                )}
                Delete
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                data-testid="bulk-edit-btn"
                disabled={loading || !file.selectedFile}
                className={styles.ManageListingButton}
                onClick={() => manageListingFile("BULK_EDIT_SELLER_LISTING", 3)}
              >
                {loading && (
                  <CircularProgress
                    size={17}
                    color="primary"
                    style={{ marginRight: "5px", color: "#696767" }}
                  />
                )}
                Edit
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                data-testid="bulk-create-btn"
                disabled={loading || !file.selectedFile}
                className={styles.ManageListingButton}
                onClick={() =>
                  manageListingFile("BULK_CREATE_SELLER_LISTING", 1)
                }
              >
                {loading && (
                  <CircularProgress
                    size={17}
                    color="primary"
                    style={{ marginRight: "5px", color: "#696767" }}
                  />
                )}
                Upload
              </Button>
            </>
          )}
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default BulkListingUploadModal;
