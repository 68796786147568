import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import style from "../Profile.module.scss";

const BusinessContactPerson = (props) => {
  const { user, setUser, errors } = props;

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    // if (!file) {
    //   alert("Please select at file.");
    //   return;
    // }

    const fileNameParts = file.name.split(".");
    const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (!["jpg", "jpeg", "png"].includes(extension)) {
      alert("Please select only image files.");
      return;
    }

    // Read the selected file as a binary string
    // const reader = new FileReader();
    // reader.onload = () => {
    //   handleChange(event.target.name, reader.result);
    //   setFileName((previousFile) => {
    //     return {
    //       ...previousFile,
    //       [event.target.name]: file.name,
    //     };
    //   });
    // };
    // reader.onerror = (error) => {
    //   console.error(error);
    //   alert("An error occurred while reading the selected file.");
    // };
    // reader.readAsBinaryString(file);

    handleChange(event.target.name, file);
    handleChange(event.target.name + "Name", file.name);
  };

  const handleChange = (type, value) => {
    setUser((previousUser) => {
      return {
        ...previousUser,
        [type]: value,
      };
    });
  };

  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Contact Person</h1>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          required
          className={style.TextField}
          name="name"
          value={user.name}
          label="Full Name"
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.name)}
          helperText={errors && errors.name}
        />
        <TextField
          required
          className={style.TextField}
          name="phoneNo"
          value={user.phoneNo}
          label="Phone Number"
          variant="outlined"
          onChange={(e) => {
            const type = e.target.name;
            const value = e.target.value;
            if (value.length <= 11) {
              handleChange(type, value);
            }
          }}
          error={errors && Boolean(errors.phoneNo)}
          helperText={errors && errors.phoneNo}
        />
      </form>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          required
          className={style.TextField}
          name="cnic"
          value={user.cnic}
          label="CNIC of Concerned Person"
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          InputProps={{
            inputComponent: CnicMaskCustom,
          }}
          error={errors && Boolean(errors.cnic)}
          helperText={errors && errors.cnic}
        />

        <form className={style.FormContainer}>
          <input
            className={style.FileInput}
            accept="image/*"
            id="cnic-file-front"
            name="cnicFrontPic"
            type="file"
            onChange={(e) => {
              handleFileInputChange(e);
            }}
          />
          <label
            htmlFor="cnic-file-front"
            className={`${style.UploadLabel} ${
              errors.cnicFrontPic ? style.ErrorLabel : ""
            }`}
          >
            <Button
              className={style.UploadButton}
              variant="contained"
              color="default"
              startIcon={<CloudUploadIcon />}
              component="span"
            >
              Upload CNIC Front Picture
            </Button>
            {user.cnicFrontPicName !== "" && (
              <p className={style.FileLabel}>{user.cnicFrontPicName}</p>
            )}
            {user.cnicFrontPicName === "" && errors.cnicFrontPic && (
              <p className={style.ErrorLabel}>{errors.cnicFrontPic}</p>
            )}
          </label>
          <input
            className={style.FileInput}
            accept="image/*"
            id="cnic-file-back"
            name="cnicBackPic"
            type="file"
            onChange={(e) => {
              handleFileInputChange(e);
            }}
          />
          <label
            htmlFor="cnic-file-back"
            className={`${style.UploadLabel} ${
              errors.cnicBackPic ? style.ErrorLabel : ""
            }`}
          >
            <Button
              className={style.UploadButton}
              variant="contained"
              color="default"
              startIcon={<CloudUploadIcon />}
              component="span"
            >
              Upload CNIC Back Picture
            </Button>
            {user.cnicBackPicName !== "" && (
              <p className={style.FileLabel}>{user.cnicBackPicName}</p>
            )}
            {user.cnicBackPicName === "" && errors.cnicBackPic && (
              <p className={style.ErrorLabel}>{errors.cnicBackPic}</p>
            )}
          </label>
        </form>
      </form>
    </div>
  );
};

const CnicMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  const cnicMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={cnicMask}
      guide={true}
    />
  );
};

export default BusinessContactPerson;
