import { useState, useEffect } from "react";
import {
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../../../components/Pagination";
import SKUItemCard from "../SKUItemCard";
import DeleteSKUsModal from "../../Modal/DeleteSKUModal";
import {
  getInactiveProductSKUUsers,
  deleteSKU,
} from "../../../../APIs/listing";
import { DELETE_INACTIVE_SKU } from "../../../../Utilities/endpoints";
import styles from "./InactiveListing.module.scss";

const InactiveListings = ({ isRender }) => {
  const [inactiveSKUs, setInactiveSKUs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSKUs, setSelectedSKUs] = useState(false);
  const [isDeleteSKUModalOpen, setDeleteSKUModalOpen] = useState(false);
  const [deleteSKULoading, setDeleteSKULoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [listingPerPage, setListingPerPage] = useState(10);

  const fetchInactiveSKUs = () => {
    const query = {
      isActive: false,
      pageNumber: page + 1,
      pageSize: listingPerPage,
    };

    if (searchTerm.length > 0) {
      query["searchKey"] = encodeURIComponent(searchTerm);
    }

    setLoading(true);
    setInactiveSKUs([]);

    getInactiveProductSKUUsers(query)
      .then((res) => {
        if (res.status === 200) {
          const SKUs =
            res.data?.sku.map((SKU) => ({ ...SKU, isSelected: false })) ?? [];

          setInactiveSKUs(SKUs);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const selectedLists = (idx) => {
    const SKUs = [...inactiveSKUs];

    SKUs[idx] = {
      ...SKUs[idx],
      isSelected: !SKUs[idx]["isSelected"],
    };

    setInactiveSKUs([...SKUs]);
  };

  const deleteSingleSKU = (idx) => {
    const SKUs = [...inactiveSKUs];

    SKUs[idx] = {
      ...SKUs[idx],
      isSelected: !SKUs[idx]["isSelected"],
    };

    setInactiveSKUs([...SKUs]);
    setDeleteSKUModalOpen(true);
  };

  const deleteMultipleSKUs = (e) => {
    const isSelected = e.target.checked;

    setSelectedSKUs(isSelected);

    const SKUs = inactiveSKUs.map((SKU) => ({
      ...SKU,
      isSelected,
    }));

    setInactiveSKUs([...SKUs]);
  };

  const closeDeleteSKUModal = () => {
    const SKUs = inactiveSKUs.map((SKU) => ({ ...SKU, isSelected: false }));

    setInactiveSKUs(SKUs);
    setSelectedSKUs(false);
    setDeleteSKUModalOpen(false);
  };

  const openDeleteSKUModal = () => setDeleteSKUModalOpen(true);

  const manageSKUsDeletion = () => {
    const URL = `api${DELETE_INACTIVE_SKU}`;

    const payload = {
      skuId: inactiveSKUs
        .filter(({ isSelected }) => isSelected)
        .map(({ skuId }) => skuId),
    };

    setDeleteSKULoading(true);
    deleteSKU(URL, payload)
      .then((res) => {
        const { statusCode } = res.data ?? {};

        if (statusCode === 200) {
          fetchInactiveSKUs();

          setSelectedSKUs(false);
          setDeleteSKUModalOpen(false);
          setDeleteSKULoading(false);
        }
      })
      .catch((_err) => setDeleteSKULoading(false));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setListingPerPage(newRowsPerPage);
    setPage(0);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      let timer;

      const debounceSearch = () => {
        clearTimeout(timer);
        timer = setTimeout(() => fetchInactiveSKUs(), 500);
      };

      debounceSearch();
      return () => clearTimeout(timer);
    } else {
      fetchInactiveSKUs();
    }
  }, [searchTerm, page, listingPerPage, isRender]);

  return (
    <div className={styles.InactiveListingsContainer}>
      <div className={styles.SearchContainer}>
        <TextField
          value={searchTerm}
          placeholder={"Search SKU"}
          variant="outlined"
          className={styles.SearchField}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={styles.Header}>
        <Typography variant="h4">
          {inactiveSKUs?.length ?? 0} Listed SKUs
        </Typography>
        <div className={styles.ActionBtnsContainer}>
          {inactiveSKUs.length > 0 && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.StyleCheckbox}
                    color="secondary"
                    checked={
                      selectedSKUs ||
                      inactiveSKUs.some(({ isSelected }) => isSelected)
                    }
                    onChange={(e) => deleteMultipleSKUs(e)}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                  />
                }
                label="Delete all SKUs"
              />
            </FormGroup>
          )}
          {(selectedSKUs ||
            inactiveSKUs.some(({ isSelected }) => isSelected)) && (
            <IconButton
              color="secondary"
              className={styles.DeleteSKU}
              onClick={openDeleteSKUModal}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          )}
        </div>
      </div>
      {!loading && inactiveSKUs.length === 0 && (
        <div className={styles.NotFoundMsg}>
          <Typography color="primary" variant="h4">
            Nothing to show
          </Typography>
        </div>
      )}
      {loading && (
        <div className={styles.LoadingContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={styles.SKUItemCardContainer}>
        {inactiveSKUs.map((inactiveSKU, idx) => {
          return (
            <SKUItemCard
              idx={idx}
              listingType="inactive"
              deleteSKU={deleteSingleSKU}
              key={inactiveSKU.skuId}
              SKUDetails={inactiveSKU}
              selectedLists={selectedLists}
            />
          );
        })}
      </div>
      <Pagination
        label="SKUs per page"
        page={page}
        pageSize={listingPerPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        count={inactiveSKUs?.length}
      />
      <DeleteSKUsModal
        modalSize="sm"
        modalTitle="Delete SKU(s)"
        deleteSKUs={manageSKUsDeletion}
        loading={deleteSKULoading}
        onClose={closeDeleteSKUModal}
        isOpen={isDeleteSKUModalOpen}
      />
    </div>
  );
};

export default InactiveListings;
