import { Typography, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Rating from "../../../components/Rating";
import { fetchAssignedBadge } from "../../PerformanceBadges/constants";
import styles from "./Ratings.module.scss";

const RatingDetails = ({
  name,
  ratings,
  setIsRatingModalOpen,
  currentBadgeID,
}) => {
  return (
    <div className={styles.Header}>
      <div className={styles.Info}>
        {currentBadgeID && (
          <img
            alt="badge-icon"
            src={fetchAssignedBadge(currentBadgeID)}
            className={styles.BadgeIcon}
          />
        )}
        <Typography variant="h1">{name}</Typography>
      </div>
      <div className={styles.Ratings}>
        <Rating
          customStyles={{ fontSize: "42px", color: "#2A7EBD" }}
          value={ratings}
          readOnly={true}
        />
        <p>
          <strong className={styles.RatingScore}>{ratings}</strong>
          <span className={styles.TotalRating}>/5</span>
        </p>
        <IconButton
          aria-label="ForwardIcon"
          onClick={() => setIsRatingModalOpen(true)}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default RatingDetails;
