import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { setUserDetails } from "../../features/userDetails/userDetailsSlice";
import { generateOTP, accountLogin } from "../../APIs/auth";
import { setLocalStorage } from "../../Utilities";
import styles from "./NewPassword.module.scss";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOTPLoading, setOTPLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const handlePassword = (e) => setPassword(e.target.value);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLoginUser = () => {
    const payload = {
      password,
      isTwilio: false,
      userName: `+${state?.phone}`,
    };

    setLoading(true);
    accountLogin(payload)
      .then((res) => {
        if (res.status === 200) {
          const userDetails = {
            id: res.data.id,
            name: res.data.name,
          };

          dispatch(setUserDetails(userDetails));

          setLoading(false);
          setLocalStorage("auth-token", res.data.token);
          setLocalStorage("user-details", userDetails);
          history.push("/dashboard");
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleForgotPassword = () => {
    const payload = {
      userName: `+${state?.phone}`,
      isTwilio: false,
    };

    setOTPLoading(true);
    generateOTP(payload)
      .then((res) => {
        if (res && res.status === 200) {
          setOTPLoading(false);
          history.push("/forgot-password", { phone: state?.phone });
        }
      })
      .catch((_err) => setOTPLoading(false));
  };

  return (
    <div className={styles.NewPasswordWrapper}>
      <div className={styles.Header}>
        <IconButton color="primary" onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant="h3" color="primary" className={styles.Title}>
          PASSWORD
        </Typography>
      </div>
      <div className={styles.NewPasswordContainer}>
        <div style={{ textAlign: "center" }}>
          <TextField
            className={styles.Password}
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            value={password}
            label="Password"
            variant="outlined"
            onChange={handlePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.ForgotPasswordContainer}>
            <Button
              disabled={isOTPLoading}
              color="primary"
              onClick={handleForgotPassword}
              className={styles.button}
            >
              Forgot Password?
            </Button>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={!(password.length > 0) || loading}
            color="primary"
            variant="contained"
            onClick={handleLoginUser}
            className={styles.LoginBtn}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
