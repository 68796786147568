import { useEffect } from "react";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import styles from "./FraudDetection.module.scss";

const FraudDetection = () => {
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!state?.isBlacklisted) {
      history.push("/");
    }
  }, []);

  return (
    <>
      {state?.isBlacklisted && (
        <div className={styles.FraudDetectionWrapper}>
          <IconButton color="primary" onClick={() => history.push("/")}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <div className={styles.Container}>
            <Typography className={styles.Headline} variant="h3">
              Your account has been suspended due to violations of out Terms of
              Service.
            </Typography>
            <Typography className={styles.HelperMsg} variant="h3">
              Call Dastgyr Helpline to learn more.
            </Typography>
            <ReportProblemIcon className={styles.ReportIcon} />
            <Typography className={styles.Status} variant="h3">
              Account Suspended
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default FraudDetection;
