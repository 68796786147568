import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { sellerTypes } from "../../../constants/enum";
import style from "../Profile.module.scss";

const SellerOption = (props) => {
  const { sellerType, setSellerType } = props;

  const handleChange = (value) => {
    setSellerType(value);
  };

  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Select Seller Type</h1>
      <form className={style.RadioButtonForm} noValidate autoComplete="off">
        <RadioGroup
          row
          aria-label="sellertype"
          name="sellertype"
          value={sellerType}
          onChange={(e) => handleChange(e.target.value)}
        >
          {sellerTypes.map((option) => (
            <FormControlLabel
              key={option.value}
              className={style.RadioButton}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </form>
    </div>
  );
};

export default SellerOption;
