import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Typography } from "@material-ui/core";
import FilterModal from "./Modal/FilterModal";
import SellerRatingModal from "./Modal/SellerRatingModal";
import { getStatistics, getSellerPayment } from "../../APIs/dashboard";
import { getSellerRating } from "../../APIs/trade-service";
import { sellerPerformanceBadges } from "../../APIs/growth";
import { homeFilter } from "./constants";
import RatingDetails from "./Ratings";
import PerformanceBadge from "./PerformanceBadge";
import Payments from "./Payments";
import Orders from "./Orders";
import LiveSKUs from "./LiveSKUs";
import Complaints from "./Complaints";
import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(homeFilter[0]["id"]);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { profileDetails } = useSelector((state) => state.profileDetails);
  const userId = userDetails?.id;

  // Fetch Statistics
  const fetchStatistics = async ({ queryKey }) => {
    const query = {
      SortingEnumId: queryKey[1],
    };

    const res = await getStatistics(query);
    return res?.data ?? {};
  };

  const { data: SKUDetails, isLoading: skuDetailsLoading } = useQuery(
    ["sku-details", selectedFilter],
    fetchStatistics
  );

  const applyFilter = (value) => {
    setSelectedFilter(value);
    closeFilterModal();
  };

  // Fetch Seller Payment
  const { data: sellerPayment } = useQuery("seller-payment", getSellerPayment, {
    select: (response) => {
      if (response?.status === 200) {
        return response?.data?.data;
      }
      return {};
    },
  });

  // Fetch Seller Ratings
  const fetchSellerRating = async () => await getSellerRating(userId);
  const { data: sellerRating } = useQuery(
    ["seller-rating", userId],
    fetchSellerRating,
    {
      enabled: !!userDetails,
      staleTime: 60000,
    }
  );

  // Fetch Performnce Badges
  const fetchSellerPerformanceBadges = async () => {
    const queryParams = { cityId: profileDetails?.city?.id };
    const res = await sellerPerformanceBadges(queryParams);

    if (res.status === 200) {
      return { data: { ...res.data.data } };
    }
  };

  const { data: badgeDetails } = useQuery(
    ["seller-performance-badges", profileDetails?.city?.id],
    fetchSellerPerformanceBadges,
    {
      enabled: !!profileDetails,
      staleTime: 60000,
    }
  );

  const openFilterModal = () => setIsFilterModalOpen(true);
  const closeFilterModal = () => setIsFilterModalOpen(false);

  const closeRatingModal = () => setIsRatingModalOpen(false);

  return (
    <>
      <div className={styles.DashboardWrapper}>
        <Typography color="primary" variant="h2" className={styles.Title}>
          DASHBOARD
        </Typography>
        <div className={styles.CardsContainer}>
          <RatingDetails
            name={userDetails?.name ?? ""}
            ratings={sellerRating?.data?.data?.overallRating ?? 0}
            setIsRatingModalOpen={setIsRatingModalOpen}
            currentBadgeID={badgeDetails?.data?.current_badge?.badge_id}
          />
          <PerformanceBadge badgeDetails={badgeDetails} />
        </div>
        <div className={styles.CardsContainer}>
          <Payments sellerPayment={sellerPayment} />
          <Orders
            totalOrders={SKUDetails?.totalOrders ?? 0}
            openFilterModal={openFilterModal}
            selectedFilter={selectedFilter}
            homeFilter={homeFilter}
          />
        </div>
        <div className={styles.CardsContainer}>
          <LiveSKUs SKUDetails={SKUDetails} />
          <Complaints />
        </div>
      </div>
      <SellerRatingModal
        modalSize="sm"
        modalTitle="Seller Ratings"
        ratingDetails={sellerRating?.data?.data ?? {}}
        name={userDetails?.name ?? ""}
        onClose={closeRatingModal}
        isOpen={isRatingModalOpen}
      />
      <FilterModal
        modalSize="sm"
        modalTitle="Order Filter"
        onClose={closeFilterModal}
        isOpen={isFilterModalOpen}
        selectedFilter={selectedFilter}
        applyFilter={applyFilter}
        skuDetailsLoading={skuDetailsLoading}
      />
    </>
  );
};

export default Dashboard;
