import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Snackbar,
  Button,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  createBulkTemplate,
  getSellerDeleteSKUs,
  getSellerEditSKUs,
} from "../../APIs/listing";
import SellerSuspension from "./SellerSuspension";
import ViewListings from "./ViewListings/listings";
import BulkListingUploadModal from "./Modal/BulkListingUploadModal";
import { exportCSV } from "../../Utilities";
import styles from "./Listing.module.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Listing = () => {
  const [isBulkListingModalOpen, setBulkListingModalVisibility] =
    useState(false);
  const [allSKUsLoading, setAllSKUsLoading] = useState();
  const [message, setMessages] = useState("");
  const [isSnackbarOpen, setSnackbarVisibility] = useState(false);
  const [deleteSKUsLoading, setDeleteSKUsLoading] = useState();
  const [editSKUsLoading, setEditSKUsLoading] = useState();
  const [isRender, setIsRender] = useState(false);

  const { profileDetails } = useSelector((state) => state.profileDetails);
  const history = useHistory();
  const { locationStatus } = useSelector((state) => state.sellerLocationStatus);

  const suspendedUser = profileDetails?.suspendedUser ?? {};

  const goTo = (path) => history.push(path);

  const downloadAllSKUs = () => {
    setAllSKUsLoading(true);
    createBulkTemplate()
      .then((res) => {
        if (res.status === 200) {
          const SKUs = res.data;

          if (SKUs?.length > 0) {
            const modifiedSKUs = SKUs.map((sku) => ({
              ...sku,
              Name: sku?.Name.replace(/,/g, ""),
            }));

            exportCSV(modifiedSKUs);
          }

          setAllSKUsLoading(false);
        }
      })
      .catch((_err) => setAllSKUsLoading(false));
  };

  const downloadSellerSKUsForDelete = () => {
    setDeleteSKUsLoading(true);
    getSellerDeleteSKUs()
      .then((res) => {
        if (res.status === 200) {
          const SKUs = res.data;

          if (SKUs?.length > 0) {
            const modifiedSKUs = SKUs.map((sku) => ({
              ...sku,
              Name: sku?.Name.replace(/,/g, ""),
            }));

            exportCSV(modifiedSKUs);
          }

          if (SKUs.length === 0) {
            setSnackbarVisibility(true);
            setMessages("No SKUs available");
          }

          setDeleteSKUsLoading(false);
        }
      })
      .catch((_err) => setDeleteSKUsLoading(false));
  };

  const downloadSellerSKUsForEdit = () => {
    setEditSKUsLoading(true);
    getSellerEditSKUs()
      .then((res) => {
        if (res.status === 200) {
          const SKUs = res.data;

          if (SKUs?.length > 0) {
            const modifiedSKUs = SKUs.map((sku) => ({
              ...sku,
              Name: sku?.Name.replace(/,/g, ""),
            }));

            exportCSV(modifiedSKUs);
          }

          if (SKUs.length === 0) {
            setSnackbarVisibility(true);
            setMessages("No SKUs available");
          }

          setEditSKUsLoading(false);
        }
      })
      .catch((_err) => setEditSKUsLoading(false));
  };

  const openBulkListingModal = () => {
    setBulkListingModalVisibility(true);
  };

  const closeBulkListingModal = (_e, reason) => {
    if (reason && reason === "backdropClick") return;
    setBulkListingModalVisibility(false);
  };

  const renderViewListing = () => setIsRender((prevState) => !prevState);

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarVisibility(false);
    setMessages("");
  };

  return (
    <>
      {suspendedUser?.isSuspended ? (
        <SellerSuspension
          unsuspensionDate={suspendedUser?.activityWillFinishedAt}
        />
      ) : (
        <div className={styles.ListingWrapper}>
          <div className={styles.Header}>
            <Typography variant="h2">LISTING</Typography>
            <div className={styles.ButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                data-testid="manage-bulk-listing-btn"
                onClick={openBulkListingModal}
                className={styles.ManageListing}
              >
                Manage Bulk Listing
              </Button>
              {locationStatus?.isValid && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => goTo("/listing/manage-listing")}
                  className={styles.ManageListing}
                >
                  Add new listing
                </Button>
              )}
            </div>
          </div>
          <div className={styles.BulkInsertGuideLines}>
            <Typography variant="h3">
              Guidelines for Bulk Listing Management
            </Typography>
            <Typography className={styles.Info} variant="h6">
              <span>1. Download item details for bulk insertion reference</span>
              {allSKUsLoading ? (
                <CircularProgress color="primary" size={25} />
              ) : (
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={downloadAllSKUs}
                />
              )}
            </Typography>
            <Typography className={styles.Info} variant="h6">
              <span>
                2. Download CSV of user's deletable items for quick reference
              </span>
              {deleteSKUsLoading ? (
                <CircularProgress color="primary" size={25} />
              ) : (
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={downloadSellerSKUsForDelete}
                />
              )}
            </Typography>
            <Typography className={styles.Info} variant="h6">
              <span>
                3. Download CSV of user's editable items for quick reference
              </span>
              {editSKUsLoading ? (
                <CircularProgress color="primary" size={25} />
              ) : (
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={downloadSellerSKUsForEdit}
                />
              )}
            </Typography>
          </div>
          <div className={styles.ViewListingsContainer}>
            <ViewListings isRender={isRender} />
          </div>
          <BulkListingUploadModal
            modalSize="sm"
            modalTitle="Manage Bulk Listing"
            isOpen={isBulkListingModalOpen}
            closeModal={closeBulkListingModal}
            renderViewListing={renderViewListing}
          />
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {message}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default Listing;
