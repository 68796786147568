import BeginnerIcon from "../../../assets/images/beginner.png";
import BronzeIcon from "../../../assets/images/bronze.png";
import SilverIcon from "../../../assets/images/silver.png";
import GoldIcon from "../../../assets/images/gold.png";
import PlatinumIcon from "../../../assets/images/platinum.png";

export const selectedStatus = {
  background: "#102947",
};

export const rewards = [
  {
    type: 1,
    label: "Current Performance",
  },
  {
    type: 2,
    label: "Past Rewards",
  },
];

export const fetchAssignedBadge = (type) => {
  const badges = {
    1: BeginnerIcon,
    2: BronzeIcon,
    3: SilverIcon,
    4: GoldIcon,
    5: PlatinumIcon,
  };

  return badges[type] ?? BeginnerIcon;
};

export const fetchBadgeSpecificColors = (type) => {
  const badgeTheme = {
    1: {
      color: "#675d5d",
      backgroundColor: "#e9eaeb",
    },
    2: {
      color: "#6c1515",
      backgroundColor: "#fecab4",
    },
    3: {
      color: "#848181",
      backgroundColor: "#e2e2e2",
    },
    4: {
      color: "#b7b72f",
      backgroundColor: "#fffab8",
    },
    5: {
      color: "#3d6f7e",
      backgroundColor: "#dfeff4",
    },
  };

  return badgeTheme[type] ?? badgeTheme[1];
};
