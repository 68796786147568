import axiosInstance from "../app-interceptor/interceptor";
import {
  SELLER_PERFORMANCE_BADGES,
  SELLER_PERFORMANCE_BADGES_HISTORY,
} from "../Utilities/endpoints";
import CONSTANT from "../constants";

const baseURL = CONSTANT.growthURL;

export const sellerPerformanceBadges = (params = undefined) =>
  axiosInstance.get(`${baseURL}${SELLER_PERFORMANCE_BADGES}`, { params });

export const sellerPerformanceBadgesHistory = (params = undefined) =>
  axiosInstance.get(`${baseURL}${SELLER_PERFORMANCE_BADGES_HISTORY}`, {
    params,
  });
