import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import style from "../Profile.module.scss";

const VerticalOption = (props) => {
  const { vertical, verticalType, setVerticalType } = props;

  const handleChange = (value) => {
    const verticalType = Number(value);
    setVerticalType(verticalType);
  };

  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Select Category Type</h1>
      <form className={style.RadioButtonForm} noValidate autoComplete="off">
        <RadioGroup
          row
          aria-label="vertical"
          name="vertical"
          value={verticalType || ""}
          onChange={(e) => handleChange(e.target.value)}
        >
          {vertical.length > 0 &&
            vertical.map((option) => (
              <FormControlLabel
                key={option.id}
                className={style.RadioButton}
                value={option.id}
                control={<Radio />}
                label={option.name}
              />
            ))}
        </RadioGroup>
      </form>
    </div>
  );
};

export default VerticalOption;
