import {
  GET_DELIVERY_CITIES,
  SELLER_DELIVERY_CITIES,
  UPDATE_SELLER_DELIVERY_CITIES,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getDeliveryCities = () =>
  axiosInstance.get(`api${GET_DELIVERY_CITIES}`);

export const getSellerDeliveryCities = () =>
  axiosInstance.get(`api${SELLER_DELIVERY_CITIES}`);

export const addSellerDeliveryCities = (payload) =>
  axiosInstance.post(`api${SELLER_DELIVERY_CITIES}`, payload);

export const removeSellerDeliveryCities = (query = undefined) =>
  axiosInstance.delete(`api${SELLER_DELIVERY_CITIES}?Id=${query}`);

export const updateSellerDeliveryCities = ({ data }) =>
  axiosInstance.put(`api${UPDATE_SELLER_DELIVERY_CITIES}`, data);
