import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Errors({
  open,
  close,
  message,
  hideDuration = 5000,
  severity = "success",
}) {
  return (
    <Snackbar open={open} autoHideDuration={hideDuration} onClose={close}>
      <Alert onClose={close} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Errors;
