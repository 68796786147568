import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import DastgyrLogo from "../../../../../../../../../assets/images/dastgyr-logo.png";
import { truncate } from "../../../../../../../../../Utilities";
import styles from "./OrderCard.module.scss";

const OrderCard = ({ details }) => {
  const { profileDetails } = useSelector((state) => state.profileDetails);

  return (
    <div className={styles.SKUItemContainer}>
      <div className={styles.PrimaryContainer}>
        <div className={styles.ProductImgContainer}>
          <img
            className={styles.ProductImg}
            alt="SKU Item"
            src={
              details?.productImage && JSON.parse(details?.productImage)
                ? JSON.parse(details?.productImage)
                : DastgyrLogo
            }
          />
        </div>
      </div>
      <div className={styles.SecondaryContainer}>
        <div className={styles.ProductDetails}>
          <Typography
            className={styles.ProductLabel}
            variant="h6"
            title={details?.productName ?? ""}
          >
            {truncate(details?.productName, 30)}
          </Typography>
          {details?.mrp && (
            <div className={styles.Badge}>
              MRP: {profileDetails?.currency ?? ""} {details?.mrp ?? 0}
            </div>
          )}
        </div>
        <div className={styles.QtyContainer}>
          <div>
            <Typography color="secondary" variant="h5">
              Returned Qty: {details?.returnedQty ?? 0}
            </Typography>
          </div>
        </div>
        <div className={styles.CostContainer}>
          <Typography className={styles.UnitCost} variant="h5">
            Total Cost:{" "}
            {new Intl.NumberFormat("en-US").format(
              details?.returnedAmount ?? 0
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
