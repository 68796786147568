export const pricingTypes = [
  {
    id: 1,
    name: "Fixed Price",
  },
  {
    id: 2,
    name: " Variable Pricing",
  },
];

export const initialVariablePricings = Array(2).fill({
  price: "",
  qty: "",
  isInvalidPrice: false,
  isInvalidQty: false,
  isDisabled: false,
});
