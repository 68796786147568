import { CircularProgress } from "@material-ui/core";
import styles from "./Loading.module.scss";

const Loading = () => (
  <div className={styles.LoadingContainer}>
    <CircularProgress color="primary" />
  </div>
);

export default Loading;
