import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modes: null,
};

export const fulfillmentModesSlice = createSlice({
  name: "fulfillmentModes",
  initialState,
  reducers: {
    setFulfillmentModeDetails: (state, action) => {
      state.modes = action.payload;
    },
    removeFulfillmentModeDetails: (state) => {
      state.modes = null;
    },
  },
});

export const { setFulfillmentModeDetails, removeFulfillmentModeDetails } =
  fulfillmentModesSlice.actions;

export default fulfillmentModesSlice.reducer;
