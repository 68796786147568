import { useState } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Button,
  List,
  ListItem,
  Divider,
  Avatar,
  ListItemAvatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PhoneIcon from "@material-ui/icons/Phone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DirectionsIcon from "@material-ui/icons/Directions";
import DastgyrLogo from "../../../../../assets/images/dastgyr-logo.png";
import { formatDateTime } from "../../../../../Utilities";
import styles from "./DeliveryOrder.module.scss";
import OrderDetailsModal from "../../../Modal/OrderDetailsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
  },
  productInfoContainer: {
    flexGrow: 1,
  },
  productName: {
    fontWeight: "bold",
    margin: 0,
  },
  productDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
  },
  productQty: {
    color: "#1176ae",
    margin: 0,
  },
  productAmount: {
    color: "#00ac99",
    margin: 0,
  },
  totalBill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
  },
  billLabel: {
    margin: 0,
    fontSize: "24px",
  },
  billAmount: {
    margin: 0,
    color: "#00ac99",
    fontWeight: "bold",
    fontSize: "30px",
  },
  listItemAvatar: {
    marginTop: "0px",
  },
  productImg: {
    width: "50px",
    marginRight: "10px",
  },
  avatar: {
    margin: "0px",
  },
}));

const deliveryStatus = {
  readyToShip: 2,
  shipped: 3,
  delivered: 4,
  cancelled: 6,
};

const DeliveryOrderCard = (props) => {
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    order,
    orderMarkAs = () => {},
    openCancellationModal = () => {},
  } = props;
  const { profileDetails } = useSelector((state) => state.profileDetails);
  const classes = useStyles();

  const openOrderDetailsModal = () => setIsOrderDetailsModalOpen(true);
  const closeOrderDetailsModal = () => setIsOrderDetailsModalOpen(false);

  const open = Boolean(anchorEl);
  let orderActions = undefined;

  if (order.status === deliveryStatus["readyToShip"]) {
    orderActions = [{ value: "cancel", key: "Cancel Order" }];
  } else if (order.status === deliveryStatus["shipped"]) {
    orderActions = [
      { value: "cancel", key: "Cancel Order" },
      { value: "report", key: "Report Order" },
    ];
  }

  const handleOrderActions = (e) => setAnchorEl(e.currentTarget);
  const orderActionMenu = (orderId, type) => {
    if (type === "cancel" || type === "report") {
      openCancellationModal(orderId, type);
    }
    setAnchorEl(null);
  };

  const MarkAs = (orderId) => {
    const { shipped, delivered } = deliveryStatus;

    if (order.status === deliveryStatus["readyToShip"]) {
      orderMarkAs(shipped, orderId, "shipped");
    } else if (order.status === deliveryStatus["shipped"]) {
      orderMarkAs(delivered, orderId, "delivered");
    }
  };

  return (
    <div className={styles.OrderCardContainer}>
      <div className={styles.PackageInfo}>
        <p className={styles.PackageID}>
          Package ID: <span className={styles.Id}>{order?.orderId ?? ""}</span>{" "}
        </p>
        {order.status !== deliveryStatus["delivered"] &&
          order.status !== deliveryStatus["cancelled"] && (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleOrderActions}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={orderActionMenu}
                PaperProps={{
                  style: {
                    maxHeight: 40 * 3,
                    width: "16ch",
                    borderRadius: "8px",
                  },
                }}
              >
                {orderActions?.map(({ key, value }) => (
                  <MenuItem
                    key={key}
                    selected={value === "cancel"}
                    onClick={() => orderActionMenu(order?.orderId, value)}
                  >
                    {key}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
      </div>

      <div className={styles.RetailerInfo}>
        <div>
          <p className={styles.NameLabel}>Retailer Name:</p>
          <p className={styles.Name}>{order?.retailerName ?? ""}</p>
        </div>
        <IconButton color="primary" onClick={() => {}}>
          <PhoneIcon />
        </IconButton>
      </div>

      <div className={styles.RetailerAddress}>
        <div>
          <p className={styles.AddressLabel}>Retailer Address:</p>
          <p className={styles.Address}>{order?.retailerAddress ?? ""}</p>
        </div>
        <IconButton color="primary" onClick={() => {}}>
          <DirectionsIcon />
        </IconButton>
      </div>

      <div className={styles.DeliveryInfo}>
        <div>
          <p className={styles.DeliveryDateLabel}>Delivery Date:</p>
          <p className={styles.DeliveryDate}>
            {order?.deliveredDate ? formatDateTime(order.deliveredDate) : "---"}
          </p>
        </div>
        <p className={styles.PaymentType}>Payment by Cash</p>
      </div>

      <div className={styles.OrderList}>
        <Divider />
        <List>
          {order?.items.slice(0, 1).map((item) => {
            return (
              <div key={item.skuId}>
                <ListItem className={classes.listItem} alignItems="flex-start">
                  {item?.productImage && JSON.parse(item?.productImage) ? (
                    <ListItemAvatar className={classes.listItemAvatar}>
                      <Avatar
                        className={classes.avatar}
                        alt="SKU Item"
                        src={JSON.parse(item?.productImage)}
                      />
                    </ListItemAvatar>
                  ) : (
                    <img
                      className={classes.productImg}
                      alt="SKU Item"
                      src={DastgyrLogo}
                    />
                  )}
                  <div className={classes.productInfoContainer}>
                    <p className={classes.productName}>
                      {item?.productName ?? ""}
                    </p>
                    <div className={classes.productDetails}>
                      <p className={classes.productQty}>
                        Ordered Qty: {item?.quantity ?? 0}
                      </p>
                      <p className={classes.productAmount}>
                        Total:{" "}
                        <strong>
                          {new Intl.NumberFormat("en-US").format(
                            item?.amount ?? 0
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </ListItem>
                <Divider />
              </div>
            );
          })}
          {order?.items.length > 1 && (
            <div className={styles.OrderDetails}>
              <p>View Order Details</p>
              <IconButton color="primary" onClick={openOrderDetailsModal}>
                <VisibilityIcon />
              </IconButton>
            </div>
          )}
          <ListItem className={classes.totalBill}>
            <p className={classes.billLabel}>Total Bill:</p>
            <p className={classes.billAmount}>
              {profileDetails?.currency ?? ""}{" "}
              {new Intl.NumberFormat("en-US").format(order?.totalBill ?? 0)}
            </p>
          </ListItem>
        </List>
      </div>

      {order.status !== deliveryStatus["delivered"] &&
        order.status !== deliveryStatus["cancelled"] && (
          <>
            <Divider />
            <div className={styles.ButtonContainer}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => MarkAs(order?.orderId)}
                className={styles.MarkAsShipped}
              >
                {order.status === deliveryStatus["readyToShip"] &&
                  "Mark as Shipped"}
                {order.status === deliveryStatus["shipped"] &&
                  "Mark as Delivered"}
              </Button>
            </div>
          </>
        )}
      <OrderDetailsModal
        modalSize="md"
        modalTitle="Order Details"
        order={order}
        orderMarkAs={orderMarkAs}
        openCancellationModal={openCancellationModal}
        onClose={closeOrderDetailsModal}
        isOpen={isOrderDetailsModalOpen}
      />
    </div>
  );
};

export default DeliveryOrderCard;
