import html2pdf from "html2pdf.js";
import { formatDateTime } from "../../../../../../../../../../Utilities";

const listData = (reciept) => {
  let returndata = "";

  reciept.orderItems
    ? reciept.orderItems.map(
        ({ skuId, productName, mrp, price, returnedQty }) => {
          returndata += `<tr>
      <th style="padding:5px;border:1px solid #a3a3a3;">${skuId}</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${productName}</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${mrp ? mrp : "-"}</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${
        price ? price : "-"
      }</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${
        returnedQty ? returnedQty : "-"
      }</th>
      </tr>`;
        }
      )
    : (returndata += `<h1></h1>`);

  return returndata;
};

export const downloadReceipt = (receiptDetails) => {
  const htmlContent = `
<div style="border:1px solid #FF6C2F;padding: 20px">
<h1 style="color:#FF6C2F;font-size:30px">RECEIPT</h1>
  <div>
  <div style="display: flex;width:"100%">
    <div style="color:#a3a3a3;width:"50%">Shipment ID</div>
    <div style="margin-left:40px;width:"50%">${receiptDetails.returnCode}</div>
    </div>
  <div style="display:flex;margin-top:5px">
    <div style="color:#a3a3a3">Shipment Date</div>
    <div style="margin-left:20px;color:black">${formatDateTime(
      receiptDetails.shipmentDate
    )}</div>
    </div>
  </div>

<div style="display:flex;justify-content:space-between">
<div style="width: 100%;display:flex;justify-content:space-between">
<div style="width: 40%">
  <div style="display:flex;margin-top:10px">
  <div style="font-size:12px;width:100%; background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Seller Name</div>
  <div style="width:100%;font-size:12px;padding:10px;border:0.1px solid #c3c3c3">${
    receiptDetails?.sellerName ?? "-"
  }</div>
  </div>

  <div style="display:flex">
  <div style="font-size:12px;width:100%;background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Seller Mobile</div>
  <div style="font-size:12px;width:100%;padding:10px;border:0.1px solid #c3c3c3">${
    receiptDetails?.sellerMobile ?? "-"
  }</div>
  </div>
</div>

<div style="width: 40%">
  <div style="display:flex;margin-top:10px">
  <div style="font-size:12px;width:100%; background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Warehouse Name</div>
  <div style="width:100%;font-size:12px;padding:10px;border:0.1px solid #c3c3c3">${
    receiptDetails?.warehouseName ?? "-"
  }</div>
  </div>

  <div style="display:flex">
  <div style="font-size:12px;width:100%;background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Warehouse City</div>
  <div style="font-size:12px;width:100%;padding:10px;border:0.1px solid #c3c3c3">${
    receiptDetails?.warehouseCity ?? "-"
  }</div>
  </div>
</div>
</div>
</div> 

<div style="display: flex; margin-top: 1rem">
  <div style="width: 100%;">
     <table style="width:100%;border-spacing:0;">
      <tr style="background-color: #c3c3c3;border:1px solid #a3a3a3;">
      <th style="padding:5px;border:1px solid #a3a3a3;">SKU ID</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">SKU NAME</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">MRP</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">Unit Price</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">Returned Qty</th>
      </tr>
      ${listData(receiptDetails)}
      </table>
      <div style="width:100%;display:flex;border:1px solid #a3a3a3">
      <div style="font-weight:bold;margin-left:5px; width:88%;padding:5px;">Total</div>
      <h4 style="padding:5px;text-align:right;margin:0">${
        receiptDetails?.totalReturnedAmount ?? "0"
      }</h4>
      </div>
      <h1>Terms & Conditions</h1>
      <div style="display:flex;margin-top:100px;justify-content:space-around">
      <div style="width:40%;border:1px solid black">
      <p style="background-color:#E4E7EB;text-align:center;margin:0;padding:0">Creator Signature</p>
      <p style="text-align:center;font-size:12px;color:#000000">${
        receiptDetails?.sellerName ?? "-"
      }</p>
      </div>
      <div style="width:40%;border:1px solid black">
      <p style="background-color:#E4E7EB;text-align:center;margin:0;padding:0">Receiver Signature</p>
      <p style="text-align:center;font-size:12px;color:#E4E7EB">Please sign here</p>
      </div>
      </div>
  </div>
</div>
</div>
`;

  const element = document.createElement("div");
  element.innerHTML = htmlContent;

  const options = {
    margin: [10, 10, 10, 10],
    filename: "generated_pdf.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, logging: true },
    jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
  };

  html2pdf().set(options).from(element).save("Dastgyr-Receipt.pdf");
};
