import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be atleast 1 character")
    .max(50, "Name must be atmost 50 character"),
  storeName: Yup.string()
    .required("Store Name is required")
    .min(1, "Name must be atleast 1 character")
    .max(50, "Name must be atmost 50 character"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be atleast 6 character")
    .max(20, "Password must be atmost 20 character"),
  cnic: Yup.string()
    .required("CNIC is required")
    .length(15, "CNIC must have exactly 15 characters")
    .test(
      "is-cnic-mask-filled",
      "CNIC must have exactly 15 characters",
      (value) => {
        return value && value.indexOf("_") === -1;
      }
    ),
  cnicFrontPic: Yup.string().required("CNIC Front Pic is required"),
  cnicBackPic: Yup.string().required("CNIC Back Pic is required"),
});

export const contactPersonSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be atleast 1 character")
    .max(50, "Name must be atmost 50 character"),
  phoneNo: Yup.string()
    .required("Phone Number is required")
    .length(11, "Phone Number must have exactly 11 characters"),
  cnic: Yup.string()
    .required("CNIC is required")
    .length(15, "CNIC must have exactly 15 characters")
    .test(
      "is-cnic-mask-filled",
      "CNIC must have exactly 15 characters",
      (value) => {
        return value && value.indexOf("_") === -1;
      }
    ),
  cnicFrontPic: Yup.string().required("CNIC Front Pic is required"),
  cnicBackPic: Yup.string().required("CNIC Back Pic is required"),
});

export const companySchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(1, "Password must be atleast 1 character")
    .max(20, "Password must be atmost 20 character"),
  companyName: Yup.string()
    .required("Company Name is required")
    .min(1, "Company Name must be atleast 1 character")
    .max(20, "Company Name must be atmost 20 character"),
  regNo: Yup.string()
    .required("Registration No is required")
    .min(1, "Company Reg No must be atleast 1 character")
    .max(20, "Company Reg No must be atmost 20 character"),
  certificatePic: Yup.string().required("Certificate Pic is required"),
});

export const storeSchema = Yup.object().shape({
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  address: Yup.string()
    .required("Address is required")
    .min(1, "Address must be atleast 1 character")
    .max(50, "Address must be atmost 50 character"),
});

export const storeLocationSchema = Yup.object().shape({
  longitude: Yup.string().required("Longitude and Latitude is required"),
  latitude: Yup.string().required("Longitude and Latitude is required"),
});

export const taxSchema = Yup.object().shape({
  ntnNo: Yup.string().test(
    "ntnNo",
    "NTN No must lies between 7 to 9 characters",
    (value) => {
      if (!value || value.length === 0) {
        return true; // Field is empty, no further validation is needed
      }

      return value.length >= 7 && value.length <= 9; // Validate length within the specified range
    }
  ),
  taxNo: Yup.string()
    .required("taxNo is required")
    .min(1, "Tax No must be atleast 1 character")
    .max(24, "Tax No must be atmost 24 character"),
});

export const bankSchema = Yup.object().shape({
  accountTitle: Yup.string().test(
    "accountTitle",
    "Account Title must lies between 1 to 20 characters",
    (value) => {
      if (!value || value.length === 0) {
        return true; // Field is empty, no further validation is needed
      }

      return value.length >= 1 && value.length <= 20; // Validate length within the specified range
    }
  ),
  ibanNo: Yup.string()
    .required("IBAN is required")
    .length(24, "IBAN must have exactly 24 characters")
    .matches(
      /^PK\d{2}[A-Z]{4}[A-Z\d]{16}$/,
      "Invalid IBAN No format PK00ABCD0000000000000000"
    ),
});
