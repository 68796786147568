import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import styles from "./modal.module.scss";

const DeclineModal = ({
  isOpen,
  modalSize,
  closeDeclineModal,
  modalTitle,
  handleDeclineTermsAndConditions,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeDeclineModal}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body"></DialogContent>
      <DialogActions title="action">
        <div className={styles.DeclineModalContainer}>
          <div className={styles.DeclineInfoContainer}>
            <p className={styles.DeclineInfoContent}>
              Are you sure you don't want to continue? All the information
              provided earlier will be lost.
            </p>
          </div>
          <div className={styles.DeclineActionBtnContainer}>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={closeDeclineModal}
            >
              No
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleDeclineTermsAndConditions}
            >
              Yes
            </Button>
          </div>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default DeclineModal;
