import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { sellerModes } from "../../constants/enum";
import { getOrderStatistic } from "../../APIs/order";
import DeliveryBus from "../../assets/images/dastgyrWillDeliver.png";
import DeliveryBox from "../../assets/images/iWillDeliver.png";
import styles from "./Orders.module.scss";

const Orders = () => {
  const { modes } = useSelector((state) => state.fulfillmentModes);
  const [orderStatistics, setOrderStatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const goTo = (path) => history.push(path);

  // Fetch Order Statictic
  const fetchOrderStatistic = () => {
    setLoading(true);

    getOrderStatistic()
      .then((res) => {
        if (res.status === 200) {
          setOrderStatistics(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderStatistic();
  }, []);

  return (
    <div className={styles.OrdersWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/dashboard")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          ORDERS
        </Typography>
      </div>
      {modes?.length > 0 && (
        <div className={styles.MainSection}>
          {modes[0]["fulfillmentModeId"] === sellerModes["DEDs"] && (
            <div
              className={styles.ordersStatisticContainer}
              onClick={() => goTo("/orders/deliver-to-dastgyr")}
            >
              <div className={styles.StatisticHeader}>
                <Typography
                  variant={"h2"}
                  color="primary"
                  className={styles.Title}
                >
                  Deliver by Dastgyr
                </Typography>
                <ArrowForwardIosIcon
                  className={styles.BackBtn}
                  fontSize="large"
                />
              </div>
              <div className={styles.OrdersDetails}>
                <div className={styles.StatusLabels}>
                  <p className={styles.ReceivedLabel}>
                    {orderStatistics?.deD_ReceivedOrderCount ?? ".."} Order(s)
                    Received
                  </p>
                  <p className={styles.DeliveredLabel}>
                    {orderStatistics?.deD_PickedUpOrderCount ?? ".."} Order(s)
                    Picked
                  </p>
                  <p className={styles.CancelledLabel}>
                    {orderStatistics?.deD_DeliveredOrderCount ?? ".."} Order(s)
                    Delivered
                  </p>
                </div>
                <div>
                  <img
                    alt="delivery-bus"
                    src={DeliveryBus}
                    className={styles.DeliveryImg}
                  />
                </div>
              </div>
            </div>
          )}
          {modes[0]["fulfillmentModeId"] === sellerModes["DS"] && (
            <div
              className={styles.ordersStatisticContainer}
              onClick={() => goTo("/orders/deliver-to-customer")}
            >
              <div className={styles.StatisticHeader}>
                <Typography
                  variant={"h2"}
                  color="primary"
                  className={styles.Title}
                >
                  I will deliver myself
                </Typography>
                <ArrowForwardIosIcon
                  className={styles.BackBtn}
                  fontSize="large"
                />
              </div>
              <div className={styles.OrdersDetails}>
                <div className={styles.StatusLabels}>
                  <p className={styles.ReceivedLabel}>
                    {orderStatistics?.dS_ReceivedOrderCount ?? ".."} Order(s)
                    Received
                  </p>
                  <p className={styles.ShippedLabel}>
                    {orderStatistics?.dS_ShippedOrderCount ?? ".."} Order(s)
                    Shipped
                  </p>
                  <p className={styles.DeliveredLabel}>
                    {orderStatistics?.dS_DeliveredOrderCount ?? ".."} Order(s)
                    Delivered
                  </p>
                </div>
                <div>
                  <img
                    alt="delivery-box"
                    src={DeliveryBox}
                    className={styles.DeliveryImg}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
