import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
  IconButton,
  Button,
  TextField,
  Snackbar,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CancelIcon from "@material-ui/icons/Cancel";
import Loader from "./../../components/Loader";
import {
  getGlobalSignupSchema,
  getProfileData,
  changePassword,
  updateProfile,
} from "../../APIs/manage-profile";
import styles from "./ManageSellerProfile.module.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageSellerProfile = () => {
  const [fieldData, setSignupSchema] = useState([]);
  const [sellerDetail, setProfileData] = useState({});
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessages] = useState("");
  const [isSnackbarOpen, setSnackbarVisibility] = useState(false);
  const [placeSearchArea, setPlaceSearchArea] = useState(null);
  const [placeSearchCity, setPlaceSearchAreaCity] = useState(null);
  const [passwordValidations, setPasswordValidations] = useState({
    isValidLength: false,
    isCharacterContain: false,
    isNumberContain: false,
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidIBAN, setIsValidIBAN] = useState(false);

  const { profileDetails } = useSelector((state) => state.profileDetails);
  const history = useHistory();
  const selectedPage = useRef();

  let location = profileDetails?.searchAddress;

  if (location) {
    location = JSON.parse(location);
  }

  const goTo = (path) => history.push(path);

  const handleClickShowPassword = (type) => {
    if (type === "old") {
      setShowOldPassword(!showOldPassword);
    } else if (type === "new") {
      setShowPassword(!showPassword);
    }
  };

  const handleInputChange = (e, paramName) => {
    const { value } = e.target;

    setProfileData({
      ...sellerDetail,
      [paramName]: value,
    });
  };

  const isValidate = (position) => {
    let isSaveBtnDisabled = false;

    if (position === 1) {
      isSaveBtnDisabled = sellerDetail["fullName"]?.length >= 2 ? false : true;
    } else if (position === 2) {
      isSaveBtnDisabled =
        sellerDetail["companyName"]?.length >= 2 ? false : true;
    } else if (position === 5) {
      isSaveBtnDisabled = sellerDetail["taxNumber"]?.length >= 1 ? false : true;
    } else if (position === 7) {
      const isValidAccountTitle =
        sellerDetail["accountTitle"]?.length >= 2 ? false : true;
      const isValidAddress =
        sellerDetail["address"]?.length >= 2 ? false : true;

      isSaveBtnDisabled = isValidAccountTitle || isValidAddress;
    }

    return isSaveBtnDisabled;
  };

  const updateProfileDetails = (position) => {
    const payload = {
      fullName: sellerDetail?.fullName ?? "",
      email: sellerDetail?.email ?? "",
      companyName: sellerDetail?.companyName ?? "",
      searchAddress: sellerDetail?.searchAddress ?? "",
      taxNumber: sellerDetail?.taxNumber ?? null,
      accountTitle: sellerDetail?.accountTitle ?? null,
      address: sellerDetail?.address ?? null,
      ibanNumber: sellerDetail?.ibanNumber ?? null,
      cityId: sellerDetail?.city.cityId,
    };

    selectedPage.current = position;

    setLoading(true);
    updateProfile(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setSnackbarVisibility(true);
          setMessages("Updated successfully");
        }
      })
      .catch((_err) => setLoading(false));
  };

  const renderSeller = () => (
    <>
      {fieldData
        ?.filter(
          (field) =>
            (field?.documents && field.documents.isVisible) ||
            field?.inputFields
        )
        .map((page) => {
          return (
            <>
              <div key={page.id} className={styles.ProfileSection}>
                <Typography variant="h3" gutterBottom>
                  {page.name}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {page.position === 8
                    ? "To change your Passport picture, contact Dastgyr helpline"
                    : page.heading}
                </Typography>
                {page.position === 8 && (
                  <div className={styles.IdentityWrapper}>
                    <Typography variant="h5" gutterBottom>
                      Passport
                    </Typography>
                    <div className={styles.ProofContainer}>
                      <CheckCircleIcon
                        style={{ color: "#0aab0a" }}
                        fontSize="large"
                      />
                      <div className={styles.Proof}>
                        <Typography variant="h4">
                          {sellerDetail?.passportFrontImage?.split(
                            ".com/"
                          )[1] ?? "---"}
                        </Typography>
                        <p className={styles.Label}>Uploaded</p>
                      </div>
                    </div>
                    <div className={styles.ProofContainer}>
                      <CheckCircleIcon
                        style={{ color: "#0aab0a" }}
                        fontSize="large"
                      />
                      <div className={styles.Proof}>
                        <Typography variant="h4">
                          {sellerDetail?.passportBackImage?.split(".com/")[1] ??
                            "---"}
                        </Typography>
                        <p className={styles.Label}>Uploaded</p>
                      </div>
                    </div>
                  </div>
                )}
                {page.position === 3 && (
                  <div className={styles.LocationWrapper}>
                    <Typography variant="h5" gutterBottom>
                      Business Location
                    </Typography>
                    <div className={styles.BusinessLocation}>
                      <div className={styles.LocationContainer}>
                        <LocationOnIcon fontSize="large" />
                      </div>
                      <div className={styles.Address}>
                        <p className={styles.Label}>Selected location</p>
                        <Typography variant="h4">
                          {placeSearchArea && placeSearchCity
                            ? `${placeSearchArea} ${placeSearchCity}`
                            : "No location found"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
                {page.position !== 3 && page.position !== 8 && (
                  <>
                    <div className={styles.FieldContainer}>
                      <>
                        {page.inputFields?.items.map((item) => {
                          return (
                            <div>
                              <Typography variant="h5" gutterBottom>
                                {item.title}{" "}
                                {item.dataType === "email" &&
                                  !item.isMandatory &&
                                  `(optional)`}
                              </Typography>
                              <TextField
                                type={page.position === 5 ? "number" : "text"}
                                style={{ width: "100%" }}
                                value={sellerDetail[item.paramName]}
                                name={item.paramName}
                                placeholder={`${item.placeholder}`}
                                variant="outlined"
                                helperText={
                                  item.error && (
                                    <small
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        marginLeft: "-12px",
                                      }}
                                    >
                                      {item.format}
                                    </small>
                                  )
                                }
                                disabled={!item.isEditable}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  const regex = item.validations.find(
                                    (validation) => validation.key === "Regex"
                                  );

                                  if (regex) {
                                    const modifiedRegex = new RegExp(
                                      `${regex["value"]}`
                                    );

                                    if (modifiedRegex.test(val)) {
                                      item.error = false;

                                      if (item?.paramName === "email") {
                                        setIsValidEmail(false);
                                      }

                                      if (item?.paramName === "ibanNumber") {
                                        setIsValidIBAN(false);
                                      }
                                    } else {
                                      item.error = true;

                                      if (item?.paramName === "email") {
                                        setIsValidEmail(true);
                                      }

                                      if (item?.paramName === "ibanNumber") {
                                        setIsValidIBAN(true);
                                      }
                                    }
                                  }

                                  handleInputChange(e, item.paramName);
                                }}
                                inputProps={{
                                  minLength: item.validations.find(
                                    (validation) =>
                                      validation.key === "MinLength"
                                  )?.["value"],
                                  maxLength: item.validations.find(
                                    (validation) =>
                                      validation.key === "MaxLength"
                                  )?.["value"],
                                }}
                              />
                            </div>
                          );
                        })}
                        {page.position == 1 && (
                          <div>
                            <Typography variant="h5" gutterBottom>
                              Your phone number
                            </Typography>
                            <TextField
                              style={{
                                width: "100%",
                                backgroundColor: "#f5f2f2",
                              }}
                              value={sellerDetail["mobile"]}
                              name={"MobileNumber"}
                              placeholder={"Phone Number"}
                              variant="outlined"
                              disabled={true}
                              onChange={() => {}}
                            />
                            <p className={styles.PhoneInfoLabel}>
                              To change your phone number, contact Dastgyr
                              helpline.
                            </p>
                          </div>
                        )}
                      </>
                    </div>
                    <div style={{ textAlign: "right", marginTop: "1rem" }}>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        disabled={
                          page.position === 1 && sellerDetail["email"]
                            ? isValidEmail
                            : page.position === 7 && sellerDetail["ibanNumber"]
                            ? isValidIBAN
                            : isValidate(page.position) ||
                              page.position === selectedPage.current
                            ? loading
                            : false
                        }
                        style={{ textTransform: "capitalize" }}
                        onClick={() => updateProfileDetails(page.position)}
                      >
                        Save changes
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
    </>
  );

  const renderValidationIcon = (isValid, label) => {
    return (
      <>
        {password.length === 0 && (
          <RadioButtonUncheckedIcon
            style={{ color: "#a19f9f" }}
            fontSize="small"
          />
        )}
        {password.length > 0 && !isValid && (
          <CancelIcon style={{ color: "#f34964" }} fontSize="small" />
        )}
        {password.length > 0 && isValid && (
          <CheckCircleIcon style={{ color: "#0aab0a" }} fontSize="small" />
        )}
        <p>{label}</p>
      </>
    );
  };

  const updatePassword = () => {
    const payload = {
      mobile: profileDetails?.mobile ?? "",
      oldPassword,
      password,
    };

    setPasswordLoading(true);
    changePassword(payload)
      .then((res) => {
        if (res?.status === 200) {
          setPassword("");
          setOldPassword("");
          setPasswordLoading(false);
          setSnackbarVisibility(true);
          setMessages("Passwords updated successfully");
        }
      })
      .catch((_err) => setPasswordLoading(false));
  };

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarVisibility(false);
    setMessages("");
  };

  useEffect(() => {
    const fetchGlobalSignupSchema = () => {
      const params = {
        countryId: profileDetails?.city?.countryId,
        sellerTypeId: profileDetails?.sellerTypeId,
      };

      setFetchLoading(true);
      getGlobalSignupSchema(params)
        .then((res) => {
          if (res?.status === 200) {
            const schema = res.data?.pages;

            if (schema?.length) {
              setSignupSchema(schema);
            }

            setFetchLoading(false);
          }
        })
        .catch((_err) => setFetchLoading(false));
    };

    const fetchProfileData = () => {
      setProfileLoading(true);
      getProfileData()
        .then((res) => {
          if (res?.status === 200) {
            const profileData = res.data?.data;

            if (profileData) {
              setProfileData(profileData);
            }

            setProfileLoading(false);
          }
        })
        .catch((_err) => setProfileLoading(false));
    };

    if (profileDetails) {
      fetchGlobalSignupSchema();
      fetchProfileData();
    }
  }, []);

  useEffect(() => {
    const fetchBusinessLocation = () => {
      const URL = `https://maps.google.com/maps/api/geocode/json?key=${process.env.REACT_APP_MAP_API_KEY}&latlng=${location?.latitude}%2C${location?.longitude}`;

      fetch(URL)
        .then((res) => res.json())
        .then((res) => {
          if (res?.status === "OK") {
            console.log("res", res);
            const locationResponse = res;
            let firstName = "",
              secondName = "";
            if (locationResponse.results[0]) {
              for (
                var i = 0;
                i < locationResponse.results[0].address_components.length;
                i++
              ) {
                if (
                  locationResponse.results[0].address_components[i].types[0] ==
                  "route"
                ) {
                  firstName =
                    firstName +
                    locationResponse.results[0].address_components[i].long_name;
                }
                if (
                  locationResponse.results[0].address_components[i].types[0] ==
                  "administrative_area_level_3"
                ) {
                  secondName =
                    secondName +
                    locationResponse.results[0].address_components[i].long_name;
                }
                if (
                  locationResponse.results[0].address_components[i].types[0] ==
                  "administrative_area_level_2"
                ) {
                  secondName =
                    secondName +
                    ", " +
                    locationResponse.results[0].address_components[i].long_name;
                }
                if (
                  locationResponse.results[0].address_components[i].types[0] ==
                  "administrative_area_level_1"
                ) {
                  secondName =
                    secondName +
                    ", " +
                    locationResponse.results[0].address_components[i].long_name;
                }
                if (
                  locationResponse.results[0].address_components[i].types[0] ==
                  "country"
                ) {
                  secondName =
                    secondName +
                    ", " +
                    locationResponse.results[0].address_components[i].long_name;
                }
              }
            }

            if (firstName && secondName) {
              setPlaceSearchArea(firstName);
              setPlaceSearchAreaCity(secondName);
            } else {
              const address = locationResponse.results[0].formatted_address;
              const splitAddress = address.split(",");

              setPlaceSearchArea(splitAddress ? splitAddress[0] : address);
              setPlaceSearchAreaCity(
                splitAddress ? splitAddress.slice(1).join(", ") : ""
              );
            }
          }
        });
    };

    if (profileDetails) {
      fetchBusinessLocation();
    }
  }, []);

  return (
    <div className={styles.SellerProfileWrapper}>
      <div data-testid="profile-header" className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/dashboard")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          MY PARTNER PROFILE
        </Typography>
      </div>
      <Loader
        data-testid="profile-loading"
        loading={profileLoading || fetchLoading}
      />
      <div>
        <div>{renderSeller()}</div>
        <div data-testid="change-password" className={styles.ProfileSection}>
          <Typography variant="h3" gutterBottom>
            Change password
          </Typography>
          <div className={styles.FieldContainer}>
            <div>
              <Typography variant="h5" gutterBottom>
                Old password
              </Typography>
              <TextField
                style={{ width: "100%" }}
                autoComplete="old-password"
                type={showOldPassword ? "text" : "password"}
                value={oldPassword}
                placeholder="Enter old password"
                variant="outlined"
                onChange={(e) => setOldPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => handleClickShowPassword("old")}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Typography variant="h5" gutterBottom>
                Choose a password
              </Typography>
              <TextField
                style={{ width: "100%" }}
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Enter a password"
                variant="outlined"
                onChange={(e) => {
                  const newPassword = e.target.value;

                  const updateValidationState = (validationKey, isValid) => {
                    setPasswordValidations((previousState) => {
                      return {
                        ...previousState,
                        [validationKey]: isValid,
                      };
                    });
                  };

                  updateValidationState(
                    "isNumberContain",
                    /\d/.test(newPassword)
                  );

                  updateValidationState(
                    "isCharacterContain",
                    /[a-zA-Z]/.test(newPassword)
                  );

                  updateValidationState(
                    "isValidLength",
                    newPassword.length >= 8
                  );

                  setPassword(newPassword);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => handleClickShowPassword("new")}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={styles.PasswordValidationContainer}>
            <Typography variant="h5" gutterBottom>
              Your password should contain:
            </Typography>
            <div className={styles.validation}>
              {renderValidationIcon(
                passwordValidations.isValidLength,
                "At least 8 characters"
              )}
            </div>
            <div className={styles.validation}>
              {renderValidationIcon(
                passwordValidations.isCharacterContain,
                "A letter"
              )}
            </div>
            <div className={styles.validation}>
              {renderValidationIcon(
                passwordValidations.isNumberContain,
                "A number"
              )}
            </div>
          </div>
          <div className={styles.ChangePasswordButton}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              disabled={
                !(
                  passwordValidations.isValidLength &&
                  passwordValidations.isNumberContain &&
                  passwordValidations.isCharacterContain
                ) || passwordLoading
              }
              style={{ textTransform: "capitalize" }}
              onClick={updatePassword}
            >
              Confirm Password
            </Button>
          </div>
        </div>
        {/* <div className={styles.UpdateProfileButton}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          disabled={loading}
          style={{ textTransform: "capitalize" }}
          onClick={updateProfileDetails}
        >
          Update Profile
        </Button>
      </div> */}
        <Snackbar
          data-testid="profile-snackbar"
          open={isSnackbarOpen}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ManageSellerProfile;
