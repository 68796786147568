import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../components/Modal";
import CustomizedSlider from "../../components/PrettoSlider";
import { fetchAssignedBadge } from "../../constants";
import { formatDateTime } from "../../../../Utilities";
import { fetchBadgeSpecificColors } from "../../constants";
import styles from "./BadgeDetails.module.scss";

const BadgeDetails = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  badgeDetails,
}) => {
  const { badge, metric, badgeAssignedDate } = badgeDetails;
  const badgeTheme = fetchBadgeSpecificColors(badge?.id);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      modalSize={modalSize}
      onExited={() => {}}
      onEnter={() => {}}
    >
      <DialogTitle title="title" onClose={onClose}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.BadgeContainer}>
          <div
            style={{
              backgroundColor: badgeTheme?.backgroundColor,
            }}
            className={styles.Header}
          >
            <img
              alt="badge"
              src={fetchAssignedBadge(badge?.id)}
              className={styles.BadgeIcon}
            />
            <div className={styles.Body}>
              <div className={styles.Section}>
                <p
                  style={{
                    color: badgeTheme?.color,
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  PERFORMANCE FOR {formatDateTime(badgeAssignedDate, "MMMM")}
                </p>
                <p
                  className={styles.BadgeTitle}
                  style={{ color: badgeTheme?.color, margin: "0px" }}
                >
                  {badge?.badge ?? ""} Badge
                </p>
              </div>
            </div>
          </div>
          <div className={styles.Body}>
            <p className={styles.Label}>YOUR PERFORMANCE METRICS</p>
            {metric?.length > 0 &&
              metric.map(({ metric_name, message, value, target_value }) => {
                const modifiedValue = parseFloat(value.replace("%", ""));

                return (
                  <div key={metric_name} className={styles.PerformanceMetrics}>
                    <div className={styles.MetricsHeader}>
                      <p
                        style={
                          modifiedValue >= target_value
                            ? { color: "#52af77" }
                            : { color: "#f34964" }
                        }
                      >
                        {metric_name}
                      </p>
                      <p
                        style={
                          modifiedValue >= target_value
                            ? { color: "#52af77" }
                            : { color: "#f34964" }
                        }
                      >
                        {value}
                      </p>
                    </div>
                    <CustomizedSlider
                      value={value}
                      targetValue={target_value}
                    />
                    <p className={styles.MetricsMessage}>{message}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.PerformanceDetailsActionBtn}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default BadgeDetails;
