import { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import ActiveListings from "./ActiveListings";
import InactiveListings from "./InactiveListings";
import PendingListings from "./PendingListings";
import styles from "./ViewListings.module.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.TabPanelContainer}>{children}</div>
      )}
    </div>
  );
}

const ViewListings = ({ isRender }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_e, tabIndex) => setTabIndex(tabIndex);

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Active" />
        <Tab label="Inactive" />
        <Tab label="Pending" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <ActiveListings isRender={isRender} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <InactiveListings isRender={isRender} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <PendingListings isRender={isRender} />
      </TabPanel>
    </>
  );
};

export default ViewListings;
