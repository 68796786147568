import { useState, useEffect } from "react";
import styles from "./Timer.module.scss";

const CountdownTimer = ({ initialSeconds = 29, isTimerFinished }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let intervalId;

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      isTimerFinished(true);
    }

    if (seconds === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [seconds, isTimerFinished]);

  const formattedTime = (minutes, seconds) => {
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const getCountdownTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const timer = formattedTime(minutes, seconds);

    return timer;
  };

  return <p className={styles.Timer}>{getCountdownTime(seconds)}</p>;
};

export default CountdownTimer;
