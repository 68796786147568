import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Snackbar,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MuiAlert from "@material-ui/lab/Alert";
import SKUsListings from "./SKUsListing";
import SKUItem from "./SKUItem";
import ListingApprovalModal from "../Modal/ListingApprovalModal";
import DeliveryAndTimeModal from "../Modal/DeliveryAndTimeModal";
import DeliveryCityApprovalModal from "../Modal/DeliveryCityApprovalModal";
// import SKUDeliverByModal from "../Modal/SKUDeliverByModal";
// import BulkSKUWarningModal from "../Modal/BulkSKUWarningModal";
// import AutoApprovalModal from "../Modal/AutoApprovalModal";
import { isValidDecimal, getPrecision } from "../../../Utilities";
import {
  getProducts,
  getSKUMinPrice,
  addProductSKU,
} from "../../../APIs/listing";
import {
  getDeliveryCities,
  getSellerDeliveryCities,
  addSellerDeliveryCities,
} from "../../../APIs/delivery-city-time";
import { pricingTypes, initialVariablePricings } from "./constants";
import styles from "./ManageListing.module.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageListing = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [SKUs, setSKUs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [SKUPrice, setSKUPrice] = useState("");
  const [mrp, setMRP] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [minOrderQty, setMinOrderQty] = useState(1);
  const [moqError, setMoqError] = useState("");
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [SKUMinPrice, setSKUMinPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qty, setQty] = useState(1);
  const [approvalModel, SetApprovalModel] = useState(false);
  const [deliveryCities, setDeliveryCities] = useState([]);
  const [sellerDeliveryCities, setSellerDeliveryCities] = useState([]);
  const [selectedDeliveryCity, setSelectedDeliveryCity] = useState();
  const [selectedPricingType, setSelectedPricingType] = useState(1);
  const [isDeliveryApprovalModalOpen, setIsDeliveryApprovalModalOpen] =
    useState(false);
  const [isDeliveryAndTimeModalOpen, setIsDeliveryAndTimeModalOpen] =
    useState(false);
  const [isSnackbarOpen, setSnackbarVisibility] = useState(false);
  const [variablePricings, setVariablePricings] = useState([
    ...initialVariablePricings,
  ]);
  const [moq, setMOQ] = useState(0);

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const history = useHistory();
  const productRef = useRef(false);
  const SKUMinPriceRef = useRef(false);
  const SKUListingDetails = useRef(null);
  const mlv = useRef(profileDetails?.minimumListingValue ?? 1500);

  const backToListing = () => history.push("/listing");
  const handleSearchTerm = (e) => setSearchTerm(e.target.value);

  // Fetch Products
  const fetchProducts = useCallback(() => {
    getProducts(searchTerm)
      .then((res) => {
        if (res.status === 200) {
          setSKUs(res.data.products);
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  }, [searchTerm, setSKUs, setLoading]);

  useEffect(() => {
    setLoading(true);

    if (productRef.current && searchTerm.length > 0) {
      let timer;

      const debounceSearch = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          fetchProducts();
        }, 500);
      };

      debounceSearch();

      return () => clearTimeout(timer);
    } else {
      productRef.current = true;
      setSKUs([]);
    }
  }, [searchTerm, fetchProducts]);

  // Fetch SKU Min Price
  const fetchSKUMinPrice = useCallback(() => {
    const { id } = selectedSKU ?? {};
    const query = { productVariantId: id };

    getSKUMinPrice(query).then((res) => {
      if (res.status === 200) {
        setSKUMinPrice(res.data.data);
      }
    });
  }, [selectedSKU, setSKUMinPrice]);

  useEffect(() => {
    if (SKUMinPriceRef.current && selectedSKU) {
      fetchSKUMinPrice();
    } else {
      SKUMinPriceRef.current = true;
    }
  }, [selectedSKU, fetchSKUMinPrice]);

  const createProductSKU = () => {
    const { id } = selectedSKU ?? {};

    const payload = {
      productVariantId: id,
      quantity: Number(qty),
      isVariablePricing: false,
      fulfilmentModeId: profileDetails?.fulFillmentModeId,
      autoApproved: false,
      ...(expiryDate && {
        expiryDate: expiryDate.toISOString(),
      }),
      ...(mrp && { mrp: Number(mrp) }),
    };

    if (selectedPricingType === 1) {
      payload["moq"] = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));
      payload["price"] = Number(SKUPrice ?? 0);
      payload["minimumQuantity"] = Number(minOrderQty);
      payload["isVariablePricing"] = false;
      payload["skuVariablePricings"] = [];
    }

    if (selectedPricingType === 2) {
      payload["isVariablePricing"] = true;
      payload["skuVariablePricings"] = variablePricings.map(
        ({ price, qty }) => {
          return {
            price: Number(price),
            minOrderQty: Number(qty),
          };
        }
      );
      payload["price"] = Number(variablePricings[0]["price"]);
      payload["minimumQuantity"] = Number(variablePricings[0]["qty"]);
      payload["moq"] = Math.ceil(
        Number(mlv.current) / Number(variablePricings[0]["price"])
      );
    }

    setAddProductLoading(true);
    addProductSKU(payload)
      .then((res) => {
        if (res.status === 200) {
          setIsModalOpen(true);
          setAddProductLoading(false);
          SKUListingDetails.current = res.data.data ?? {};
        }
      })
      .catch((_err) => {
        setAddProductLoading(false);
      });
  };

  const handleApprovalSubmit = () => {
    setIsModalOpen(false);
    history.push("/listing");
  };

  const openDeliveryApprovalModal = () => setIsDeliveryApprovalModalOpen(true);
  const closeDeliveryApprovalModal = () =>
    setIsDeliveryApprovalModalOpen(false);

  const openDeliveryAndTimeModal = () => setIsDeliveryAndTimeModalOpen(true);
  const closeDeliveryAndTimeModal = () => setIsDeliveryAndTimeModalOpen(false);

  const manageDeliveryCityAddition = () => {
    const payload = { cityId: selectedDeliveryCity };

    SetApprovalModel(true);

    addSellerDeliveryCities(payload)
      .then((res) => {
        if (res?.status === 201) {
          closeDeliveryApprovalModal();
          closeDeliveryAndTimeModal();
          SetApprovalModel(false);
        }
      })
      .catch((_e) => {
        closeDeliveryApprovalModal();
        closeDeliveryAndTimeModal();
        SetApprovalModel(false);
      });
  };

  const fetchDeliveryCities = () => {
    getDeliveryCities().then((res) => {
      if (res.status === 200) {
        const cities = res.data;
        setDeliveryCities(cities);
      }
    });
  };

  const fetchSellerDeliveryCities = () => {
    getSellerDeliveryCities()
      .then((res) => {
        if (res.status === 200) {
          const cities = res.data;
          setSellerDeliveryCities(cities);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (
          response?.data.statusCode === 404 &&
          response?.data.message === "No data found"
        ) {
          setSellerDeliveryCities([]);
        }
      });
  };

  const calculateMoq = () => {
    if (SKUPrice) {
      const moq = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));

      setMOQ(moq);

      if (moq <= qty) {
        setMinOrderQty(moq);
        setMoqError("");
      } else {
        setMinOrderQty(1);
        setMoqError(
          `Your minimum order quantity cannot be less then ${moq}. Either increase your order quantity or price.`
        );
      }
    }
  };

  const calculateMoqOnMInimumQuantity = () => {
    if (SKUPrice) {
      const moq = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));
      const errMsg = `Your minimum order quantity cannot be less then ${moq}. Either increase your order quantity or price.`;

      if (Number(SKUPrice ?? 0) * Number(minOrderQty) < Number(mlv.current)) {
        setMoqError(errMsg);
      } else {
        setMoqError("");
      }

      if (
        !(Number(SKUPrice ?? 0) * Number(minOrderQty) >= Number(mlv.current))
      ) {
        setMinOrderQty(1);
      }
    }
  };

  const isSubmitBtnDisabled = () => {
    if (Number(SKUPrice ?? 0) * Number(minOrderQty) < Number(mlv.current)) {
      return false;
    } else {
      return true;
    }
  };

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarVisibility(false);
  };

  const addVariablePricing = () => {
    const allAvailableSlabFilled = variablePricings.every(
      ({ price, qty }) => price && qty
    );

    if (allAvailableSlabFilled) {
      const duplicatePricings = [...variablePricings];
      const variablePrice = {
        price: "",
        qty: "",
        isInvalidPrice: false,
        isInvalidQty: false,
      };

      duplicatePricings.push(variablePrice);
      setVariablePricings([...duplicatePricings]);
    } else {
      setSnackbarVisibility(true);
    }
  };

  const isValidPricings = () => {
    const isAllSlabFilled = variablePricings.every(
      ({ price, qty }) => price && qty
    );

    return !isAllSlabFilled;
  };

  const handleSKUQty = (e, idx) => {
    const qty = e.target.value;
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      qty,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const verifySKUQty = (e, idx) => {
    const currentQty = Number(e.target.value);
    const duplicatePricings = [...variablePricings];

    if (idx > 0) {
      const isPreviousPriceExist = duplicatePricings[idx - 1];
      const previousQty = isPreviousPriceExist["qty"];

      if (previousQty && Number(currentQty) <= Number(previousQty)) {
        duplicatePricings[idx] = {
          ...duplicatePricings[idx],
          qty: Number(previousQty) + 1,
          isInvalidQty: true,
        };
      }

      setVariablePricings([...duplicatePricings]);
    }
  };

  const handlePricingTypes = (e) => {
    const type = Number(e.target.value);
    setSelectedPricingType(type);
  };

  const resetVariablePricings = () => {
    setSelectedPricingType(1);
    setVariablePricings([...initialVariablePricings]);
  };

  const deleteVariablePricing = (idx) => {
    const duplicatePricings = [...variablePricings];

    if (idx > 0) {
      duplicatePricings[idx - 1] = {
        ...duplicatePricings[idx - 1],
        isDisabled: false,
      };
    }

    if (idx === 1) {
      duplicatePricings[idx] = {
        price: "",
        qty: "",
        isInvalidPrice: false,
        isInvalidQty: false,
        isDisabled: false,
      };
    } else {
      duplicatePricings.splice(idx, 1);
    }

    setVariablePricings([...duplicatePricings]);
  };

  const handleSKUPrice = (e, idx) => {
    const price = e.target.value;
    const precision = profileDetails?.city?.currencyPrecision;
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      price,
    };

    if (idx === 0) {
      if (isValidDecimal(price, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    } else {
      duplicatePricings[idx - 1] = {
        ...duplicatePricings[idx - 1],
        isDisabled: price ? true : false,
      };

      if (isValidDecimal(price, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    }
  };

  const verifySKUPrice = (e, idx) => {
    const currentPrice = Number(e.target.value);
    const duplicatePricings = [...variablePricings];
    const precision = profileDetails?.city?.currencyPrecision;
    const moq = currentPrice
      ? Math.ceil(Number(mlv.current) / Number(currentPrice))
      : "";

    if (idx === 0) {
      duplicatePricings[idx] = {
        ...duplicatePricings[idx],
        price: currentPrice,
        qty: moq,
      };

      if (isValidDecimal(currentPrice, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    } else {
      const isPreviousPriceExist = duplicatePricings[idx - 1];

      if (isPreviousPriceExist) {
        const previousPrice = isPreviousPriceExist["price"];
        const previousQty = isPreviousPriceExist["qty"];

        if (previousPrice && Number(currentPrice) >= Number(previousPrice)) {
          duplicatePricings[idx] = {
            ...duplicatePricings[idx],
            qty: "",
            price: currentPrice,
            isInvalidPrice: true,
          };
        } else {
          duplicatePricings[idx] = {
            ...duplicatePricings[idx],
            qty:
              currentPrice && previousQty >= moq
                ? Number(previousQty) + 1
                : moq,
            price: currentPrice,
            isInvalidPrice: false,
          };
        }

        if (isValidDecimal(currentPrice, precision)) {
          setVariablePricings([...duplicatePricings]);
        }
      }
    }
  };

  const resetSKUPrice = (e, idx) => {
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      isInvalidPrice: false,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const resetSKUPQty = (e, idx) => {
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      isInvalidQty: false,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const getPricingStatus = (SKUPrice) => {
    const price = Number(SKUPrice);
    const bestPrice = Number(SKUMinPrice[0]);
    const currencySymbol = profileDetails?.currency ?? "";
    let priceStatusInfo = `Best Price: ${currencySymbol}. ${bestPrice}`;

    if (SKUMinPrice.length === 1) {
      if (price && price <= SKUMinPrice[0]) {
        priceStatusInfo = "Your listing is best priced.";
      } else if (price) {
        priceStatusInfo = "This is slightly higher than best price.";
      }

      return priceStatusInfo;
    } else {
      for (let i = 1; i < SKUMinPrice.length; i++) {
        if (price && price <= SKUMinPrice[0]) {
          priceStatusInfo = "Your listing is best priced.";
        } else if (
          price &&
          price > SKUMinPrice[i - 1] &&
          price <= SKUMinPrice[i]
        ) {
          priceStatusInfo = "This is slightly higher than best price.";
        }
      }

      if (price && price > SKUMinPrice[SKUMinPrice.length - 2]) {
        priceStatusInfo = "This is way higher than best price.";
      }
    }

    return priceStatusInfo;
  };

  const getPricingStatusClass = (SKUPrice) => {
    const price = Number(SKUPrice);
    let className;

    if (SKUMinPrice.length === 1) {
      if (price && price <= SKUMinPrice[0]) {
        className = "BestPriced";
      } else if (price) {
        className = "SlightlyHigher";
      }
    } else {
      for (let i = 1; i < SKUMinPrice.length; i++) {
        if (price && price <= SKUMinPrice[0]) {
          className = "BestPriced";
        } else if (
          price &&
          price > SKUMinPrice[i - 1] &&
          price <= SKUMinPrice[i]
        ) {
          className = "SlightlyHigher";
        }
      }

      if (price && price > SKUMinPrice[SKUMinPrice.length - 2]) {
        className = "WayHigher";
      }
    }

    return className ?? "BestPriceBadge";
  };

  useEffect(() => {
    calculateMoq();
  }, [qty]);

  useEffect(() => {
    fetchDeliveryCities();
    fetchSellerDeliveryCities();
  }, []);

  return (
    <div className={styles.ManageListingWrapper}>
      <div className={styles.Header}>
        <IconButton
          className={styles.BackBtn}
          color="primary"
          onClick={backToListing}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          CREATE NEW LISTING
        </Typography>
      </div>
      <div className={styles.MainSection}>
        <div className={styles.SearchSKUsContainer}>
          <TextField
            className={styles.SearchField}
            value={searchTerm}
            label="Search SKU Name"
            variant="outlined"
            required={true}
            onChange={handleSearchTerm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm ? (
                    <IconButton
                      className={styles.BackBtn}
                      onClick={() => {
                        setSearchTerm("");
                        setSKUs([]);
                      }}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {searchTerm.length > 0 && (
            <div className={styles.SKUsItems}>
              {loading && (
                <Typography className={styles.SearchingMsg} color={"primary"}>
                  We are searching for your products. Please wait.
                </Typography>
              )}
              {!loading && SKUs.length === 0 && (
                <Typography className={styles.NotFound} color={"primary"}>
                  No Product Found
                </Typography>
              )}
              {!loading && SKUs.length > 0 && (
                <SKUsListings
                  SKUs={SKUs}
                  setQty={setQty}
                  setSelectedSKU={setSelectedSKU}
                  setSearchTerm={setSearchTerm}
                />
              )}
            </div>
          )}
          {selectedSKU && (
            <SKUItem
              qty={qty}
              setQty={setQty}
              calculateMoq={calculateMoq}
              selectedSKU={selectedSKU}
              setSKUPrice={setSKUPrice}
              setSKUMinPrice={setSKUMinPrice}
              resetVariablePricings={resetVariablePricings}
              setSelectedSKU={setSelectedSKU}
            />
          )}
        </div>
        <div
          style={{
            ...(!selectedSKU && {
              pointerEvents: "none",
              opacity: 0.5,
            }),
          }}
          className={styles.PricingContainer}
        >
          <Typography variant="h4">Select Pricing</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedPricingType}
              onChange={handlePricingTypes}
              className={styles.PricingTypeContainer}
            >
              {pricingTypes?.map((type) => (
                <FormControlLabel
                  key={type.id}
                  value={type.id}
                  label={type.name}
                  control={<Radio color="primary" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {selectedPricingType === 1 && (
            <>
              <div className={styles.FixedPriceContainer}>
                <TextField
                  value={SKUPrice}
                  label={`Price (${profileDetails?.currency ?? ""})`}
                  placeholder={getPrecision(
                    profileDetails?.city?.currencyPrecision
                  )}
                  variant="outlined"
                  required={true}
                  onChange={(e) => {
                    const price = e.target.value;
                    const precision = profileDetails?.city?.currencyPrecision;

                    if (isValidDecimal(price, precision)) {
                      setSKUPrice(price);
                    }
                  }}
                  onBlur={calculateMoq}
                />
                <TextField
                  value={minOrderQty}
                  label="Minimum Order Quantity"
                  variant="outlined"
                  onChange={(e) => {
                    const minimumQty = e.target.value;
                    if (selectedSKU) {
                      if (minimumQty <= qty) {
                        setMinOrderQty(minimumQty);
                      } else {
                        setSnackbarVisibility(true);
                      }
                    } else {
                      setSnackbarVisibility(true);
                    }
                  }}
                  onBlur={calculateMoqOnMInimumQuantity}
                />
              </div>
              {moqError ? (
                <p className={styles.MOQErrorMsg}>{moqError}</p>
              ) : (
                <>
                  {SKUMinPrice?.length > 0 && (
                    <p
                      className={`${styles.PricingStatus} ${
                        styles[getPricingStatusClass(SKUPrice)]
                      } `}
                    >
                      {getPricingStatus(SKUPrice)}
                    </p>
                  )}
                </>
              )}
            </>
          )}
          {selectedPricingType === 2 && (
            <>
              {variablePricings.map((pricing, idx) => {
                return (
                  <>
                    <div key={idx} className={styles.VariablePricingContainer}>
                      <div>
                        <TextField
                          value={pricing.price}
                          label={`Price (${profileDetails?.currency ?? ""})`}
                          placeholder={getPrecision(
                            profileDetails?.city?.currencyPrecision
                          )}
                          variant="outlined"
                          required={true}
                          onChange={(e) => handleSKUPrice(e, idx)}
                          onBlur={(e) => verifySKUPrice(e, idx)}
                          onFocus={(e) => resetSKUPrice(e, idx)}
                          style={{ width: "100%" }}
                          disabled={pricing.isDisabled}
                        />
                        {pricing.isInvalidPrice && (
                          <p className={styles.MOQErrorMsg}>
                            Price cannot be greater than previous slab.
                          </p>
                        )}
                      </div>
                      <div>
                        <TextField
                          value={pricing.qty}
                          label="Minimum Order Quantity"
                          variant="outlined"
                          onChange={(e) => handleSKUQty(e, idx)}
                          onBlur={(e) => verifySKUQty(e, idx)}
                          onFocus={(e) => resetSKUPQty(e, idx)}
                          style={{ width: "100%" }}
                          disabled={
                            pricing.isDisabled ||
                            pricing.isInvalidPrice ||
                            !pricing.price
                          }
                        />
                        {pricing.isInvalidQty && (
                          <p className={styles.MOQErrorMsg}>
                            Quantity should be greater than previous slab.
                          </p>
                        )}
                      </div>
                      {variablePricings.length - 1 === idx && (
                        <div className={styles.RemovePricingButton}>
                          <IconButton
                            color="primary"
                            onClick={() => deleteVariablePricing(idx)}
                          >
                            <RemoveCircleOutlineIcon fontSize="large" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    {idx === 0 &&
                      (pricing.qty && Number(pricing.qty) > Number(qty) ? (
                        <p className={styles.MOQErrorMsg}>
                          Minimum order quantity should be lower than the listed
                          quantity. Please update the listing quantity.
                        </p>
                      ) : (
                        <>
                          {SKUMinPrice?.length > 0 && (
                            <p
                              className={`${styles.PricingStatus} ${
                                styles[getPricingStatusClass(pricing.price)]
                              } `}
                            >
                              {getPricingStatus(pricing.price)}
                            </p>
                          )}
                        </>
                      ))}
                  </>
                );
              })}
              <div style={{ marginTop: "12px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addVariablePricing}
                  disabled={Number(variablePricings[0]["qty"]) > Number(qty)}
                  className={styles.ManageListingBtn}
                >
                  + Add option
                </Button>
              </div>
            </>
          )}
        </div>
        <div className={styles.OptionalFieldsContainer}>
          <div className={styles.DateContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="expiry-date-picker-dialog"
                label="Expiry Date"
                format="MM/dd/yyyy"
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
              />
              <TextField
                value={mrp}
                label={`Market Retail Price (${
                  profileDetails?.currency ?? ""
                })`}
                placeholder={getPrecision(
                  profileDetails?.city?.currencyPrecision
                )}
                variant="outlined"
                onChange={(e) => {
                  const price = e.target.value;
                  const precision = profileDetails?.city?.currencyPrecision;

                  if (isValidDecimal(price, precision)) {
                    setMRP(price);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        {selectedPricingType === 1 && (
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={selectedSKU ? 1000 : 3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={selectedSKU ? "error" : "info"}
            >
              {selectedSKU
                ? "Minimum order quantity should be less than Listing quantity"
                : "Add SKU price to enter MOQ"}
            </Alert>
          </Snackbar>
        )}
        {selectedPricingType === 2 && (
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={"info"}>
              Please filling the last slab first.
            </Alert>
          </Snackbar>
        )}
      </div>

      <div className={styles.SubmitBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={createProductSKU}
          disabled={
            addProductLoading ||
            (selectedPricingType === 1
              ? !moqError &&
                isSubmitBtnDisabled() &&
                selectedSKU?.baseUomId === 1
                ? !(SKUPrice && selectedSKU)
                : !(SKUPrice && selectedSKU && /^\d+(\.\d{1})$/.test(qty))
              : Number(variablePricings[0]["qty"]) > Number(qty) ||
                isValidPricings())
          }
          className={styles.ManageListingBtn}
        >
          Add new listing
        </Button>
      </div>

      <ListingApprovalModal
        isOpen={isModalOpen}
        modalTitle=""
        modalSize="sm"
        SKUListingDetails={SKUListingDetails.current}
        handleApprovalSubmit={handleApprovalSubmit}
        approvalType={"createListing"}
      />

      <DeliveryAndTimeModal
        isOpen={isDeliveryAndTimeModalOpen}
        modalSize="md"
        modalTitle="Select Delivery City"
        deliveryCities={deliveryCities}
        setSelectedDeliveryCity={setSelectedDeliveryCity}
        closeModal={closeDeliveryAndTimeModal}
        openDeliveryApprovalModal={openDeliveryApprovalModal}
      />

      <DeliveryCityApprovalModal
        modalSize="sm"
        modalTitle="Add City"
        addDeliveryCity={manageDeliveryCityAddition}
        selectedDeliveryCity={selectedDeliveryCity}
        fulfilmentModeId={profileDetails?.fulFillmentModeId}
        onClose={closeDeliveryApprovalModal}
        isOpen={isDeliveryApprovalModalOpen}
        approvalModel={approvalModel}
      />

      {/* <BulkSKUWarningModal
        isOpen={isBulkSKUModalOpen}
        modalTitle=""
        modalSize="sm"
        closeModal={closeBulkSKUModal}
      /> */}

      {/* <SKUDeliverByModal
        isOpen={isDeliverModalOpen}
        modalTitle="SKU Deliver"
        modalSize="sm"
        onClose={closeAskingDeliveryByModal}
        loading={addProductLoading}
        createProductSKU={createProductSKU}
      /> */}

      {/* <AutoApprovalModal
        modalSize="sm"
        modalTitle="Listing Approval"
        closeModal={closeListingApprovalModal}
        isOpen={isListingApprovalModalOpen}
        SKUListingDetails={SKUListingDetails.current}
        SKUPrice={SKUPrice}
        qty={qty}
        manageListing={manageListing}
        approvalType={"createListing"}
      /> */}
    </div>
  );
};

export default ManageListing;
