import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Typography,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../../../components/Loading";
import NotFoundMsg from "../../../components/NotFoundMsg";
import { setSnackbarDetails } from "../../../features/snackbar/snackbarSlice";
import {
  getSellerDeliveryCities,
  updateSellerDeliveryCities,
} from "../../../APIs/delivery-city-time";
import styles from "../Areas&Times.module.scss";

const DeliveryTimes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const goTo = (path) => history.push(path);

  const { mutate: addTime, isLoading: saveTimeLoading } = useMutation(
    updateSellerDeliveryCities,
    {
      onSuccess: (res) => {
        console.log("res", res);
        if (res.status === 200) {
          queryClient.invalidateQueries("seller-delivery-cities");
          dispatch(
            setSnackbarDetails({
              open: true,
              message: "Updated Successfully",
              severity: "success",
              hideDuration: 1000,
            })
          );
        }
      },
      onError: (error) => {
        console.log("e", error);
      },
    }
  );

  const fetchSellerDeliveryCities = async () => await getSellerDeliveryCities();
  const { data: deliveryCities, isLoading: loading } = useQuery(
    "seller-delivery-cities",
    fetchSellerDeliveryCities
  );

  const incrementTime = (idx) => {
    const queryObject = queryClient.getQueryData("seller-delivery-cities");
    const duplicateCities = [...queryObject.data];
    const maxQty = duplicateCities[idx]["maxDays"];
    let time = duplicateCities[idx]["days"];
    if (time < maxQty) {
      time++;
    }
    duplicateCities[idx] = { ...duplicateCities[idx], days: time };
    queryObject.data = [...duplicateCities];
    queryClient.setQueryData("seller-delivery-cities", { ...queryObject });
  };

  const decrementTime = (idx) => {
    const queryObject = queryClient.getQueryData("seller-delivery-cities");
    const duplicateCities = [...queryObject.data];
    let time = duplicateCities[idx]["days"];
    if (time > 1) {
      time--;
    }
    duplicateCities[idx] = { ...duplicateCities[idx], days: time };
    queryObject.data = [...duplicateCities];
    queryClient.setQueryData("seller-delivery-cities", { ...queryObject });
  };

  const addDeliveryTime = () => {
    const payload = deliveryCities;
    addTime(payload);
  };

  return (
    <div className={styles.DeliveryAreaAndTimeWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/delivery/areas-times")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          Delivery Times
        </Typography>
      </div>
      {loading && <Loading />}
      {!loading && !deliveryCities && (
        <NotFoundMsg variant="h4" msg="No cities found" />
      )}
      {deliveryCities?.data?.length > 0 && (
        <div className={styles.Section}>
          <div className={styles.UpperSection}>
            <p className={styles.SectionHeading}>
              Select Maximum Delivery Time for each Area
            </p>
            <List>
              {deliveryCities?.data?.map((data, idx) => (
                <div className={styles.CityListItem} key={data.cityId}>
                  <ListItem>
                    <ListItemText primary={data.name} />
                    <div className={styles.TimeManageContainer}>
                      <IconButton
                        color="primary"
                        className={styles.DecrementBtn}
                        onClick={() => decrementTime(idx)}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography className={styles.TimeText}>
                        {data.days} Day(s)
                      </Typography>
                      <IconButton
                        color="primary"
                        className={styles.IncrementBtn}
                        onClick={() => incrementTime(idx)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </div>
          <div className={styles.ButtonDeliverySection}>
            <Button
              color="primary"
              variant="contained"
              style={{ textTransform: "capitalize" }}
              onClick={addDeliveryTime}
              disabled={saveTimeLoading}
            >
              Save Delivery Times
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeliveryTimes;
