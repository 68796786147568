import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NotFoundIcon from "../../assets/svg/404.svg";
import styles from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const { userDetails } = useSelector((state) => state.userDetails);
  const history = useHistory();

  const goTo = (path) => history.push(path);

  return (
    <div className={styles.NotFoundWrapper}>
      {userDetails && (
        <IconButton color="primary" onClick={() => goTo("/dashboard")}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      )}
      <div className={styles.NotFoundContainer}>
        <Typography variant="h1">
          404: The page you are looking for isn’t here
        </Typography>
        <Typography variant="h6">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>
        <NotFoundIcon width="360px" height="360px" />
      </div>
    </div>
  );
};

export default NotFoundPage;
