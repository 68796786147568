import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import { Button, Typography } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loader from "../../components/Loader";
import CountdownTimer from "../../components/CountdownTimer";
import OTPInput from "../../components/OTPInput";
import OTPMessage from "../../components/core/OTPMessage";
import {
  removeSnackbarDetails,
  setSnackbarDetails,
} from "../../features/snackbar/snackbarSlice";
import { OTPLength } from "../../constants/enum";
import { verifyOTP, forgotPassword, generateOTP } from "../../APIs/auth";
import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const [OTP, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOTPLoading, setOTPLoading] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);

  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleOTP = (otp) => setOTP(otp);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleForgotPassword = () => {
    const payload = {
      password: newPassword,
      mobile: `+${state?.phone}`,
    };

    setLoading(true);
    forgotPassword(payload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setNewPassword("");
          setConfirmPassword("");

          dispatch(
            setSnackbarDetails({
              open: true,
              message: res.data,
              severity: "success",
              hideDuration: 1000,
            })
          );

          setTimeout(
            () => history.push("/new-password", { phone: state?.phone }),
            1000
          );
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleOTPGeneration = () => {
    const payload = {
      userName: `+${state?.phone}`,
      isTwilio: false,
    };

    setOTPLoading(true);
    generateOTP(payload)
      .then((res) => {
        if (res && res.status === 200) {
          setOTPLoading(false);

          if (timerFinished) {
            setTimerFinished(false);
          }
        }
      })
      .catch((_err) => setOTPLoading(false));
  };

  const handleOTPVerification = () => {
    const payload = {
      userName: `+${state?.phone}`,
      otp: OTP,
    };

    setOTPLoading(true);
    verifyOTP(payload)
      .then((res) => {
        if (res?.status === 200 && res?.data?.id) {
          setOTPLoading(false);
          setIsOTPVerified(true);
        }
      })
      .catch((err) => {
        const res = err.response;

        if (res?.status === 400) {
          setOTPLoading(false);
        }

        setIsOTPVerified(false);
      });
  };

  const isTimerFinished = (status) => setTimerFinished(status);

  useEffect(() => {
    if (OTP.length === 6) {
      handleOTPVerification();
    }
  }, [OTP]);

  return (
    <div className={styles.ForgotPasswordWrapper}>
      <Loader loading={isOTPLoading} />
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => history.push("/")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant="h3" color="primary" className={styles.Title}>
          CREATE NEW PASSWORD
        </Typography>
      </div>
      {!isOTPVerified ? (
        <div className={styles.OTPContainer}>
          <Typography
            style={{ textAlign: "center" }}
            variant="h2"
            color="primary"
          >
            Enter OTP
          </Typography>
          <OTPInput
            autoFocus
            isNumberInput
            length={OTPLength}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otp) => handleOTP(otp)}
          />
          {!isOTPLoading && (
            <div className={styles.OTPTimerContainer}>
              <CountdownTimer isTimerFinished={isTimerFinished} />
              <OTPMessage
                handleOTPGeneration={handleOTPGeneration}
                timerFinished={timerFinished}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.PasswordContainer}>
          <TextField
            id="new-password"
            className={styles.Password}
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            label="Enter new password"
            variant="outlined"
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={styles.Password}
            autoComplete="confirm-password"
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            label="Confirm new password"
            variant="outlined"
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={
              !(
                newPassword?.length >= 6 &&
                confirmPassword?.length >= 6 &&
                newPassword === confirmPassword
              ) || loading
            }
            color="primary"
            variant="contained"
            onClick={handleForgotPassword}
            className={styles.SubmitBtn}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
