export const USER_BY_MOBILE = "/Accounts/UserbyMobile";
export const GENERATE_OTP = "/Accounts/v2/GenerateOTP";
export const VERIFY_OTP = "/Accounts/VerifyOTP";
export const REGISTER = "/Profile/Register";
export const ACCOUNT_LOGIN = "/Accounts/Login";
export const FORGOT_PASSWORD = "/Accounts/ForgotPassword";
export const VERTICALS = "/Categories/GetVerticals";
export const GET_SINGUP_SCHEMA = "/GlobalSignupConfig/Schema";
export const GET_PROFILE_DETAILS = "/Profile/V2/GetProfileData";
export const UPDATE_PROFILE = "/Profile/UpdateProfile";
export const CHANGE_PASSWORD = "/Accounts/ChangePassword";

// Listing
export const GET_PRODUCTS = "/ProductSkuUsers/GetProduct";
export const GET_SKU_MIN_PRICE = "/Listings/GetSkuMinPrice";
export const VALIDATE_LOCATION = "/Listings/IsDeliveryLocationExist";
export const GET_FULFILLMENT_MODE =
  "/SellerAppConfiguration/v2/FulfillmentModeMenuItems";
export const ADD_PRODUCT_SKU_USER = "/Listings/CreateListing";
export const GET_ALL_PRODUCT_SKU_USERS =
  "/ProductSkuUsers/GetAllProductSkuUsers";
export const GET_SKU_REQUEST = "/ProductSkuUsers/GetSKURequest";
export const GET_SKU_CHANGE_REQUEST = "/ProductSkuUsers/GetSKUChangeRequest";
export const UPDATE_SKU_VISIBILITY = "/ProductSkuUsers/UpdateVisibility";
export const DELETE_SKU_REQUEST = "/ProductSkuUsers/DeleteSkuRequest";
export const DELETE_SKU_CHANGE_REQUEST =
  "/ProductSkuUsers/DeleteSkuChangeRequest";
export const DELETE_INACTIVE_SKU = "/ProductSkuUsers/DeleteInactiveSku";
export const SELLER_CHANGE_REQUEST = "/Listings/UpdateListing";

// Bulk Listing
export const CREATE_BULK_TEMPLATE = "/Template/CreateBulkTemplate";
export const GET_SELLER_DELETE_SKUs = "/Template/GetSellerDeleteSKUs";
export const GET_SELLER_EDIT_SKUs = "/Template/GetSellerEditSKUs";
export const IMPORT_BULK_LISTING = "/file-import";

// DS
export const GET_ORDER_STATISTIC = "/Dashboard/GetOrderStatistic";
export const GET_RECEIVED_ORDERS =
  "/DropShipping/GetDropShippingReceivedOrders";
export const GET_PAST_ORDERS = "/DropShipping/GetDropShippingPastOrders";
export const IS_ON_SPOT_ENABLE = "/DropShipping/IsOnSpotEnable";
export const UPDATE_ORDER_STATUS = "/DropShipping/UpdateOrderStatusAsync";
export const CANCELLATION_REASONS = "/DropShipping/CancellationReasons";
export const CANCEL_ORDER = "/DropShipping/CancelOrder";
export const Delivered_ORDER_QUANTITY_DETAIL =
  "/DropShipping/DeliverdOrderQuantityDetail";
export const PARTIAL_DELIVERY_REASONS = "/DropShipping/PartialDeliveryReasons";
export const ON_SPOT_RETURN_ITEM = "/DropShipping/OnSpotReturnItem";

// Seller Contract
export const TERMS_CONDITIONS = "/SellerContract/GetContract";
export const STATES = "/SellerContract/States";
export const CITIES = "/SellerContract/Cities";
export const GET_PDF_TERMS = "/SellerContract/GetPdfTerms";

// DEDs
export const DED_NEW_ORDERS = "/PickAndDrop/NewOrdersV2";
export const DED_PICKUP_ORDERS = "/PickAndDrop/ReadyToPickupOrders";
export const DED_PICKED_UP_SKUs = "/PickAndDrop/PickedUpSkus";
export const DED_RETURN_ORDERS = "/PickAndDropReturn/ReturnOrders";
export const DED_RETURN_SKUs = "/PickAndDropReturn/ReturnSkus";
export const DED_RETURN_RECEIPT = "/PickAndDropReturn/ReturnReceipt";
export const DED_PAST_ORDERS = "/PickAndDrop/PastOrders";
export const DED_PICKUP_SKUs = "/PickAndDrop/PickupSkuDetails";
export const DED_PICKUP_RECEIPT = "/PickAndDrop/PickupReceipt";

// Logistics
export const PICKUP_SELLER = "/pickup/seller";
export const PICKUP_ETA = "/pickup/eta";
export const PICKUP_REVIEW = "/pickup/review";
export const RETURN_STOP_REVIEW = "/return-stop/review";

// Growth
export const SELLER_PERFORMANCE_BADGES = "/seller-performance-badges";
export const SELLER_PERFORMANCE_BADGES_HISTORY =
  "/seller-performance-badges/history";

// Dashboard
export const GET_STATISTIC = "/Dashboard/GetStatistic";
export const GET_SELLER_RATING = "seller-rating";

// Return Modes
export const GET_RETURN_MODES = "/Categories/ReturnModeLocation";
export const ADD_RETURN_MODE_LOCATION = "/Categories/AddReturnModeLocation";

// Delivery City, Area and Time
export const GET_DELIVERY_CITIES = "/SellerLeadTimeH3/FavouriteCities";
export const SELLER_DELIVERY_CITIES = "/SellerLeadTimeH3/SellerLeadTimesH3";
export const UPDATE_SELLER_DELIVERY_CITIES = "/SellerLeadTimeH3/SellerLeadTime";

// Application Version
export const GET_APPLICATION_VERSION = "/Common/GetApplicationVersion";

// Payments
export const GET_SELLER_PAYMENT = "/SellerPayment/SellerPaymentDueDetails";
export const SELLER_PAYMENTS = "/SellerPayment/SellerPayments";
export const MANAGE_PENDING_PAYMENTS = "/Orders/ManagePendingPayments";
export const MANAGE_COMPLETED_PAYMENTS = "/Orders/ManageCompletedPayments";
export const CREATE_SELLER_PAYMENT = "/SellerPayment/CreateSellerPayment";

// Seller Invoices
export const GET_SELLER_INVOICES = "/SellerInvoices/GetSellerInvoicesAsync";
export const GET_INVOICE_DETAILS = "/SellerInvoices/GetInvoiceDetailsAsync";

// Area Lead Time
export const GET_AREA_LEAD_TIMES = "/AreaLeadTimeH3/Hexagons";
export const ADD_SELLER_LEAD_TIMES_AREA =
  "/SellerLeadTimeH3/AddSellerLeadTimesArea";
export const TOGGLE_HEXAGONS = "/AreaLeadTimeH3/ToggleAllHexagons";
