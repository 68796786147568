import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import NotFoundMsg from "../../../../../../../components/NotFoundMsg";
import Loading from "../../../../../../../components/Loading";
import Pagination from "../../../../../../../components/Pagination";
import { DEDNewOrders } from "../../../../../../../APIs/order";
import {
  getStartEndUTC,
  convertDatePick,
} from "../../../../../../../Utilities/index";
import OrderCard from "../../components/OrderCard";
import styles from "./NewOrders.module.scss";

const NewOrders = ({ date }) => {
  const [newOrders, setNewOrders] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const fetchNewOrders = () => {
    const { fromDateTime, toDateTime } = getStartEndUTC(date);
    const query = {
      startDate: fromDateTime,
      endDate: toDateTime,
      pageNumber: page + 1,
      pageSize,
    };

    setLoading(true);
    setNewOrders({});

    DEDNewOrders(query)
      .then((res) => {
        if (res?.status === 200) {
          const newOrders = res?.data?.data;

          setNewOrders({ ...newOrders });
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setPageSize(newRowsPerPage);
    setPage(0);
  };

  useEffect(() => {
    fetchNewOrders();
  }, [date, page, pageSize]);

  return (
    <>
      {!loading && newOrders?.orderItems?.length === 0 && <NotFoundMsg />}
      {loading && <Loading />}
      {newOrders?.orderItems?.length > 0 && (
        <div className={styles.NewOrdersContainer}>
          {newOrders?.pickupDate && (
            <Typography variant="h4">
              {convertDatePick(newOrders.pickupDate, "Delivery Due")}
            </Typography>
          )}
          <div className={styles.NewOrders}>
            {newOrders.orderItems.map((order) => (
              <OrderCard key={order.productId} details={order} />
            ))}
          </div>
          <Pagination
            label="Orders per page"
            page={page}
            pageSize={pageSize}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            count={newOrders?.length}
          />
        </div>
      )}
    </>
  );
};

export default NewOrders;
