import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../components/Modal";
import styles from "./Modal.module.scss";

const MarkAsModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle = "",
  message,
  loading,
  updateOrderStatus,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => console.log("Modal closed")}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div className={styles.MarkAsModalContainer}>
          <p className={styles.MarkAsAskingMsg}>{message}</p>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.MarkAsActionBtnContainer}>
          <Button
            disableElevation
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={onClose}
          >
            No
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={updateOrderStatus}
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default MarkAsModal;
