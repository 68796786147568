import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography, IconButton, Tabs, Tab } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ReceivedOrders from "./ReceivedOrders";
import PastOrders from "./PastOrders";
import styles from "./Customer.module.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.TabPanelContainer}>{children}</div>
      )}
    </div>
  );
};

const DeliverToCustomer = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [date, setDate] = useState(new Date());
  const history = useHistory();

  const backToOrders = () => history.push("/orders");
  const handleTabs = (_e, tabIndex) => setTabIndex(tabIndex);
  const handleDate = (date) => setDate(date);

  return (
    <div className={styles.CustomerDeliverWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToOrders}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          DELIVER TO CUSTOMER
        </Typography>
        <div className={styles.DateContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              data-delivery-date-picker
              margin="normal"
              id="delivery-date-picker-dialog"
              label="Expiry Date"
              format="MM/dd/yyyy"
              value={date}
              onChange={handleDate}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <>
        <Tabs
          value={tabIndex}
          onChange={handleTabs}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Received Orders" />
          <Tab label="Past Orders" />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <ReceivedOrders date={moment(date).format("YYYY-MM-DD")} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <PastOrders date={moment(date).format("YYYY-MM-DD")} />
        </TabPanel>
      </>
    </div>
  );
};

export default DeliverToCustomer;
