import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loader from "../../components/Loader";
import OTPInput from "../../components/OTPInput";
import CountdownTimer from "../../components/CountdownTimer";
import FlagsSelectField from "../../components/FlagsSelectField";
import OTPMessage from "../../components/core/OTPMessage";
import { setUserDetails } from "../../features/userDetails/userDetailsSlice";
import {
  userByMobile,
  generateOTP,
  accountLogin,
  verifyOTP,
} from "../../APIs/auth";
import { setLocalStorage } from "../../Utilities";
import { OTPLength } from "../../constants/enum";
import styles from "./Login.module.scss";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOTPLoading, setOTPLoading] = useState(false);
  const [withPassword, setWithPassword] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleOTP = (otp) => setOTP(otp);
  const handlePassword = (e) => setPassword(e.target.value);
  const askingPassword = () => setWithPassword((withPassword) => !withPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const checkUserExist = () => {
    const query = `${encodeURIComponent("+")}${phone}`;

    setLoading(true);
    userByMobile(query)
      .then((res) => {
        if (res.status === 200) {
          const { isVerify } = res.data;

          if (isVerify) {
            setIsUserVerified(isVerify);
            handleOTPGeneration();
          } else if (!isVerify) {
            history.push("/awaiting-approval");
          }

          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleOTPGeneration = () => {
    const payload = {
      userName: `+${phone}`,
      isTwilio: false,
    };

    setOTPLoading(true);
    generateOTP(payload)
      .then((res) => {
        if (res && res.status === 200) {
          setOTPLoading(false);

          if (timerFinished) {
            setTimerFinished(false);
          }
        }
      })
      .catch((_err) => setOTPLoading(false));
  };

  const isTimerFinished = (status) => setTimerFinished(status);

  const loginWithOTP = () => {
    const payload = {
      userName: `+${phone}`,
      otp: OTP,
    };

    setOTPLoading(true);
    verifyOTP(payload)
      .then((res) => {
        if (res?.status === 200) {
          handlLoginResponse(res);
        }
      })
      .catch((err) => {
        const response = err.response;

        if (response?.status === 400) {
          setOTPLoading(false);
        }
      });
  };

  const loginWithPassword = () => {
    const payload = {
      userName: `+${phone}`,
      isTwilio: false,
      password,
    };

    setLoading(true);
    if (password.length) {
      accountLogin(payload)
        .then((res) => {
          if (res?.status === 200) {
            handlLoginResponse(res);
          }
        })
        .catch((_err) => setLoading(false));
    }
  };

  const handleForgotPassword = () => {
    const payload = {
      userName: `+${phone}`,
      isTwilio: false,
    };

    setOTPLoading(true);
    generateOTP(payload)
      .then((res) => {
        if (res && res.status === 200) {
          setOTPLoading(false);
          history.push("/forgot-password", { phone });
        }
      })
      .catch((_err) => setOTPLoading(false));
  };

  const handlLoginResponse = (res) => {
    const { isBlacklisted, id, name, token } = res.data ?? {};

    if (isBlacklisted) {
      history.push("/account-suspension", { isBlacklisted });
    } else {
      const userDetails = { id, name };

      dispatch(setUserDetails(userDetails));
      setLoading(false);
      setOTPLoading(false);
      setLocalStorage("auth-token", token);
      setLocalStorage("user-details", userDetails);
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (OTP.length === 6) {
      loginWithOTP();
    }
  }, [OTP]);

  return (
    <div className={styles.LoginWrapper}>
      <Loader loading={isOTPLoading} />
      <IconButton color="primary" onClick={() => history.push("/")}>
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <div className={styles.LoginContainer}>
        {!isUserVerified && (
          <>
            <Typography color="primary" variant="h2" className={styles.Heading}>
              Mobile Number
            </Typography>
            <FlagsSelectField phone={phone} setPhone={setPhone} />
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                onClick={checkUserExist}
                className={styles.LoginNxtBtn}
              >
                Next
              </Button>
            </div>
          </>
        )}
        {isUserVerified && (
          <>
            <Typography variant="h2" color="primary" className={styles.Heading}>
              Enter OTP
            </Typography>
            <div>
              <OTPInput
                autoFocus
                isNumberInput
                length={OTPLength}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(otp) => handleOTP(otp)}
              />
              {!isOTPLoading && (
                <div className={styles.OTPTimerContainer}>
                  <CountdownTimer isTimerFinished={isTimerFinished} />
                  <OTPMessage
                    handleOTPGeneration={handleOTPGeneration}
                    timerFinished={timerFinished}
                  />
                </div>
              )}
              <Typography
                color="primary"
                variant="h5"
                className={styles.AskingPassword}
                onClick={askingPassword}
              >
                Login with password?
              </Typography>
            </div>
            {withPassword && (
              <>
                <TextField
                  className={styles.Password}
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  label="Password"
                  variant="outlined"
                  onChange={handlePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className={styles.ForgotPasswordContainer}>
                  <Button
                    disabled={isOTPLoading}
                    color="primary"
                    onClick={handleForgotPassword}
                    className={styles.button}
                  >
                    Forgot Password?
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    disabled={!(password.length > 0) || loading}
                    color="primary"
                    variant="contained"
                    onClick={loginWithPassword}
                    className={styles.LoginNxtBtn}
                  >
                    Login
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
