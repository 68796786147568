import BestSKU from "../../../assets/images/bestSku.png";
import styles from "./LiveSKUs.module.scss";

const LiveSKUs = (props) => {
  const { SKUDetails } = props;

  return (
    <div className={styles.LiveSKUsWrapper}>
      <div className={styles.CountDetailContainer}>
        <img src={BestSKU} alt="sku-icon" className={styles.SKUIcon} />
        <p style={{ margin: 0 }}>
          <strong className={styles.LiveSKUs}>
            {SKUDetails?.liveSkus ?? 0}
          </strong>
          <strong className={styles.TotalSKUs}>
            /{SKUDetails?.totalSKU ?? 0}
          </strong>
        </p>
      </div>
      <p className={styles.Label}>
        of your SKUs are <strong>live</strong>
      </p>
    </div>
  );
};

export default LiveSKUs;
