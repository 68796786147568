import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import NotFoundMsg from "../../../../../../../components/NotFoundMsg";
import Loading from "../../../../../../../components/Loading";
import Pagination from "../../../../../../../components/Pagination";
import { DEDPickupOrders } from "../../../../../../../APIs/order";
import { pickupSeller } from "../../../../../../../APIs/logistics";
import { getStartEndUTC } from "../../../../../../../Utilities/index";
import ShipmentCard from "../../components/ShipmentCard";
import styles from "./PickupOrders.module.scss";

const PickupOrders = ({ date }) => {
  const [pickupOrders, setPickupOrders] = useState([]);
  const [pickupStatus, setPickupStatus] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchPickupOrders = (previousDate = undefined) => {
    const { fromDateTime, toDateTime } = getStartEndUTC(previousDate ?? date);
    const query = {
      startDate: fromDateTime,
      endDate: toDateTime,
      pageNumber: page + 1,
      pageSize,
    };

    setLoading(true);
    setPickupOrders([]);

    DEDPickupOrders(query)
      .then((res) => {
        if (res?.status === 200) {
          const pickupOrders = res?.data?.data;
          setPickupOrders(pickupOrders);
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  };

  const fetchPickupSeller = () => {
    pickupSeller().then((res) => {
      if (res?.status === 200) {
        const pickupStatus = res?.data?.data;
        setPickupStatus({ ...pickupStatus });
      }
    });
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setPageSize(newRowsPerPage);
    setPage(0);
  };

  useEffect(() => {
    fetchPickupOrders();
  }, [date, page, pageSize]);

  useEffect(() => {
    fetchPickupSeller();
  }, [date]);

  return (
    <>
      {!loading && pickupOrders?.length === 0 && <NotFoundMsg />}
      {loading && <Loading />}
      {pickupOrders?.length > 0 && (
        <div className={styles.PickupOrdersContainer}>
          <Typography variant="h4">{pickupOrders[0].pickupDue}</Typography>
          <div className={styles.PickupOrders}>
            {pickupOrders?.map((order) => (
              <ShipmentCard
                key={order.pickupId}
                date={date}
                shipmentDetails={order}
                pickupStatus={pickupStatus}
              />
            ))}
          </div>
          <Pagination
            label="Orders per page"
            page={page}
            pageSize={pageSize}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            count={pickupOrders?.length}
          />
        </div>
      )}
    </>
  );
};

export default PickupOrders;
