import { useHistory } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DeliveryPlaces from "../../assets/images/deliveryPlace.png";
import TimesClock from "../../assets/images/timesClock.png";
import styles from "./Areas&Times.module.scss";

const DeliveryAreasAndTimes = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  const DeliveryContainer = ({
    headerTxt,
    imgAltTxt,
    imgSrc,
    redirectHandler,
  }) => (
    <div className={styles.Delivery} onClick={redirectHandler}>
      <img alt={imgAltTxt} src={imgSrc} className={styles.Img} />
      <p className={styles.LabelHeader}>{headerTxt}</p>
      <div className={styles.LabelIcon}>
        <ArrowForwardIosIcon fontSize="large" />
      </div>
    </div>
  );

  return (
    <div className={styles.DeliveryAreaAndTimeWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/dashboard")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          DELIVERY AREAS & TIMES
        </Typography>
      </div>
      <div className={styles.Section}>
        <div className={styles.DeliveryContainer}>
          <DeliveryContainer
            imgAltTxt="delivery-place-icon"
            headerTxt="View or edit cities and areas where you deliver"
            imgSrc={DeliveryPlaces}
            redirectHandler={() => goTo("/delivery/delivery-cities")}
          />
          <DeliveryContainer
            imgAltTxt="time-clock-icon"
            headerTxt="  View delivery times for each city and area"
            imgSrc={TimesClock}
            redirectHandler={() => goTo("/delivery/delivery-times")}
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveryAreasAndTimes;
