import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  fetchAssignedBadge,
  fetchBadgeSpecificColors,
} from "../../PerformanceBadges/constants";
import styles from "./PerformanceBadge.module.scss";

const PerformanceBadge = ({ badgeDetails }) => {
  const history = useHistory();
  const performanceBadge = badgeDetails?.data?.performance_badges ?? [];
  let badgeTheme = undefined;

  if (performanceBadge?.length) {
    badgeTheme = fetchBadgeSpecificColors(performanceBadge[0]["badge_id"]);
  }

  const goTo = (path) => history.push(path, badgeDetails);

  const Text = ({ text, classes = "" }) => (
    <p style={{ color: badgeTheme?.color }} className={classes}>
      {text}
    </p>
  );

  return (
    <>
      {performanceBadge.length > 0 && (
        <div
          className={styles.BadgeContainer}
          style={{ backgroundColor: badgeTheme?.backgroundColor }}
        >
          {performanceBadge?.length > 0 && (
            <img
              alt="badge"
              className={styles.BadgeIcon}
              src={fetchAssignedBadge(performanceBadge[0]["badge_id"])}
            />
          )}
          <div className={styles.Body}>
            <div className={styles.Section}>
              <Text text="CURRENT PERFORMANCE" />
              {performanceBadge?.length > 0 && (
                <Text
                  classes={styles.BadgeTitle}
                  text={`${performanceBadge[0]["badge_name"]} Badge`}
                />
              )}
              <Text text="Badge, performance aur deegar maloomat k liye yahan click krain." />
            </div>
            <IconButton
              style={{ color: badgeTheme?.color }}
              className={styles.ArrowForwardIcon}
              onClick={() => goTo("/seller-performance-badges")}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceBadge;
