import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  Snackbar,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MuiAlert from "@material-ui/lab/Alert";
import ListingApprovalModal from "../Modal/ListingApprovalModal";
// import ResetInventoryModal from "./../Modal/ResetInventoryModal";
// import AutoApprovalModal from "../Modal/AutoApprovalModal";
import {
  isValidDecimal,
  getPrecision,
  validateNumericWithOnePrecision,
} from "../../../Utilities";
import { sellerChangeRequest } from "../../../APIs/listing";
import DastgyrLogo from "../../../assets/images/dastgyr-logo.png";
import { pricingTypes, initialVariablePricings } from "./constants";
import styles from "./ManageListing.module.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditListing = () => {
  const [editProductLoading, setEditProductLoading] = useState(false);
  const [SKUPrice, setSKUPrice] = useState(0);
  const [expiryDate, setExpiryDate] = useState(null);
  const [minOrderQty, setMinOrderQty] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mrp, setMRP] = useState("");
  const [isSnackbarOpen, setSnackbarVisibility] = useState(false);
  const [selectedPricingType, setSelectedPricingType] = useState(1);
  const [listedQty, setListedQty] = useState(1);
  const [variablePricings, setVariablePricings] = useState([]);
  const [moqError, setMoqError] = useState("");
  const [moq, setMOQ] = useState(0);

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const SKUListingDetails = useRef(null);
  const mlv = useRef(profileDetails?.minimumListingValue ?? 1500);
  const history = useHistory();
  const { state } = useLocation();

  const goTo = () => history.push("/listing");

  useEffect(() => {
    setMRP(state?.details?.mrp);
    setExpiryDate(new Date(state?.details?.expiryDate));
    setListedQty(state?.details?.quantity);

    if (state?.details?.isVariblePricing) {
      setSelectedPricingType(2);
    } else {
      setSelectedPricingType(1);
      setSKUPrice(state?.details?.price);
      setMinOrderQty(state?.details?.minimumQuantity);
      setVariablePricings([...initialVariablePricings]);
    }

    if (state?.details?.skuVariablePricings?.length > 0) {
      const variablePricings = state?.details?.skuVariablePricings;
      const modifiedVariablePricings = variablePricings.map((pricing, idx) => {
        return {
          price: pricing.price,
          qty: pricing.minOrderQty,
          isInvalidPrice: false,
          isInvalidQty: false,
          isDisabled: variablePricings.length - 1 !== idx ? true : false,
        };
      });

      setVariablePricings([...modifiedVariablePricings]);
    }
  }, [state]);

  const editListing = (isAutoUpdateInventory, RetryRequest = false) => {
    const payload = {
      id: 0,
      skuId: state?.details?.skuId,
      quantity: Number(listedQty),
      retryRequest: RetryRequest,
      autoApproved: false,
      retryRequest: true,
      autoUpdateInventory: isAutoUpdateInventory,
      sendLowInventoryNotification: false,
      variantId: state?.details?.variantId,
      ...(expiryDate && {
        expiryDate: expiryDate.toISOString(),
      }),
      ...(mrp && { mrp: Number(mrp) }),
    };

    if (selectedPricingType === 1) {
      payload["moq"] = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));
      payload["price"] = Number(SKUPrice ?? 0);
      payload["minimumQuantity"] = Number(minOrderQty);
      payload["isVariablePricing"] = false;
    }

    if (selectedPricingType === 2) {
      payload["isVariablePricing"] = true;
      payload["skuVariablePricings"] = variablePricings.map(
        ({ price, qty }) => {
          return {
            price: Number(price),
            minOrderQty: Number(qty),
          };
        }
      );
      payload["price"] = Number(variablePricings[0]["price"]);
      payload["minimumQuantity"] = Number(variablePricings[0]["qty"]);
      payload["moq"] = Math.ceil(
        Number(mlv.current) / Number(variablePricings[0]["price"])
      );
    }

    setEditProductLoading(true);
    sellerChangeRequest(payload)
      .then((res) => {
        if (res.status === 200) {
          SKUListingDetails.current = res.data.data ?? {};
          setEditProductLoading(false);
          setIsModalOpen(true);
        }
      })
      .catch((err) => setEditProductLoading(false));
  };

  const handleApprovalSubmit = () => {
    setIsModalOpen(false);
    history.push("/listing");
  };

  const incrementQty = () => {
    if (state?.details?.baseUomId === 1) {
      setListedQty((qty) => Number(qty) + 1);
    } else {
      setListedQty((qty) => Number(Number(qty) + 1).toFixed(1));
    }
  };

  const decrementQty = () => {
    if (listedQty > 0) {
      if (state?.details?.baseUomId === 1) {
        setListedQty((qty) => qty - 1);
      } else {
        setListedQty((qty) => Number(qty - 1).toFixed(1));
      }
    }
  };

  const handleListedQty = (value) => {
    if (state?.details?.baseUomId === 1) {
      const intValue = value.replace(/[^0-9]/g, "");

      if (intValue) {
        setListedQty(intValue);
      } else {
        setListedQty(0);
      }
    } else {
      if (!value || validateNumericWithOnePrecision(value)) {
        if (value) {
          setListedQty(value);
        } else {
          setListedQty(0);
        }
      }
    }
  };

  const calculateMoq = () => {
    if (SKUPrice) {
      const moq = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));

      setMOQ(moq);
      if (Number(listedQty) >= moq) {
        setMinOrderQty(moq);
        setMoqError("");
      } else {
        setMinOrderQty(1);
        setMoqError(
          "Minimum order quantity should be lower than the listed quantity. Please update the listed quantity."
        );
      }
    }
  };

  const calculateMoqOnMInimumQuantity = () => {
    if (SKUPrice) {
      const moq = Math.ceil(Number(mlv.current) / Number(SKUPrice ?? 0));
      const errMsg =
        "Minimum order quantity should be lower than the listed quantity. Please update the listed quantity.";

      setMOQ(moq);
      if (Number(SKUPrice ?? 0) * Number(minOrderQty) < Number(mlv.current)) {
        setMoqError(errMsg);
      } else {
        setMoqError("");
      }
    }
  };

  const handlePricingTypes = (e) => {
    const type = Number(e.target.value);
    setSelectedPricingType(type);
  };

  const isValidPricings = () => {
    const isAllSlabFilled = variablePricings.every(
      ({ price, qty }) => price && qty
    );

    return !isAllSlabFilled;
  };

  const addVariablePricing = () => {
    const allAvailableSlabFilled = variablePricings.every(
      ({ price, qty }) => price && qty
    );

    if (allAvailableSlabFilled) {
      const duplicatePricings = [...variablePricings];
      const variablePrice = {
        price: "",
        qty: "",
        isInvalidPrice: false,
        isInvalidQty: false,
      };

      duplicatePricings.push(variablePrice);
      setVariablePricings([...duplicatePricings]);
    } else {
      setSnackbarVisibility(true);
    }
  };

  const deleteVariablePricing = (idx) => {
    const duplicatePricings = [...variablePricings];

    if (idx > 0) {
      duplicatePricings[idx - 1] = {
        ...duplicatePricings[idx - 1],
        isDisabled: false,
      };
    }

    if (idx === 1) {
      duplicatePricings[idx] = {
        price: "",
        qty: "",
        isInvalidPrice: false,
        isInvalidQty: false,
        isDisabled: false,
      };
    } else {
      duplicatePricings.splice(idx, 1);
    }

    setVariablePricings([...duplicatePricings]);
  };

  const handleSKUPrice = (e, idx) => {
    const price = e.target.value;
    const precision = profileDetails?.city?.currencyPrecision;
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      price,
    };

    if (idx === 0) {
      if (isValidDecimal(price, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    } else {
      duplicatePricings[idx - 1] = {
        ...duplicatePricings[idx - 1],
        isDisabled: price ? true : false,
      };

      if (isValidDecimal(price, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    }
  };

  const handleSKUQty = (e, idx) => {
    const qty = e.target.value;
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      qty,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const verifySKUQty = (e, idx) => {
    const currentQty = Number(e.target.value);
    const duplicatePricings = [...variablePricings];

    if (idx > 0) {
      const isPreviousPriceExist = duplicatePricings[idx - 1];
      const previousQty = isPreviousPriceExist["qty"];

      if (previousQty && Number(currentQty) <= Number(previousQty)) {
        duplicatePricings[idx] = {
          ...duplicatePricings[idx],
          qty: Number(previousQty) + 1,
          isInvalidQty: true,
        };
      }

      setVariablePricings([...duplicatePricings]);
    }
  };

  const verifySKUPrice = (e, idx) => {
    const currentPrice = Number(e.target.value);
    const duplicatePricings = [...variablePricings];
    const precision = profileDetails?.city?.currencyPrecision;
    const moq = currentPrice
      ? Math.ceil(Number(mlv.current) / Number(currentPrice))
      : "";

    if (idx === 0) {
      duplicatePricings[idx] = {
        ...duplicatePricings[idx],
        price: currentPrice,
        qty: moq,
      };

      if (isValidDecimal(currentPrice, precision)) {
        setVariablePricings([...duplicatePricings]);
      }
    } else {
      const isPreviousPriceExist = duplicatePricings[idx - 1];

      if (isPreviousPriceExist) {
        const previousPrice = isPreviousPriceExist["price"];
        const previousQty = isPreviousPriceExist["qty"];

        if (previousPrice && Number(currentPrice) >= Number(previousPrice)) {
          duplicatePricings[idx] = {
            ...duplicatePricings[idx],
            qty: "",
            price: currentPrice,
            isInvalidPrice: true,
          };
        } else {
          duplicatePricings[idx] = {
            ...duplicatePricings[idx],
            qty:
              currentPrice && previousQty >= moq
                ? Number(previousQty) + 1
                : moq,
            price: currentPrice,
            isInvalidPrice: false,
          };
        }

        if (isValidDecimal(currentPrice, precision)) {
          setVariablePricings([...duplicatePricings]);
        }
      }
    }
  };

  const resetSKUPrice = (_e, idx) => {
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      isInvalidPrice: false,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const resetSKUPQty = (_e, idx) => {
    const duplicatePricings = [...variablePricings];

    duplicatePricings[idx] = {
      ...duplicatePricings[idx],
      isInvalidQty: false,
    };

    setVariablePricings([...duplicatePricings]);
  };

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    setSnackbarVisibility(false);
  };

  useEffect(() => {
    calculateMoq();
  }, [listedQty]);

  return (
    <div className={styles.ManageListingWrapper}>
      <div className={styles.Header}>
        <IconButton className={styles.BackBtn} color="primary" onClick={goTo}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          EDIT LISTING
        </Typography>
      </div>
      <div className={styles.MainSection}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            backgroundColor: "#fff",
            padding: "1rem",
            alignItems: "center",
            borderRadius: "16px",
          }}
        >
          <div className={styles.ProductImgContainer}>
            <img
              className={styles.ProductImg}
              alt="SKU Item"
              src={
                state?.details?.image && JSON.parse(state?.details?.image)
                  ? JSON.parse(state?.details?.image)
                  : DastgyrLogo
              }
            />
          </div>
          <div className={styles.ProductDetails}>
            <p
              style={{
                fontWeight: 500,
                margin: "0px 0px 5px 0px",
                fontSize: "24px",
              }}
            >
              {state?.details?.name ?? "--"}
            </p>
            <p style={{ margin: 0, color: "#1176ae" }}>
              Available Qty:{" "}
              {new Intl.NumberFormat("en-US").format(
                state?.details?.remainingInventory ?? 0
              )}
            </p>
            <p style={{ margin: 0, color: "#1176ae" }}>
              Listed Qty:{" "}
              {new Intl.NumberFormat("en-US").format(
                state?.details?.quantity ?? 0
              )}
            </p>
          </div>
        </div>
        <div className={styles.PriceContainer}>
          <div className={styles.QtyManageContainer}>
            <IconButton
              className={styles.DecrementBtn}
              color="primary"
              onClick={decrementQty}
            >
              <RemoveIcon />
            </IconButton>
            <TextField
              required={false}
              className={styles.QtyTextField}
              data-sku-qty-field
              type="number"
              label="Listing Quantity"
              name="listedQty"
              value={listedQty}
              variant="outlined"
              inputProps={{ maxLength: 5 }}
              onChange={(e) => handleListedQty(e.target.value)}
            />
            <IconButton
              className={styles.IncrementBtn}
              color="primary"
              onClick={incrementQty}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.OptionalFieldsContainer}>
          <div className={styles.DateContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="expiry-date-picker-dialog"
                label="Expiry Date"
                format="MM/dd/yyyy"
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
              />
              <TextField
                value={mrp}
                label={`Market Retail Price (${
                  profileDetails?.currency ?? ""
                })`}
                placeholder={getPrecision(
                  profileDetails?.city?.currencyPrecision
                )}
                variant="outlined"
                onChange={(e) => {
                  const price = e.target.value;
                  const precision = profileDetails?.city?.currencyPrecision;

                  if (isValidDecimal(price, precision)) {
                    setMRP(price);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className={styles.PricingContainer}>
          <Typography variant="h4">Select Pricing</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedPricingType}
              onChange={handlePricingTypes}
              className={styles.PricingTypeContainer}
            >
              {pricingTypes?.map((type) => (
                <FormControlLabel
                  key={type.id}
                  value={type.id}
                  label={type.name}
                  control={<Radio color="primary" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {selectedPricingType === 1 && (
            <div className={styles.FixedPriceContainer}>
              <TextField
                value={SKUPrice}
                label={`Unit Price (${profileDetails?.currency ?? ""})`}
                placeholder={getPrecision(
                  profileDetails?.city?.currencyPrecision
                )}
                variant="outlined"
                required={true}
                onChange={(e) => {
                  const price = e.target.value;
                  const precision = profileDetails?.city?.currencyPrecision;

                  if (isValidDecimal(price, precision)) {
                    setSKUPrice(price);
                  }
                }}
                onBlur={calculateMoq}
              />
              <div>
                <TextField
                  value={minOrderQty}
                  label="Minimum Order Quantity"
                  style={{ width: "100%" }}
                  variant="outlined"
                  onChange={(e) => {
                    const minimumQty = e.target.value;
                    if (Number(minimumQty) <= Number(listedQty)) {
                      setMinOrderQty(minimumQty);
                    } else {
                      setSnackbarVisibility(true);
                    }
                  }}
                  onBlur={calculateMoqOnMInimumQuantity}
                />
                <p className={styles.MOQErrorMsg}>{moqError}</p>
              </div>
            </div>
          )}
          {selectedPricingType === 2 && (
            <>
              {variablePricings.map((pricing, idx) => {
                return (
                  <>
                    <div key={idx} className={styles.VariablePricingContainer}>
                      <div>
                        <TextField
                          value={pricing.price}
                          label={`SKU Price (${
                            profileDetails?.currency ?? ""
                          })`}
                          placeholder={getPrecision(
                            profileDetails?.city?.currencyPrecision
                          )}
                          variant="outlined"
                          required={true}
                          onChange={(e) => handleSKUPrice(e, idx)}
                          onBlur={(e) => verifySKUPrice(e, idx)}
                          onFocus={(e) => resetSKUPrice(e, idx)}
                          style={{ width: "100%" }}
                          disabled={pricing.isDisabled}
                        />
                        {pricing.isInvalidPrice && (
                          <p className={styles.MOQErrorMsg}>
                            Price cannot be greater than previous slab.
                          </p>
                        )}
                      </div>
                      <div>
                        <TextField
                          value={pricing.qty}
                          label="Minimum Order Quantity"
                          variant="outlined"
                          onChange={(e) => handleSKUQty(e, idx)}
                          onBlur={(e) => verifySKUQty(e, idx)}
                          onFocus={(e) => resetSKUPQty(e, idx)}
                          style={{ width: "100%" }}
                          disabled={
                            pricing.isDisabled ||
                            pricing.isInvalidPrice ||
                            !pricing.price
                          }
                        />
                        {pricing.isInvalidQty && (
                          <p className={styles.MOQErrorMsg}>
                            Quantity should be greater than previous slab.
                          </p>
                        )}
                      </div>
                      {variablePricings.length - 1 === idx && (
                        <div className={styles.RemovePricingButton}>
                          <IconButton
                            color="primary"
                            onClick={() => deleteVariablePricing(idx)}
                          >
                            <RemoveCircleOutlineIcon fontSize="large" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    {idx === 0 &&
                      pricing.qty &&
                      Number(pricing.qty) > Number(listedQty) && (
                        <p className={styles.MOQErrorMsg}>
                          Minimum order quantity should be lower than the listed
                          quantity. Please update the listing quantity.
                        </p>
                      )}
                  </>
                );
              })}
              <div style={{ marginTop: "12px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addVariablePricing}
                  disabled={
                    Number(
                      variablePricings?.length > 0 && variablePricings[0]["qty"]
                    ) > Number(listedQty)
                  }
                  className={styles.ManageListingBtn}
                >
                  + Add option
                </Button>
              </div>
            </>
          )}
        </div>
        {selectedPricingType === 1 && (
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={"info"}>
              Minimum order quantity should be less than Listing quantity
            </Alert>
          </Snackbar>
        )}
        {selectedPricingType === 2 && (
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={"info"}>
              Please filling the last slab first.
            </Alert>
          </Snackbar>
        )}
      </div>
      <div className={styles.SubmitBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => editListing(false)}
          disabled={
            editProductLoading ||
            (selectedPricingType === 1
              ? !moqError && state?.details?.baseUomId === 1
                ? !SKUPrice
                : !(SKUPrice && /^\d+(\.\d{1})$/.test(listedQty))
              : Number(
                  variablePricings?.length > 0 && variablePricings[0]["qty"]
                ) > Number(listedQty) || isValidPricings())
          }
          className={styles.ManageListingBtn}
        >
          Edit Listing
        </Button>
      </div>
      <ListingApprovalModal
        isOpen={isModalOpen}
        modalTitle=""
        modalSize="sm"
        SKUListingDetails={SKUListingDetails.current}
        handleApprovalSubmit={handleApprovalSubmit}
        approvalType={"editListing"}
      />
      {/* <ResetInventoryModal
        isOpen={isResetInventoryModalOpen}
        modalTitle="Mark Inventory Unavailable"
        modalSize="sm"
        onClose={closeResetInventoryModal}
        loading={resetInventoryLoading}
        resetInventory={handleResetInventory}
      /> */}
      {/* <AutoApprovalModal
        qty={listedQty}
        modalSize="sm"
        SKUPrice={SKUPrice}
        modalTitle="Listing Approval"
        closeModal={closeListingApprovalModal}
        isOpen={isListingApprovalModalOpen}
        SKUListingDetails={SKUListingDetails.current}
        manageListing={(isRetryRequest) => editListing(true, isRetryRequest)}
        approvalType={"editListing"}
      /> */}
    </div>
  );
};

export default EditListing;
