import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import styles from "./Home.module.scss";
import { getLocalStorage } from "../../Utilities";

const Home = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  useEffect(() => {
    const token = getLocalStorage("auth-token");

    if (token) {
      history.push("/dashboard");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.HomePageWrapper}>
      <div>
        <div className={styles.GreetingContainer}>Welcome To Seller Portal</div>
        <div className={styles.LoginMsg}>
          Log in with your account to continue
        </div>
        <div className={styles.AuthBtnContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => goTo("/login")}
          >
            Log in
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => goTo("/signup")}
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
