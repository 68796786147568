import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PaymentIcon from "@material-ui/icons/Payment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { sellerModes } from "../../../../constants/enum";
import { Profile, SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

function Sidebar(props) {
  const { open, variant, onClose, className, ...rest } = props;
  const { modes } = useSelector((state) => state.fulfillmentModes);
  const classes = useStyles();

  const routesLinks = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Listing",
      href: "/listing",
      icon: <ListAltIcon />,
    },
    {
      title: "Orders",
      href: "/orders",
      icon: <AssignmentIcon />,
    },
    {
      title: "Manage Payments",
      href: "/payments",
      icon: <PaymentIcon />,
    },
    {
      title: "Seller Invoices",
      href: "/seller-invoices",
      icon: <ReceiptIcon />,
    },
  ];

  if (
    modes?.length > 0 &&
    modes[0]["fulfillmentModeId"] === sellerModes["DS"]
  ) {
    routesLinks.push({
      title: "Areas & Times",
      href: "/delivery/areas-times",
      icon: <AccessTimeIcon />,
    });

    routesLinks.push({
      title: "Returns",
      href: "/returns/i-will-deliver",
      icon: <AirportShuttleIcon />,
    });
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      open={open}
      onClose={onClose}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={[...routesLinks]} />
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
