import { useState, useEffect } from "react";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { getPastOrders } from "../../../../../../APIs/order";
import DeliveryOrderCard from "../../DeliveryOrderCard";
import styles from "./PastOrders.module.scss";
import { getStartEndUTC } from "../../../../../../Utilities/index";

const selectedStatus = {
  background: "#102947",
};

const ordersStatus = {
  4: "Delivered",
  6: "Cancelled",
};

const PastOrders = (props) => {
  const [currentType, setCurrentType] = useState(4);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { date } = props;

  const handleCurrentType = (type) => setCurrentType(type);

  const fetchPastOrders = (status) => {
    const utcDate = getStartEndUTC(date);
    const query = {
      status: status,
      startDate: utcDate.fromDateTime,
      endDate: utcDate.toDateTime,
    };

    return getPastOrders(query).then((res) => {
      if (res.status === 200) return res?.data?.items ?? [];
    });
  };
  // Fetch Delivered Orders
  const fetchDeliveredOrders = () => {
    return fetchPastOrders(4);
  };

  // Fetch Cancelled Orders
  const fetchCancelledOrders = () => {
    return fetchPastOrders(6);
  };

  const fetchOrders = () => {
    setLoading(true);

    setDeliveredOrders([]);
    setCancelledOrders([]);

    Promise.all([fetchDeliveredOrders(), fetchCancelledOrders()])
      .then(([deliveredOrders, cancelledOrders]) => {
        setDeliveredOrders(deliveredOrders);
        setCancelledOrders(cancelledOrders);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, [date]);

  const NotFoundMsg = () => (
    <div className={styles.NotFoundMsg}>
      <Typography color="primary" variant="h5">
        Nothing to show
      </Typography>
    </div>
  );

  const Loading = () => (
    <div className={styles.LoadingContainer}>
      <CircularProgress color="primary" />
    </div>
  );

  const PastOrderTypes = ({ orders }) => (
    <>
      {orders.length > 0 && (
        <p className={styles.DeliveredOn}>{orders[0].deliveryDue}</p>
      )}
      <div className={styles.PastOrders}>
        {orders?.map((order) => (
          <DeliveryOrderCard key={order.orderId} order={order} />
        ))}
      </div>
    </>
  );

  return (
    <div className={styles.PastOrdersContainer}>
      <div className={styles.ActionBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(4)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[4] &&
              selectedStatus),
          }}
          className={styles.Delivered}
        >
          Delivered
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(6)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[6] &&
              selectedStatus),
          }}
          className={styles.Cancelled}
        >
          Cancelled
        </Button>
      </div>

      {ordersStatus[currentType] === ordersStatus[4] && (
        <>
          {!loading && deliveredOrders?.length === 0 && <NotFoundMsg />}
          {loading && <Loading />}
          <PastOrderTypes orders={deliveredOrders} />
        </>
      )}

      {ordersStatus[currentType] === ordersStatus[6] && (
        <>
          {!loading && cancelledOrders?.length === 0 && <NotFoundMsg />}
          {loading && <Loading />}
          <PastOrderTypes orders={cancelledOrders} />
        </>
      )}
    </div>
  );
};

export default PastOrders;
