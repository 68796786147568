import {
  GET_SINGUP_SCHEMA,
  GET_PROFILE_DETAILS,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getGlobalSignupSchema = (params = undefined) =>
  axiosInstance.get(`api${GET_SINGUP_SCHEMA}`, { params });

export const getProfileData = () =>
  axiosInstance.get(`api${GET_PROFILE_DETAILS}`);

export const updateProfile = (payload) =>
  axiosInstance.put(`api${UPDATE_PROFILE}`, payload);

export const changePassword = (payload) =>
  axiosInstance.put(`api${CHANGE_PASSWORD}`, payload);
