import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileDetails: null,
};

export const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
    removeProfileDetails: (state) => {
      state.profileDetails = null;
    },
  },
});

export const { setProfileDetails, removeProfileDetails } = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
