import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import { homeFilter } from "../constants";
import styles from "./Modal.module.scss";

const FilterModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  selectedFilter,
  applyFilter,
  skuDetailsLoading,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(selectedFilter);
  }, [selectedFilter]);

  const handleChange = (e) => setValue(e.target.value);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      modalSize={modalSize}
      onExited={() => {}}
      onEnter={() => {}}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div className={styles.HideSKUsModalContainer}>
          <FormControl component="fieldset">
            <RadioGroup
              name="optionFilter"
              value={value}
              onChange={handleChange}
              className={styles.HideSKUsAskingMsg}
            >
              {homeFilter.map((option) => (
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={option.name}
                  key={option.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.HideSKUsActionBtnContainer}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={skuDetailsLoading}
            onClick={() => applyFilter(value)}
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default FilterModal;
