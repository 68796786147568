import { useHistory } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./Shipment.module.scss";

const ShipmentCard = ({ date, shipmentDetails, pickupStatus }) => {
  const { pickupId, pickupCode } = shipmentDetails;
  const history = useHistory();

  const goTo = () => {
    const path = `/order/shipment/${pickupCode}/details`;
    history.push(path, { pickupId, date });
  };

  return (
    <div className={styles.ShipmentCard}>
      <div className={styles.Header}>
        <div>
          <Typography variant="h3">Shipment ID:</Typography>
          <Typography className={styles.ShipmentID} variant="h1">
            {pickupCode ?? ""}
          </Typography>
        </div>
        <IconButton aria-label="Close" onClick={goTo}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>

      {Object.keys(pickupStatus).length > 0 && pickupStatus[pickupId] && (
        <>
          {!pickupStatus[pickupId]?.is_arrived &&
            pickupStatus[pickupId]?.time_window && (
              <Typography variant="h5" className={styles.ArrvingIn}>
                Arriving in:{" "}
                <strong style={{ color: "#1176ae" }}>
                  {pickupStatus[pickupId]?.time_window ?? ""}
                </strong>
              </Typography>
            )}
          <div className={styles.StatusContainer}>
            <Typography className={styles.Status} variant="h5">
              {pickupStatus[pickupId]?.message_title ?? ""}
            </Typography>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {pickupStatus[pickupId]?.message_body ?? ""}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default ShipmentCard;
