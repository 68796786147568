import { useState, useEffect } from "react";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import DeliveryOrderCard from "../../DeliveryOrderCard";
import MarkAsModal from "../../../Modal/MarkAsConfirmationModal";
import OrderCancellationModal from "../../../Modal/OrderCancellationModal";
import DeliveredOrderModal from "../../../Modal/DeliveredOrderModal";
import {
  getReceivedOrders,
  isOnSpotEnable,
  updateOrderStatus,
  cancelOrder,
} from "../../../../../../APIs/order";
import { getStartEndUTC } from "../../../../../../Utilities/index";
import styles from "./ReceivedOrders.module.scss";

const selectedStatus = {
  background: "#102947",
};

const ordersStatus = {
  2: "ReadyToShip",
  3: "Shipped",
  4: "Delivered",
};

const ReceivedOrders = (props) => {
  const [currentType, setCurrentType] = useState(2);
  const [readyToShippedOrders, setReadyToShippedOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [isMarkAsModalOpen, setMarkAsModalOpen] = useState(false);
  const [isCancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [isDeliveredModalOpen, setDeliveredModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderStatusLoading, setOrderStatusLoading] = useState(false);
  const [orderCancelLoading, setOrderCancelLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { date } = props;

  const handleCurrentType = (type) => setCurrentType(type);

  // Fetch Order API Call
  const fetchOrdersAPICall = (status) => {
    const utcDate = getStartEndUTC(date);
    const query = {
      status: status,
      startDate: utcDate.fromDateTime,
      endDate: utcDate.toDateTime,
    };

    return getReceivedOrders(query).then((res) => {
      if (res.status === 200) return res?.data?.items ?? [];
    });
  };

  // Fetch Ready To Shipped Orders
  const fetchReadyToShippedOrders = () => fetchOrdersAPICall(2);

  // Fetch Shipped Orders
  const fetchShippedOrders = () => fetchOrdersAPICall(3);

  // Fetch Delivered Orders
  const fetchDeliveredOrders = () => fetchOrdersAPICall(4);

  const fetchOrders = () => {
    setLoading(true);

    setReadyToShippedOrders([]);
    setShippedOrders([]);
    setDeliveredOrders([]);

    Promise.all([
      fetchReadyToShippedOrders(),
      fetchShippedOrders(),
      fetchDeliveredOrders(),
    ])
      .then(([readyToShippedOrders, shippedOrders, deliveredOrders]) => {
        setReadyToShippedOrders(readyToShippedOrders);
        setShippedOrders(shippedOrders);
        setDeliveredOrders(deliveredOrders);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const orderMarkAs = (status, orderId, type) => {
    isOnSpotEnable().then((res) => {
      if (res?.status === 200) {
        const isEnabled = res?.data;

        setSelectedOrder({ status, orderId });

        if (isEnabled && type === "delivered") {
          setDeliveredModalOpen(true);
        } else {
          setMarkAsModalOpen(true);
        }
      }
    });
  };

  const modifiedOrderStatus = () => {
    setOrderStatusLoading(true);

    updateOrderStatus(selectedOrder)
      .then((res) => {
        if (res?.status === 200) {
          setOrderStatusLoading(false);
          setDeliveredModalOpen(false);
          closeMarkAsModal();
          fetchOrders();
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        closeMarkAsModal();
        closeDeliveredModal();
        setOrderStatusLoading(false);
      });
  };

  const closeMarkAsModal = () => {
    setMarkAsModalOpen(false);
    setSelectedOrder(null);
  };

  const openCancellationModal = (orderId, type) => {
    setSelectedOrder({ orderId, type });
    setCancellationModalOpen(true);
  };

  const closeCancellationModal = () => {
    setSelectedOrder(null);
    setCancellationModalOpen(false);
  };

  const cancelSelectedOrder = (reasonID) => {
    const query = {
      orderId: selectedOrder?.orderId,
      reasonId: reasonID,
    };

    setOrderCancelLoading(true);
    cancelOrder(query)
      .then((res) => {
        if (res?.status === 200) {
          setOrderCancelLoading(false);
          closeCancellationModal();
          fetchOrders();
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        closeCancellationModal();
        setOrderCancelLoading(false);
      });
  };

  const closeDeliveredModal = (result) => {
    setDeliveredModalOpen(false);

    if (result === "success") {
      fetchOrders();
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [date]);

  const NotFoundMsg = () => (
    <div className={styles.NotFoundMsg}>
      <Typography color="primary" variant="h5">
        Nothing to show
      </Typography>
    </div>
  );

  const Loading = () => (
    <div className={styles.LoadingContainer}>
      <CircularProgress color="primary" />
    </div>
  );

  const ReceivedOrderTypes = ({
    orders,
    orderMarkAs,
    openCancellationModal,
  }) => (
    <>
      {orders.length > 0 && (
        <p className={styles.DeliveredOn}>{orders[0].deliveryDue}</p>
      )}
      <div className={styles.ReceivedOrders}>
        {orders?.map((order) => (
          <DeliveryOrderCard
            key={order.orderId}
            order={order}
            orderMarkAs={orderMarkAs}
            openCancellationModal={openCancellationModal}
          />
        ))}
      </div>
    </>
  );

  return (
    <div className={styles.ReceivedOrdersContainer}>
      <div className={styles.ActionBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(2)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[2] &&
              selectedStatus),
          }}
          className={styles.ReadyToShip}
        >
          Ready to Ship
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(3)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[3] &&
              selectedStatus),
          }}
          className={styles.Shipped}
        >
          Shipped
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(4)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[4] &&
              selectedStatus),
          }}
          className={styles.Delivered}
        >
          Delivered
        </Button>
      </div>

      {ordersStatus[currentType] === ordersStatus[2] && (
        <>
          {!loading && readyToShippedOrders?.length === 0 && <NotFoundMsg />}
          {loading && <Loading />}
          <ReceivedOrderTypes
            orders={readyToShippedOrders}
            orderMarkAs={orderMarkAs}
            openCancellationModal={openCancellationModal}
          />
        </>
      )}

      {ordersStatus[currentType] === ordersStatus[3] && (
        <>
          {!loading && shippedOrders?.length === 0 && <NotFoundMsg />}
          {loading && <Loading />}
          <ReceivedOrderTypes
            orderMarkAs={orderMarkAs}
            orders={shippedOrders}
            openCancellationModal={openCancellationModal}
          />
        </>
      )}

      {ordersStatus[currentType] === ordersStatus[4] && (
        <>
          {!loading && deliveredOrders?.length === 0 && <NotFoundMsg />}
          {loading && <Loading />}
          <ReceivedOrderTypes orders={deliveredOrders} />
        </>
      )}

      <MarkAsModal
        modalSize="sm"
        message={
          ordersStatus[selectedOrder?.status] === "Shipped"
            ? "Mark Order As Shipped?"
            : ordersStatus[selectedOrder?.status] === "Delivered"
            ? "Mark Order As Delivered?"
            : ""
        }
        loading={orderStatusLoading}
        onClose={closeMarkAsModal}
        isOpen={isMarkAsModalOpen}
        updateOrderStatus={modifiedOrderStatus}
      />

      <OrderCancellationModal
        modalSize="sm"
        message={
          selectedOrder?.type === "cancel"
            ? "Reason For Cancellation?"
            : "Report Order"
        }
        type={selectedOrder?.type}
        loading={orderCancelLoading}
        onClose={closeCancellationModal}
        isOpen={isCancellationModalOpen}
        cancelOrder={cancelSelectedOrder}
      />

      <DeliveredOrderModal
        modalSize="sm"
        isOpen={isDeliveredModalOpen}
        onClose={closeDeliveredModal}
        orderId={selectedOrder?.orderId}
        updateOrderStatus={modifiedOrderStatus}
        deliveredOrderLoading={orderStatusLoading}
      />
    </div>
  );
};

export default ReceivedOrders;
