import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import fulfillmentModes from "./features/fulfillmentModes/modesSlice";
import userDetails from "./features/userDetails/userDetailsSlice";
import snackbarDetails from "./features/snackbar/snackbarSlice";
import profileDetails from "./features/profileDetails/profileDetailsSlice";
import sellerLocationStatus from "./features/sellerLocationStatus/locationSlice";

const secretKey = process.env.REACT_APP_SECRET_KEY;
const encryptor = encryptTransform({ secretKey });

const reducers = combineReducers({
  fulfillmentModes,
  userDetails,
  snackbarDetails,
  profileDetails,
  sellerLocationStatus,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["userDetails", "profileDetails", "fulfillmentModes"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
