// import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function FlagsSelectField({ phone, setPhone }) {
  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <div style={{ margin: "1rem" }}>
      <PhoneInput
        country={"pk"}
        value={phone}
        countryCodeEditable={false}
        onChange={handlePhoneChange}
        inputStyle={{
          padding: "0.5rem",
          fontSize: "1.2rem",
          borderRadius: "0.5rem",
          borderColor: "#ddd",
          marginLeft: "2.1rem",
        }}
        dropdownStyle={{ backgroundColor: "#fff", color: "#333" }}
      />
    </div>
  );
}

export default FlagsSelectField;
