import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import NewOrders from "./NewOrders";
import PickupOrders from "./PickupOrders";
import ReturnOrders from "./ReturnOrders";
import styles from "./ReceivedOrders.module.scss";

const selectedStatus = {
  background: "#102947",
};

const orderStatus = [
  {
    type: 1,
    label: "New Orders",
  },
  {
    type: 2,
    label: "Ready for Pickup",
  },
  {
    type: 3,
    label: "Returns",
  },
];

const ReceivedOrders = ({ date }) => {
  const [currentType, setCurrentType] = useState(1);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setCurrentType(state?.currentType);
    }
  }, [state]);

  return (
    <div className={styles.ReceivedOrdersContainer}>
      <div className={styles.ActionBtnContainer}>
        {orderStatus.map(({ type, label }) => (
          <Button
            key={type}
            color="primary"
            variant="contained"
            onClick={() => setCurrentType(type)}
            style={{
              ...(currentType === type && selectedStatus),
            }}
            className={styles.ChipButton}
          >
            {label}
          </Button>
        ))}
      </div>

      {currentType === orderStatus[0]["type"] && <NewOrders date={date} />}
      {currentType === orderStatus[1]["type"] && <PickupOrders date={date} />}
      {currentType === orderStatus[2]["type"] && <ReturnOrders date={date} />}
    </div>
  );
};

export default ReceivedOrders;
