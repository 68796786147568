import { useState, useEffect } from "react";
import NotFoundMsg from "../../../../../../../components/NotFoundMsg";
import Loading from "../../../../../../../components/Loading";
import Pagination from "../../../../../../../components/Pagination";
import { DEDReturnOrders } from "../../../../../../../APIs/order";
import { getStartEndUTC } from "../../../../../../../Utilities/index";
import ShipmentCard from "./components/ShipmentCard";
import styles from "./ReturnOrders.module.scss";

const ReturnOrders = ({ date }) => {
  const [returnOrders, setReturnOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const fetchReturnOrders = () => {
    const { fromDateTime, toDateTime } = getStartEndUTC(date);
    const query = {
      startDate: fromDateTime,
      endDate: toDateTime,
      pageNumber: page + 1,
      pageSize,
    };

    setLoading(true);
    setReturnOrders([]);

    DEDReturnOrders(query)
      .then((res) => {
        if (res?.status === 200) {
          const returnOrders = res?.data?.data;

          setReturnOrders(returnOrders);
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setPageSize(newRowsPerPage);
    setPage(0);
  };

  useEffect(() => {
    fetchReturnOrders();
  }, [date, page, pageSize]);

  return (
    <>
      {!loading && returnOrders?.length === 0 && <NotFoundMsg />}
      {loading && <Loading />}
      {returnOrders?.length > 0 && (
        <div className={styles.ReturnOrdersContainer}>
          <div className={styles.ReturnOrders}>
            {returnOrders?.map((order) => (
              <ShipmentCard
                key={order.returnId}
                date={date}
                shipmentDetails={order}
              />
            ))}
          </div>
          <Pagination
            label="Orders per page"
            page={page}
            pageSize={pageSize}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            count={returnOrders?.length}
          />
        </div>
      )}
    </>
  );
};

export default ReturnOrders;
