import clsx from "clsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Sidebar } from "./layouts/Main/components";
import Errors from "./components/Errors";
import { useDispatch } from "react-redux";
import { removeSnackbarDetails } from "./features/snackbar/snackbarSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    backgroundColor: "#f4f5f7",
    minHeight: "calc(100vh - 56px)",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 54,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
}));

const LayoutWithDrawer = ({ children, onClose, isSidebarOpen }) => {
  const {
    details: { open, message, severity, hideDuration },
  } = useSelector((state) => state.snackbarDetails);
  const { userDetails } = useSelector((state) => state.userDetails);

  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const shouldOpenSidebar = isDesktop ? true : isSidebarOpen;

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    dispatch(
      removeSnackbarDetails({
        open: false,
        message: "",
        hideDuration: 0,
        severity: "",
      })
    );
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop && userDetails,
      })}
    >
      {userDetails && (
        <Sidebar
          onClose={onClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? "persistent" : "temporary"}
        />
      )}
      {children}
      <Errors
        open={open}
        message={message}
        close={handleClose}
        hideDuration={hideDuration}
        severity={severity}
      />
    </div>
  );
};

export default LayoutWithDrawer;
