import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpIcon from "@material-ui/icons/Help";
import CurrentPerformance from "./components/CurrentPerformance";
import PastRewards from "./components/PastRewards/PastRewards";
import PerformanceInfo from "./Modals/PerformanceInfo";
import { selectedStatus, rewards } from "./constants";
import styles from "./PerformanceBadges.module.scss";

const PerformanceBadges = () => {
  const [currentType, setCurrentType] = useState(1);
  const [isPerformanceModalOpen, setPerformanceModalOpen] = useState(false);

  const history = useHistory();
  const { state } = useLocation();

  const goTo = (path) => history.push(path);

  const openPerformanceModal = () => setPerformanceModalOpen(true);
  const closePerformanceModal = () => setPerformanceModalOpen(false);

  return (
    <div className={styles.PerformanceBadgesWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          className={styles.BackBtn}
          onClick={() => goTo("/dashboard")}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          REWARDS
        </Typography>
        <div className={styles.DateContainer}>
          <IconButton color="primary" onClick={openPerformanceModal}>
            <HelpIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
      <div className={styles.ActionBtnContainer}>
        {rewards.map(({ type, label }) => (
          <Button
            key={type}
            color="primary"
            variant="contained"
            onClick={() => setCurrentType(type)}
            style={{
              ...(currentType === type && selectedStatus),
            }}
            className={styles.ChipButton}
          >
            {label}
          </Button>
        ))}
      </div>
      <div className={styles.MainSection}>
        {currentType === 1 && (
          <CurrentPerformance
            performanceBadges={state?.data?.performance_badges}
          />
        )}
        {currentType === 2 && <PastRewards />}
      </div>
      <PerformanceInfo
        isOpen={isPerformanceModalOpen}
        modalSize="sm"
        modalTitle=""
        onClose={closePerformanceModal}
        performanceDetails={state?.data?.details ?? {}}
      />
    </div>
  );
};

export default PerformanceBadges;
