import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Checkbox,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { getLocalStorage } from "../../Utilities/index";
import { getReturnModes, addReturnModeLocation } from "../../APIs/return-modes";
import styles from "./Returns.module.scss";

const Returns = () => {
  const [modes, setModes] = useState([]);
  const [latLong, setLatLong] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const history = useHistory();

  const handleReturnModes = (idx) => {
    const duplicateModes = [...modes];

    duplicateModes[idx] = {
      ...duplicateModes[idx],
      isSelected: !duplicateModes[idx]["isSelected"],
    };

    setModes([...duplicateModes]);
  };

  const backToDashboard = () => history.push("/dashboard");

  const updateReturnModes = () => {
    const { id } = userDetails;
    const payload = {
      latLong,
      returnModes: modes,
      sellerId: id,
      address:
        "43/1-B, Block-6 Block 6 PECHS, Karachi, Karachi City, Sindh, Pakistan",
    };

    setSubmitLoading(true);

    addReturnModeLocation(payload)
      .then((res) => {
        if (res?.status === 200) {
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    const userDetails = getLocalStorage("user-details");
    setLoading(true);

    getReturnModes()
      .then((res) => {
        if (res?.status === 200) {
          setModes(res?.data?.returnModes ?? []);
          setLatLong(res?.data?.latLong);
          setLoading(false);

          if (userDetails) {
            setUserDetails(userDetails);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const NotFoundMsg = () => (
    <div className={styles.NotFoundMsg}>
      <Typography color="primary" variant="h5">
        No modes available
      </Typography>
    </div>
  );

  const Loading = () => (
    <div className={styles.LoadingContainer}>
      <CircularProgress color="primary" />
    </div>
  );

  return (
    <div className={styles.ReturnsModeWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToDashboard}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          Returns
        </Typography>
      </div>
      <div className={styles.Section}>
        <Typography variant="h3">Select Returns Mode</Typography>
        {!loading && modes?.length === 0 && <NotFoundMsg />}
        {loading && <Loading />}
        {modes?.length > 0 && (
          <>
            <div className={styles.ModesContainer}>
              {modes?.map((mode, idx) => {
                return (
                  <div className={styles.Mode} key={mode.id}>
                    <Checkbox
                      color="secondary"
                      checked={mode.isSelected}
                      className={styles.StyleCheckbox}
                      onChange={() => handleReturnModes(idx)}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                    />
                    <div>
                      <p className={styles.LabelHeader}>{mode?.name ?? ""}</p>
                      <p className={styles.LabelText}>
                        {mode?.description ?? ""}{" "}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ textAlign: "right", marginTop: "15px" }}>
              <Button
                color="primary"
                variant="contained"
                disabled={submitLoading}
                style={{ textTransform: "capitalize" }}
                onClick={updateReturnModes}
              >
                Update Modes
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Returns;
