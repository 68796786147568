export const sellerTypes = [
  {
    value: "1",
    label: "Individual Seller",
  },
  {
    value: "2",
    label: "Registered Business",
  },
];

export const sellerModes = {
  DEDs: 4,
  DS: 3,
};

export const OTPLength = 6;
