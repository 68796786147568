import { useSelector } from "react-redux";
import { formatDateTime } from "../../../Utilities/index";
import styles from "./Payments.module.scss";

const Payments = (props) => {
  const { sellerPayment } = props;
  const { profileDetails } = useSelector((state) => state.profileDetails);

  return (
    <div className={styles.PaymentsWrapper}>
      <p className={styles.PaymentDue}>Payments Due:</p>
      <div className={styles.AmountContainer}>
        <p className={styles.CurrencyLabel}>
          {profileDetails?.currency ?? ""}{" "}
          <span className={styles.Amount}>
            {new Intl.NumberFormat("en-US").format(
              sellerPayment?.amountDue ?? 0
            )}
          </span>
        </p>
      </div>
      <p className={styles.DateLabel}>
        Due Date:
        <span className={styles.DueDate}>
          {sellerPayment?.dueDate
            ? formatDateTime(sellerPayment?.dueDate)
            : "--"}
        </span>
      </p>
    </div>
  );
};

export default Payments;
