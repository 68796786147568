import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EventIcon from "@material-ui/icons/Event";
import { Typography, IconButton } from "@material-ui/core";
import NotFoundMsg from "../../components/NotFoundMsg";
import Loading from "../../components/Loading";
import Invoices from "./ViewInvoices";
import { getSellerInvoices } from "../../APIs/seller-invoices";
import styles from "./SellerInvoices.module.scss";

const SellerInvoices = () => {
  const [dateRange, setDateRange] = useState({});
  const [rangePickerVisibility, setRangePickerVisibility] = useState(false);
  const history = useHistory();

  const handleRangePicker = () => {
    setRangePickerVisibility(!rangePickerVisibility);
  };

  const backToOrders = () => history.push("/payments");

  const sellerParams = {
    startDate: moment(dateRange.startDate).format("YYYY/MM/DD"),
    endDate: moment(dateRange.endDate).format("YYYY/MM/DD"),
  };

  const fetchInvoices = ({ queryKey }) => {
    const [_, sellerParams] = queryKey;

    return getSellerInvoices(sellerParams);
  };

  const { data: invoices, isLoading } = useQuery({
    queryKey: ["invoices", sellerParams],
    queryFn: fetchInvoices,
  });

  return (
    <div className={styles.ViewInvoicesWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToOrders}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          SELLER INVOICES
        </Typography>
        <div className={styles.DateContainer}>
          <IconButton onClick={handleRangePicker}>
            <EventIcon />
          </IconButton>
          <div className={rangePickerVisibility ? styles.overlay : ""}>
            <div className={styles.DateRangePicker}>
              <DateRangePicker
                open={rangePickerVisibility}
                toggle={handleRangePicker}
                onChange={(range) => {
                  setDateRange(range);
                  setRangePickerVisibility(!rangePickerVisibility);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {!isLoading && invoices?.data?.data?.length === 0 && <NotFoundMsg />}
      {isLoading && <Loading />}
      <div className={styles.InvoiceList}>
        {invoices?.data?.data.map((invoice, idx) => (
          <Invoices key={idx} invoice={invoice} />
        ))}
      </div>
    </div>
  );
};

export default SellerInvoices;
