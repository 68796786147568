import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import DeleteSKUsModal from "../../Modal/DeleteSKUModal";
import NewListing from "./NewListing";
import PendingListingSKUs from "./PendingListingSKUs";
import {
  getSKURequest,
  getSKUChangeRequest,
  deleteSKU,
} from "../../../../APIs/listing";
import {
  DELETE_SKU_REQUEST,
  DELETE_SKU_CHANGE_REQUEST,
} from "../../../../Utilities/endpoints";
import styles from "./PendingListing.module.scss";

const disabledBtnStyle = {
  backgroundColor: "darkgray",
};

const PendingListings = ({ isRender }) => {
  const [currentType, setCurrentType] = useState("newListing");
  const [SKURequests, setSKURequests] = useState([]);
  const [changeSKURequests, setChangeSKURequests] = useState([]);
  const [productSKULoading, setProductSKULoading] = useState(false);
  const [isDeleteSKUModalOpen, setDeleteSKUModalOpen] = useState(false);
  const [deleteSKULoading, setDeleteSKULoading] = useState(false);
  const [selectedSKUs, setSelectedSKUs] = useState(false);
  const [newListingPage, setNewListingPage] = useState(0);
  const [newListingPerPage, setNewListingPerPage] = useState(10);
  const [editListingPage, setEditListingPage] = useState(0);
  const [editListingPerPage, setEditListingPerPage] = useState(10);

  const fetchSKURequest = () => {
    const query = {
      sortKey: "Name",
      pageNumber: newListingPage + 1,
      pageSize: newListingPerPage,
    };

    return getSKURequest(query).then((res) => {
      if (res.status === 200) {
        const SKUs =
          res.data?.sku.map((SKU) => ({ ...SKU, isSelected: false })) ?? [];

        return SKUs;
      }
    });
  };

  const fetchSKUChangeRequest = () => {
    const query = {
      sortKey: "Name",
      pageNumber: editListingPage + 1,
      pageSize: editListingPerPage,
    };

    return getSKUChangeRequest(query).then((res) => {
      if (res.status === 200) {
        const SKUs =
          res.data?.sku.map((SKU) => ({ ...SKU, isSelected: false })) ?? [];

        return SKUs;
      }
    });
  };

  const fetchSKUs = () => {
    setProductSKULoading(true);

    setSKURequests([]);
    setChangeSKURequests([]);

    Promise.all([fetchSKURequest(), fetchSKUChangeRequest()])
      .then(([SKURequest, SKUChangeRequest]) => {
        setSKURequests(SKURequest);
        setChangeSKURequests(SKUChangeRequest);
        setProductSKULoading(false);
      })
      .catch((_err) => setProductSKULoading(false));
  };

  const selectedLists = (idx) => {
    if (currentType === "newListing") {
      const SKUs = [...SKURequests];

      SKUs[idx] = {
        ...SKUs[idx],
        isSelected: !SKUs[idx]["isSelected"],
      };

      setSKURequests([...SKUs]);
    } else if (currentType === "editListing") {
      const SKUs = [...changeSKURequests];

      SKUs[idx] = {
        ...SKUs[idx],
        isSelected: !SKUs[idx]["isSelected"],
      };

      setChangeSKURequests([...SKUs]);
    }
  };

  const deleteSingleSKU = (idx) => {
    if (currentType === "newListing") {
      const SKUs = [...SKURequests];

      SKUs[idx] = {
        ...SKUs[idx],
        isSelected: !SKUs[idx]["isSelected"],
      };

      setSKURequests([...SKUs]);
    } else if (currentType === "editListing") {
      const SKUs = [...changeSKURequests];

      SKUs[idx] = {
        ...SKUs[idx],
        isSelected: !SKUs[idx]["isSelected"],
      };

      setChangeSKURequests([...SKUs]);
    }

    setDeleteSKUModalOpen(true);
  };

  const deleteMultipleSKUs = (e) => {
    const isSelected = e.target.checked;

    setSelectedSKUs(isSelected);

    if (currentType === "newListing") {
      const SKUs = SKURequests.map((SKU) => ({
        ...SKU,
        isSelected,
      }));

      setSKURequests([...SKUs]);
    } else if (currentType === "editListing") {
      const SKUs = changeSKURequests.map((SKU) => ({
        ...SKU,
        isSelected,
      }));

      setChangeSKURequests([...SKUs]);
    }
  };

  const closeDeleteSKUModal = () => {
    if (currentType === "newListing") {
      const SKUs = SKURequests.map((SKU) => ({
        ...SKU,
        isSelected: false,
      }));

      setSKURequests([...SKUs]);
    } else if (currentType === "editListing") {
      const SKUs = changeSKURequests.map((SKU) => ({
        ...SKU,
        isSelected: false,
      }));

      setChangeSKURequests([...SKUs]);
    }

    setSelectedSKUs(false);
    setDeleteSKUModalOpen(false);
  };

  const openDeleteSKUModal = () => setDeleteSKUModalOpen(true);

  const manageSKUsDeletion = () => {
    let URL = "";
    const payload = {
      skuId:
        currentType === "newListing"
          ? SKURequests.filter(({ isSelected }) => isSelected).map(
              ({ skuId }) => skuId
            )
          : changeSKURequests
              .filter(({ isSelected }) => isSelected)
              .map(({ skuId }) => skuId),
    };

    if (currentType === "newListing") {
      URL = `api${DELETE_SKU_REQUEST}`;
    } else if (currentType === "editListing") {
      URL = `api${DELETE_SKU_CHANGE_REQUEST}`;
    }

    setDeleteSKULoading(true);
    deleteSKU(URL, payload)
      .then((res) => {
        const { statusCode } = res.data ?? {};

        if (statusCode === 200) {
          fetchSKUs();

          setSelectedSKUs(false);
          setDeleteSKUModalOpen(false);
          setDeleteSKULoading(false);
        }
      })
      .catch((_err) => setDeleteSKULoading(true));
  };

  const handleCurrentType = (type) => {
    setCurrentType(type);
    setSelectedSKUs(false);
  };

  const handleNextPage = (type) => {
    if (type === "newListing") {
      setNewListingPage((prevPage) => prevPage + 1);
    } else if (type === "editListing") {
      setEditListingPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = (type) => {
    if (type === "newListing") {
      setNewListingPage((prevPage) => Math.max(prevPage - 1, 0));
    } else if (type === "editListing") {
      setEditListingPage((prevPage) => Math.max(prevPage - 1, 0));
    }
  };

  const handleRowsPerPageChange = (event, type) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    if (type === "newListing") {
      setNewListingPerPage(newRowsPerPage);
      setNewListingPage(0);
    } else if (type === "editListing") {
      setEditListingPerPage(newRowsPerPage);
      setEditListingPage(0);
    }
  };

  useEffect(() => {
    fetchSKUs();
  }, [
    newListingPage,
    editListingPage,
    newListingPerPage,
    editListingPerPage,
    isRender,
  ]);

  return (
    <div className={styles.PendingListingsContainer}>
      <div className={styles.PendingBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleCurrentType("newListing");
          }}
          style={{
            ...(currentType !== "newListing" && disabledBtnStyle),
          }}
          className={styles.NewListingBtn}
        >
          New Listing
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setCurrentType("editListing");
            handleCurrentType("editListing");
          }}
          style={{
            ...(currentType === "newListing" && disabledBtnStyle),
          }}
          className={styles.EditListingBtn}
        >
          Edit Listing
        </Button>
      </div>
      {currentType === "newListing" && (
        <NewListing
          SKURequests={SKURequests}
          selectedSKUs={selectedSKUs}
          loading={productSKULoading}
          deleteMultipleSKUs={deleteMultipleSKUs}
          deleteSingleSKU={deleteSingleSKU}
          selectedLists={selectedLists}
          openDeleteSKUModal={openDeleteSKUModal}
          displayListingPage={newListingPage}
          displayListingPerPage={newListingPerPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {currentType === "editListing" && (
        <PendingListingSKUs
          changeSKURequests={changeSKURequests}
          selectedSKUs={selectedSKUs}
          loading={productSKULoading}
          deleteMultipleSKUs={deleteMultipleSKUs}
          deleteSingleSKU={deleteSingleSKU}
          selectedLists={selectedLists}
          openDeleteSKUModal={openDeleteSKUModal}
          displayListingPage={editListingPage}
          displayListingPerPage={editListingPerPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      <DeleteSKUsModal
        modalSize="sm"
        modalTitle="Delete SKU(s)"
        deleteSKUs={manageSKUsDeletion}
        loading={deleteSKULoading}
        onClose={closeDeleteSKUModal}
        isOpen={isDeleteSKUModalOpen}
      />
    </div>
  );
};

export default PendingListings;
