import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import style from "../Profile.module.scss";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";


const AboutBusiness = (props) => {
  const { company, setCompany, errors } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleChange = (type, value) => {
    setCompany((previousCompany) => {
      return {
        ...previousCompany,
        [type]: value,
      };
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    // if (!file) {
    //   alert("Please select at file.");
    //   return;
    // }

    const fileNameParts = file.name.split(".");
    const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (!["jpg", "jpeg", "png"].includes(extension)) {
      alert("Please select only image files.");
      return;
    }

    // Read the selected file as a binary string
    // const reader = new FileReader();
    // reader.onload = () => {
    //   handleChange(event.target.name, reader.result);
    //   setFileName((previousFile) => {
    //     return {
    //       ...previousFile,
    //       [event.target.name]: file.name,
    //     };
    //   });
    // };
    // reader.onerror = (error) => {
    //   console.error(error);
    //   alert("An error occurred while reading the selected file.");
    // };
    // reader.readAsBinaryString(file);

    handleChange(event.target.name, file);
    handleChange(event.target.name + "Name", file.name);
  };
  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>About Business</h1>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          required
          className={style.TextField}
          label="Company Name"
          name="companyName"
          value={company.companyName}
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.companyName)}
          helperText={errors && errors.companyName}
        />
        <TextField
          required
          className={style.TextField}
          label="Registration Number"
          name="regNo"
          value={company.regNo}
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.regNo)}
          helperText={errors && errors.regNo}
        />
      </form>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          required
          className={style.TextField}
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          label="Create Password"
          name="password2"
          value={company.password}
          variant="outlined"
          onChange={(e) => handleChange("password", e.target.value)}
          error={Boolean(errors && errors.password)}
          helperText={errors && errors.password}
          InputProps={{
            endAdornment:             
              <InputAdornment>
              <IconButton
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            
            </InputAdornment>
          }}
        />
        <input
          className={style.FileInput}
          accept="image/*"
          id="cert-file"
          name="certificatePic"
          type="file"
          onChange={(e) => {
            handleFileInputChange(e);
          }}
        />
        <label
          htmlFor="cert-file"
          className={`${style.UploadLabel} ${
            errors.certificatePic ? style.ErrorLabel : ""
          }`}
        >
          <Button
            className={style.UploadButton}
            variant="contained"
            color="default"
            startIcon={<CloudUploadIcon />}
            component="span"
          >
            Upload Certificate of Incorporation Picture
          </Button>
          {company.certificatePicName !== "" && (
            <p className={style.FileLabel}>{company.certificatePicName}</p>
          )}
          {company.certificatePicName === "" && errors.certificatePic && (
            <p className={style.ErrorLabel}>{errors.certificatePic}</p>
          )}
        </label>
      </form>
    </div>
  );
};

export default AboutBusiness;
