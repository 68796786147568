import { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import HideSKUsModal from "../../Modal/HideSKUModal";
import UpdateVisibilityModal from "../../Modal/UpdateVisibilityModal";
import {
  getActiveProductSKUUsers,
  updateSKUVisibility,
} from "../../../../APIs/listing";
import DisplayedListing from "./DisplayedListing";
import HiddenListing from "./HiddenListing";
import styles from "./ActiveListing.module.scss";

const disabledBtnStyle = {
  backgroundColor: "darkgray",
};

const ActiveListings = ({ isRender }) => {
  const [currentType, setCurrentType] = useState("displayed");
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleProductSKUs, setVisibleProductSKU] = useState([]);
  const [hiddenProductSKUs, setHiddenProductSKU] = useState([]);
  const [updateVisibilityResponse, setUpdateVisibilityResponse] = useState([]);
  const [productSKULoading, setProductSKULoading] = useState(false);
  const [isSKUsVisibleModalOpen, setSKUsVisibleModal] = useState(false);
  const [isUpdateVisibilityModalOpen, setUpdateVisibilityModalOpen] =
    useState(false);
  const [hideSKULoading, setHideSKULoading] = useState(false);
  const [selectedSKUs, setSelectedSKUs] = useState(false);
  const [displayListingPage, setDisplayListingPage] = useState(0);
  const [displayListingPerPage, setDisplayListingPerPage] = useState(10);
  const [hiddenListingPage, setHiddenListingPage] = useState(0);
  const [hiddenListingPerPage, setHiddenListingPerPage] = useState(10);

  const fetchVisibleSKUs = () => {
    const query = {
      isActive: true,
      isVisible: true,
      sortKey: "Name",
      pageNumber: displayListingPage + 1,
      pageSize: displayListingPerPage,
    };

    if (searchTerm.length > 0) {
      query["searchKey"] = encodeURIComponent(searchTerm);
    }

    setVisibleProductSKU([]);

    setProductSKULoading(true);
    getActiveProductSKUUsers(query)
      .then((res) => {
        if (res.status === 200) {
          const SKUs =
            res.data?.sku.map((SKU) => ({ ...SKU, isSelected: false })) ?? [];

          setVisibleProductSKU([...SKUs]);
          setProductSKULoading(false);
        }
      })
      .catch((_err) => setProductSKULoading(false));
  };

  const fetchHiddenSKUs = () => {
    const query = {
      isActive: true,
      isVisible: false,
      sortKey: "Name",
      pageNumber: hiddenListingPage + 1,
      pageSize: hiddenListingPerPage,
    };

    if (searchTerm.length > 0) {
      query["searchKey"] = encodeURIComponent(searchTerm);
    }

    setHiddenProductSKU([]);

    setProductSKULoading(true);
    getActiveProductSKUUsers(query)
      .then((res) => {
        if (res.status === 200) {
          const SKUs =
            res.data?.sku.map((SKU) => ({ ...SKU, isSelected: false })) ?? [];

          setHiddenProductSKU([...SKUs]);
          setProductSKULoading(false);
        }
      })
      .catch((err) => setProductSKULoading(false));
  };

  const selectedLists = (idx) => {
    if (currentType === "displayed") {
      const visibleSKUs = [...visibleProductSKUs];

      visibleSKUs[idx] = {
        ...visibleSKUs[idx],
        isSelected: !visibleSKUs[idx]["isSelected"],
      };

      setVisibleProductSKU([...visibleSKUs]);
    } else if (currentType === "hidden") {
      const hiddenSKUs = [...hiddenProductSKUs];

      hiddenSKUs[idx] = {
        ...hiddenSKUs[idx],
        isSelected: !hiddenSKUs[idx]["isSelected"],
      };

      setHiddenProductSKU([...hiddenSKUs]);
    }
  };

  const handleMultipleSKUVisibilities = (e) => {
    const isSelected = e.target.checked;

    setSelectedSKUs(isSelected);

    if (currentType === "displayed") {
      const visibleSKUs = visibleProductSKUs.map((SKU) => ({
        ...SKU,
        isSelected,
      }));

      setVisibleProductSKU([...visibleSKUs]);
    } else if (currentType === "hidden") {
      const hiddenSKUs = hiddenProductSKUs.map((SKU) => ({
        ...SKU,
        isSelected,
      }));

      setHiddenProductSKU([...hiddenSKUs]);
    }
  };

  const handleSKUVisibility = (idx) => {
    if (currentType === "displayed") {
      const visibleSKUs = [...visibleProductSKUs];

      visibleSKUs[idx] = {
        ...visibleSKUs[idx],
        isSelected: !visibleSKUs[idx]["isSelected"],
      };

      setVisibleProductSKU([...visibleSKUs]);
    } else if (currentType === "hidden") {
      const hiddenSKUs = [...hiddenProductSKUs];

      hiddenSKUs[idx] = {
        ...hiddenSKUs[idx],
        isSelected: !hiddenSKUs[idx]["isSelected"],
      };

      setHiddenProductSKU([...hiddenSKUs]);
    }

    openSKUsVisibilityModal();
  };

  const closeSKUsVisibilityModal = () => {
    if (currentType === "displayed") {
      const SKUs = visibleProductSKUs.map((SKU) => ({
        ...SKU,
        isSelected: false,
      }));

      setVisibleProductSKU([...SKUs]);
    } else if (currentType === "hidden") {
      const SKUs = hiddenProductSKUs.map((SKU) => ({
        ...SKU,
        isSelected: false,
      }));

      setHiddenProductSKU([...SKUs]);
    }

    setSelectedSKUs(false);
    setSKUsVisibleModal(false);
  };

  const openSKUsVisibilityModal = () => setSKUsVisibleModal(true);

  const manageSKUsVisibility = () => {
    setHideSKULoading(true);

    const payload = {
      skuId:
        currentType === "displayed"
          ? visibleProductSKUs
              .filter(({ isSelected }) => isSelected)
              .map(({ skuId }) => skuId)
          : hiddenProductSKUs
              .filter(({ isSelected }) => isSelected)
              .map(({ skuId }) => skuId),
      isVisible: currentType === "displayed" ? false : true,
    };

    updateSKUVisibility(payload)
      .then((res) => {
        if (res.status === 200) {
          const { isValid, data } = res?.data ?? {};

          if (!isValid) {
            const visibilityResponse = [...data];

            setUpdateVisibilityModalOpen(true);
            setUpdateVisibilityResponse(visibilityResponse);
          }

          if (currentType === "displayed") {
            fetchVisibleSKUs();
          } else if (currentType === "hidden") {
            fetchHiddenSKUs();
          }

          setSelectedSKUs(false);
          setSKUsVisibleModal(false);
          setHideSKULoading(false);
        }
      })
      .catch((err) => {
        setHideSKULoading(false);
      });
  };

  const handleCurrentType = (type) => {
    setCurrentType(type);
    setSelectedSKUs(false);
    setSearchTerm("");
  };

  const closeVisibilityResponseModal = () => {
    setUpdateVisibilityModalOpen(false);
  };

  const handleNextPage = (type) => {
    if (type === "displayed") {
      setDisplayListingPage((prevPage) => prevPage + 1);
    } else if (type === "hidden") {
      setHiddenListingPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = (type) => {
    if (type === "displayed") {
      setDisplayListingPage((prevPage) => Math.max(prevPage - 1, 0));
    } else if (type === "hidden") {
      setHiddenListingPage((prevPage) => Math.max(prevPage - 1, 0));
    }
  };

  const handleRowsPerPageChange = (event, type) => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    if (type === "displayed") {
      setDisplayListingPerPage(newRowsPerPage);
      setDisplayListingPage(0);
    } else if (type === "hidden") {
      setHiddenListingPerPage(newRowsPerPage);
      setHiddenListingPage(0);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      let timer;

      const debounceSearch = () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
          if (currentType === "displayed") {
            fetchVisibleSKUs();
          } else if (currentType === "hidden") {
            fetchHiddenSKUs();
          }
        }, 500);
      };

      debounceSearch();

      return () => clearTimeout(timer);
    } else {
      if (currentType === "displayed") {
        fetchVisibleSKUs();
      } else if (currentType === "hidden") {
        fetchHiddenSKUs();
      }
    }
  }, [
    searchTerm,
    currentType,
    displayListingPage,
    hiddenListingPage,
    displayListingPerPage,
    hiddenListingPerPage,
    isRender,
  ]);

  return (
    <div className={styles.ActiveListingsContainer}>
      <div className={styles.ActionBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType("displayed")}
          style={{
            ...(currentType !== "displayed" && disabledBtnStyle),
          }}
          className={styles.DisplayedButton}
        >
          Displayed
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType("hidden")}
          style={{
            ...(currentType === "displayed" && disabledBtnStyle),
          }}
          className={styles.HideButton}
        >
          Hidden
        </Button>
      </div>
      {currentType === "displayed" && (
        <DisplayedListing
          loading={productSKULoading}
          visibleProductSKUs={visibleProductSKUs}
          selectedSKUs={selectedSKUs}
          selectedLists={selectedLists}
          handleSKUVisibility={handleSKUVisibility}
          openSKUsVisibilityModal={openSKUsVisibilityModal}
          handleMultipleSKUVisibilities={handleMultipleSKUVisibilities}
          displayListingPage={displayListingPage}
          displayListingPerPage={displayListingPerPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      {currentType === "hidden" && (
        <HiddenListing
          loading={productSKULoading}
          hiddenProductSKUs={hiddenProductSKUs}
          selectedSKUs={selectedSKUs}
          selectedLists={selectedLists}
          handleSKUVisibility={handleSKUVisibility}
          openSKUsVisibilityModal={openSKUsVisibilityModal}
          handleMultipleSKUVisibilities={handleMultipleSKUVisibilities}
          displayListingPage={hiddenListingPage}
          displayListingPerPage={hiddenListingPerPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      <HideSKUsModal
        isOpen={isSKUsVisibleModalOpen}
        modalSize="sm"
        modalTitle="Manage SKU(s) Visibility"
        message={
          currentType === "displayed"
            ? "Do you want to hide SKU(s) on Dastgyr Retailer App?"
            : "Do you want to display SKU(s) on Dastgyr Retailer App?"
        }
        onClose={closeSKUsVisibilityModal}
        loading={hideSKULoading}
        manageSKUsVisibility={manageSKUsVisibility}
      />
      <UpdateVisibilityModal
        isOpen={isUpdateVisibilityModalOpen}
        modalSize="sm"
        modalTitle=""
        onClose={closeVisibilityResponseModal}
        updateVisibilityResponse={updateVisibilityResponse}
      />
    </div>
  );
};

export default ActiveListings;
