import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Typography, Divider, List, ListItem } from "@material-ui/core";
import NotFoundMsg from "../../../../components/NotFoundMsg";
import Loading from "../../../../components/Loading";
import BadgeDetails from "../../Modals/BadgeDetails";
import { sellerPerformanceBadgesHistory } from "../../../../APIs/growth";
import { fetchAssignedBadge } from "../../constants";
import { formatDateTime } from "../../../../Utilities";
import styles from "./PastRewards.module.scss";

const PastRewards = () => {
  const [performanceHistory, setPerformanceHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isBadgeDetailsModalOpen, setBadgeDetailsModalVisibility] =
    useState(false);
  const badgeDetails = useRef(null);

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const openBadgeDetailsModal = (details) => {
    badgeDetails.current = { ...details };
    setBadgeDetailsModalVisibility(true);
  };

  const closeBadgeDetailsModal = () => {
    setBadgeDetailsModalVisibility(false);
  };

  useEffect(() => {
    const fetchPerformanceHistory = () => {
      const params = { cityId: profileDetails?.city?.id };

      setLoading(true);
      sellerPerformanceBadgesHistory(params)
        .then((res) => {
          if (res.status === 200) {
            const history = res.data.data;

            setPerformanceHistory([...history]);
            setLoading(false);
          }
        })
        .catch((_err) => setLoading(false));
    };

    fetchPerformanceHistory();
  }, [profileDetails?.city?.id]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && performanceHistory?.length === 0 && <NotFoundMsg />}
      {performanceHistory?.length > 0 && (
        <div className={styles.HistoryWrapper}>
          <List>
            {performanceHistory.map((badgeDetails) => {
              const { id, badge, badgeAssignedDate, incentiveAmount } =
                badgeDetails;

              return (
                <div key={id}>
                  <ListItem>
                    <div className={styles.History}>
                      <div
                        className={styles.Date}
                        onClick={() => openBadgeDetailsModal(badgeDetails)}
                      >
                        <Typography variant="h4" color="primary">
                          {formatDateTime(badgeAssignedDate, "MMMM YYYY")}
                        </Typography>
                        <img
                          alt="badge-icon"
                          src={fetchAssignedBadge(badge.id)}
                          style={{ ...(badge?.id === 1 && { height: "60px" }) }}
                          className={styles.BadgeIcon}
                        />
                      </div>
                      <Typography variant="h4" className={styles.Amount}>
                        {profileDetails?.currency === "Rs"
                          ? `${profileDetails?.currency}.`
                          : profileDetails?.currency ?? ""}{" "}
                        {incentiveAmount}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
          <BadgeDetails
            isOpen={isBadgeDetailsModalOpen}
            modalSize="sm"
            modalTitle=""
            onClose={closeBadgeDetailsModal}
            badgeDetails={badgeDetails.current ?? {}}
          />
        </div>
      )}
    </>
  );
};

export default PastRewards;
