import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import styles from "./Modal.module.scss";

const ListingApprovalModal = ({
  isOpen,
  modalSize,
  modalTitle,
  handleApprovalSubmit,
  SKUListingDetails,
  approvalType,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => {}}
      modalSize={modalSize}
      onExited={() => {}}
      onEnter={() => {}}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div className={styles.ApprovalModalContainer}>
          {approvalType === "createListing" && (
            <p className={styles.ApprovalMsg}>
              {SKUListingDetails?.isAutoApproval
                ? "New Listing Added!"
                : "Listing has been sent to the admin for approval."}
            </p>
          )}
          {approvalType === "editListing" && (
            <p className={styles.ApprovalMsg}>
              {SKUListingDetails?.isAutoApproval
                ? "Changes Confirmed!"
                : "Listing has been sent to the admin for approval."}
            </p>
          )}
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.ApprovalActionBtnContainer}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleApprovalSubmit}
          >
            Okay
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default ListingApprovalModal;
