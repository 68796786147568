import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { formatDateTime } from "../../../../Utilities";
import { managePendingPayments } from "../../../../APIs/manage-payments";
import styles from "./PendingPayments.module.scss";

const selectedStatus = {
  background: "#102947",
};

const ordersStatus = {
  0: "All",
  4: "Dastgyr will Deliver",
  3: "I will Deliver",
};

const PendingPayments = (props) => {
  // const [currentType, setCurrentType] = useState(0);
  const [currentType, setCurrentType] = useState(3);
  const [allPendingPayments, setAllPendingPayments] = useState(null);
  const [dastgyrPendingPayments, setDastgyrPendingPayments] = useState(null);
  const [myPendingPayments, setMyPendingPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const { date } = props;

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const handleCurrentType = (type) => setCurrentType(type);

  // Fetch all pending payments
  const fetchAllPendingPayments = () => {
    const query = {
      fulfillmentModeStatus: 0,
      startDate: date.startDate,
      endDate: date.endDate,
    };

    return managePendingPayments(query).then((res) => {
      if (res.status === 200) return res?.data ?? {};
    });
  };

  // Fetch all pending payments related to Dastgyr will Deliver
  const fetchDastgyrPendingPayment = () => {
    const query = {
      fulfillmentModeStatus: 4,
      startDate: date.startDate,
      endDate: date.endDate,
    };

    return managePendingPayments(query).then((res) => {
      if (res.status === 200) return res?.data ?? {};
    });
  };

  // Fetch all pending payments related to I will Deliver
  const fetchMyPendingPayments = () => {
    const query = {
      fulfillmentModeStatus: 3,
      startDate: date.startDate,
      endDate: date.endDate,
    };

    return managePendingPayments(query).then((res) => {
      if (res.status === 200) return res?.data ?? {};
    });
  };

  const fetchOrders = () => {
    setLoading(true);

    setAllPendingPayments([]);
    setDastgyrPendingPayments([]);
    setMyPendingPayments([]);

    Promise.all([
      fetchAllPendingPayments(),
      fetchDastgyrPendingPayment(),
      fetchMyPendingPayments(),
    ])
      .then(
        ([allPendingPayments, dastgyrPendingPayments, myPendingPayments]) => {
          setAllPendingPayments(allPendingPayments);
          setDastgyrPendingPayments(dastgyrPendingPayments);
          setMyPendingPayments(myPendingPayments);
          setLoading(false);
        }
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  const NotFoundMsg = () => (
    <div className={styles.NotFoundMsg}>
      <Typography color="primary" variant="h5">
        Nothing to show
      </Typography>
    </div>
  );

  const Loading = () => (
    <div className={styles.LoadingContainer}>
      <CircularProgress color="primary" />
    </div>
  );

  const PendingPaymentsList = ({ payments }) => (
    <div>
      <div
        style={{
          background: "#fff",
          padding: "1rem 1.5rem",
          boxSizing: "border-box",
          borderRadius: "16px",
          marginBottom: "1.6rem",
          marginTop: "1rem",
        }}
        className={styles.PaymentInfo}
      >
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            I have to pay dastgyr:{" "}
            <span
              style={{
                color: "#f34964",
              }}
            >
              {profileDetails?.currency ?? ""}{" "}
            </span>
            <strong
              style={{
                color: "#f34964",
              }}
            >
              {new Intl.NumberFormat("en-US").format(
                payments?.outFlowPayment ?? 0
              )}
            </strong>
          </p>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Dastgyr has to pay me:{" "}
            <span
              style={{
                color: "#00ac99",
              }}
            >
              {profileDetails?.currency ?? ""}{" "}
            </span>
            <strong
              style={{
                color: "#00ac99",
              }}
            >
              {new Intl.NumberFormat("en-US").format(
                payments?.inFlowPayment ?? 0
              )}
            </strong>
          </p>
        </div>
      </div>
      {payments?.managePaymentItems?.length > 0 && (
        <div
          style={{
            background: "#fff",
            padding: "1rem 1.5rem",
            boxSizing: "border-box",
            borderRadius: "16px",
          }}
          className={styles.Payments}
        >
          {payments?.managePaymentItems?.map((payment, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {payment.transactionFlow ? (
                  <ArrowUpwardIcon style={{ color: "#00ac99" }} />
                ) : (
                  <ArrowDownwardIcon style={{ color: "#f34964" }} />
                )}
                <div
                  style={{
                    marginLeft: "15px",
                  }}
                >
                  <Typography variant="h4">
                    {payment?.transactionType ?? ""}
                  </Typography>
                  <p style={{ margin: 0, color: "#8d8585" }}>
                    Invoice ID: {payment?.invoiceId ?? ""}
                  </p>
                </div>
              </div>
              <div>
                <p style={{ margin: 0, textAlign: "right", color: "#00ac99" }}>
                  {profileDetails?.currency ?? ""}{" "}
                  <strong>
                    {new Intl.NumberFormat("en-US").format(
                      payment?.amount ?? 0
                    )}
                  </strong>
                </p>
                <p style={{ margin: 0, color: "#8d8585" }}>
                  Payment Date: {formatDateTime(payment?.transactionDate)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    fetchOrders();
  }, [date]);

  return (
    <div className={styles.PendingPaymentsContainer}>
      <div className={styles.ActionBtnContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(0)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[0] &&
              selectedStatus),
          }}
          className={styles.AllPendingPayments}
        >
          All
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(4)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[4] &&
              selectedStatus),
          }}
          className={styles.DastgyrWillDeliver}
        >
          Dastgyr will Deliver
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCurrentType(3)}
          style={{
            ...(ordersStatus[currentType] === ordersStatus[3] &&
              selectedStatus),
          }}
          className={styles.IWillDelivered}
        >
          I will Deliver
        </Button>
      </div>

      {ordersStatus[currentType] === ordersStatus[0] && (
        <>
          <PendingPaymentsList payments={allPendingPayments} />
          {loading && <Loading />}
          {!loading && allPendingPayments?.managePaymentItems?.length === 0 && (
            <NotFoundMsg />
          )}
        </>
      )}

      {ordersStatus[currentType] === ordersStatus[4] && (
        <>
          <PendingPaymentsList payments={dastgyrPendingPayments} />
          {loading && <Loading />}
          {!loading &&
            dastgyrPendingPayments?.managePaymentItems?.length === 0 && (
              <NotFoundMsg />
            )}
        </>
      )}

      {ordersStatus[currentType] === ordersStatus[3] && (
        <>
          <PendingPaymentsList payments={myPendingPayments} />
          {loading && <Loading />}
          {!loading && myPendingPayments?.managePaymentItems?.length === 0 && (
            <NotFoundMsg />
          )}
        </>
      )}
    </div>
  );
};

export default PendingPayments;
