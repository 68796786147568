import { useState } from "react";
import ModalComponent, {
  DialogTitle,
  DialogContent,
} from "../../../components/Modal";
import styles from "./Modal.module.scss";
import Chip from "@material-ui/core/Chip";

const DeliveryCityModal = ({
  isOpen,
  modalSize,
  modalTitle,
  closeModal,
  openDeliveryApprovalModal,
  openDeliveryRemovalModal,
  setSelectedDeliveryCity,
  deliveryCities,
  sellerDeliveryCities,
}) => {
  const [sellerDeliverCityIds, setSellerDeliveryCityIds] = useState([]);

  function getSellerDeliveryCityId() {
    const array = sellerDeliveryCities;
    const sellerIds = [];
    if (array?.length !== 0) {
      for (let i = 0; i < array?.length; i++) {
        const obj = array[i];
        if (obj.hasOwnProperty("cityId")) {
          sellerIds.push(obj.cityId);
        }
      }
    }
    setSellerDeliveryCityIds(sellerIds);
  }

  const handleClick = (deliveryId) => {
    setSelectedDeliveryCity(deliveryId);
    if (sellerDeliverCityIds.includes(deliveryId)) {
      openDeliveryRemovalModal();
    } else {
      openDeliveryApprovalModal();
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => getSellerDeliveryCityId()}
    >
      <DialogTitle title="title" onClose={closeModal}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div>
          {deliveryCities?.map((data, index) => (
            <Chip
              key={index}
              label={<div className={styles.ChipLabel}>{data.name}</div>}
              className={
                sellerDeliverCityIds.includes(data.id)
                  ? styles.ChipStylingOutlined
                  : styles.ChipStyling
              }
              onClick={() => handleClick(data.id)}
            />
          ))}
          <div className={styles.ShadedContainer}>
            <p className={styles.HintMsg}>
              <strong>To add City, </strong>you can:
            </p>
            <p className={styles.HintMsg}>1. Search for a city, or</p>
            <p className={styles.HintMsg}>2. Select a city form the map, or</p>
            <p className={styles.HintMsg}>
              3. Tap on any of the suggested cities
            </p>
          </div>
        </div>
      </DialogContent>
    </ModalComponent>
  );
};

export default DeliveryCityModal;
