import styles from "./Message.module.scss";

const OTPMessage = ({ timerFinished, handleOTPGeneration }) => (
  <p
    onClick={handleOTPGeneration}
    className={timerFinished ? styles.EnabledOTPMsg : styles.DisabledOTPMsg}
  >
    Didn't receive OTP?
  </p>
);

export default OTPMessage;
