import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, TextField, Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import ClearIcon from "@material-ui/icons/Clear";
import { getSellerPayment } from "../../../../APIs/dashboard";
import { createSellerPayment } from "../../../../APIs/manage-payments";
import styles from "./NewPayments.module.scss";

const NewPayments = () => {
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentProofFile, setPaymentProofFile] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [sellerPayments, setSellerPayments] = useState(0);
  const [loading, setLoading] = useState(false);

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const fileName =
      "Receipt" + "." + event.target.files[0].name.split(".").pop();

    if (file) {
      const fileNameParts = file.name.split(".");
      const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (!["jpg", "jpeg", "png"].includes(extension)) {
        alert("Please select only image files.");
        return;
      }

      setPaymentProofFile(file);
      setPaymentProofFileName(fileName);
    }
  };

  const submitPayment = () => {
    const formData = new FormData();

    formData.append("PaymentAmount", paidAmount);
    formData.append("Image", paymentProofFile);

    setLoading(true);

    createSellerPayment(formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPaidAmount(0);
          setPaymentProofFile("");
          setPaymentProofFileName("");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSellerPayment().then((response) => {
      if (response?.status === 200) {
        setSellerPayments(response?.data?.data?.amountDue ?? 0);
      }
    });
  }, [paidAmount, paymentProofFile]);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: 20,
        }}
      >
        <div className={styles.AmountPaidContainer}>
          <Typography variant="h5">Enter Amount Paid</Typography>
          <TextField
            type="number"
            value={paidAmount}
            label={profileDetails?.currency ?? ""}
            variant="outlined"
            required={true}
            style={{ width: "100%", marginTop: "15px" }}
            onChange={(e) => {
              const amount = e.target.valueAsNumber || 0;

              if (amount >= 0 && amount <= sellerPayments) {
                setPaidAmount(amount);
              }
            }}
          />
        </div>
        <div className={styles.PaymentProofContainer}>
          {paymentProofFile ? (
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#00ac99",
                }}
              >
                Proof of Payment
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#00ac99",
                }}
              >
                Uploaded Successfully
              </p>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  marginTop: "10px",
                }}
              >
                <InsertPhotoIcon color="primary" />
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ margin: 0, fontWeight: "500", color: "#1176ae" }}>
                    {paymentProofFileName}{" "}
                  </p>
                  <ClearIcon
                    style={{ cursor: "pointer", color: "rgb(225 91 115)" }}
                    onClick={() => {
                      setPaymentProofFile("");
                      setPaymentProofFileName("");
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Typography variant="h3" color="primary">
              Upload Proof of Payment
            </Typography>
          )}

          <form>
            <label htmlFor="file-upload" className={styles.CustomFileUpload}>
              <input
                className={styles.FileInput}
                accept="image/*"
                id="file-upload"
                name="payment-proof"
                type="file"
                onChange={(e) => {
                  handleFileInputChange(e);
                }}
              />
              <CloudUploadIcon fontSize="large" color="primary" />
            </label>
          </form>
        </div>
      </div>
      <div
        style={{
          textAlign: "right",
          marginTop: "15px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!(paidAmount && paymentProofFile)}
          onClick={submitPayment}
          style={{ width: "170px", height: "40px" }}
        >
          {loading ? (
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <CircularProgress size={25} style={{ color: "#fff" }} />{" "}
              Loading...
            </div>
          ) : (
            "Submit Payments"
          )}
        </Button>
      </div>
    </>
  );
};

export default NewPayments;
