import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../../../../../../../components/Loading";
import OrderPickedUpCard from "../../components/OrderPickedUpCard";
import PickupsAcceptModal from "./Modals/ConfirmPickups/PickupsAcceptModal";
import PickupsRejectModal from "./Modals/ConfirmPickups/PickupsRejectModal";
import { DEDPickedupSKUs } from "../../../../../../../APIs/order";
import { pickupETA, pickupReview } from "../../../../../../../APIs/logistics";
import styles from "./ShipmentDetails.module.scss";

const ShipmentDetails = () => {
  const [orderPickupETA, setOrderPickupETA] = useState(null);
  const [pickedupSKUs, setPickedupSKUs] = useState([]);
  const [isPickupAcceptModalOpen, setPickupAcceptModalOpen] = useState(false);
  const [isPickupRejectModalOpen, setPickupRejectModalOpen] = useState(false);
  const [reviewPickupLoading, setReviewPickupLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();

  const goTo = () =>
    history.push("/orders/deliver-to-dastgyr", {
      currentType: 2,
      previousDate: state?.date,
    });

  const fetchPickupSKUs = () => {
    return DEDPickedupSKUs({ pickupId: state?.pickupId }).then((res) => {
      if (res?.status === 200) return res.data.data;
    });
  };

  const fetchPickupETA = () => {
    return pickupETA(state?.pickupId).then((res) => {
      if (res?.status === 200) return res.data.data;
    });
  };

  const fetchPickups = () => {
    setLoading(true);

    setOrderPickupETA(null);
    setPickedupSKUs([]);

    Promise.all([fetchPickupSKUs(), fetchPickupETA()])
      .then(([SKUs, pickupETA]) => {
        setPickedupSKUs([...SKUs]);
        setOrderPickupETA({ ...pickupETA });
        setLoading(false);
      })
      .catch((_err) => setLoading(false));
  };

  const reviewPickups = (status) => {
    const payload = {
      pickupId: Number(state?.pickupId),
      status,
    };

    setReviewPickupLoading(true);

    pickupReview(payload)
      .then((res) => {
        if (res?.status === 200) {
          const { pickupReview } = res.data.data;

          setReviewPickupLoading(false);

          if (pickupReview) {
            history.push(`/order/shipment/${state?.pickupId}/receipt`);
          } else {
            history.push("/orders/deliver-to-dastgyr", {
              currentType: 2,
              previousDate: state?.date,
            });
          }
        }
      })
      .catch((_err) => setReviewPickupLoading(false));
  };

  const closePickupsAcceptModal = () => setPickupAcceptModalOpen(false);

  const closePickupsRejectModal = () => setPickupRejectModalOpen(false);

  useEffect(() => fetchPickups(), [state]);

  return (
    <>
      {loading ? (
        <div className={styles.LoadingContainer}>
          <Loading />
        </div>
      ) : (
        <div className={styles.ShipmentWrapper}>
          <div
            className={styles.ShipmentContainer}
            style={{
              ...(orderPickupETA?.tracking_key === 3 && {
                overflowY: "scroll",
                height: "calc(100vh - 290px)",
              }),
            }}
          >
            <div className={styles.Header}>
              <IconButton
                color="primary"
                onClick={goTo}
                className={styles.BackBtn}
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <Typography
                variant={"h2"}
                color="primary"
                className={styles.Title}
              >
                SHIPMENT {id ?? ""}
              </Typography>
            </div>

            {orderPickupETA?.tracking_key === 4 && (
              <div className={styles.ShipmentStatusContainer}>
                <Typography variant="h3" className={styles.Status}>
                  Shipment was already accepted
                </Typography>
              </div>
            )}

            {orderPickupETA?.tracking_key !== 4 && (
              <>
                <div className={styles.StatusContainer}>
                  <Typography variant="h4" style={{ color: "#485359" }}>
                    {orderPickupETA?.message_title ?? ""}
                  </Typography>

                  {orderPickupETA?.tracking_key === 2 && (
                    <Typography variant="h2" style={{ textAlign: "center" }}>
                      Please load all items within
                    </Typography>
                  )}

                  {orderPickupETA?.tracking_key === 3 && (
                    <Typography variant="h2">
                      {orderPickupETA?.message_body ?? ""}
                    </Typography>
                  )}

                  {orderPickupETA?.tracking_key === 1 && (
                    <Typography className={styles.StopTime} variant="h2">
                      {orderPickupETA?.time_window ?? ""}
                    </Typography>
                  )}

                  {[2, 3].includes(orderPickupETA?.tracking_key) && (
                    <Typography className={styles.StopTime} variant="h2">
                      {orderPickupETA?.estimated_stop_time ?? ""}
                    </Typography>
                  )}
                </div>

                {orderPickupETA?.tracking_key === 1 && (
                  <Typography variant="h4">Items To Be Picked</Typography>
                )}

                <div className={styles.OrderItemContainer}>
                  {pickedupSKUs?.map((orderItem) => (
                    <OrderPickedUpCard
                      key={orderItem.productId}
                      details={orderItem}
                      trackingStatus={orderPickupETA?.tracking_key}
                    />
                  ))}
                </div>
              </>
            )}
          </div>

          {orderPickupETA?.tracking_key === 3 && (
            <div className={styles.ConfirmedQtyContainer}>
              <div className={styles.InfoLabel}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  Confirm picked up quantities before accepting
                </Typography>
              </div>

              <div className={styles.ActionBtn}>
                <Button
                  color="primary"
                  className={styles.Reject}
                  variant="outlined"
                  onClick={() => setPickupRejectModalOpen(true)}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  className={styles.Accept}
                  variant="contained"
                  onClick={() => setPickupAcceptModalOpen(true)}
                >
                  Accept
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <PickupsAcceptModal
        modalSize="sm"
        isOpen={isPickupAcceptModalOpen}
        onClose={closePickupsAcceptModal}
        submitReviewPickups={reviewPickups}
        loading={reviewPickupLoading}
      />

      <PickupsRejectModal
        modalSize="sm"
        isOpen={isPickupRejectModalOpen}
        onClose={closePickupsRejectModal}
        submitReviewPickups={reviewPickups}
        loading={reviewPickupLoading}
      />
    </>
  );
};

export default ShipmentDetails;
