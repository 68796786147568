import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import DastgyrLogo from "../../../../assets/images/dastgyr-logo.png";
import styles from "./SKUsListing.module.scss";

const useStyles = makeStyles((_theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    marginBottom: "5px",
    cursor: "pointer",
  },
  avatar: {
    margin: "0px",
  },
  productImg: {
    width: "50px",
    marginRight: "10px",
  },
}));

const SKUsListings = (props) => {
  const { SKUs, setSelectedSKU, setSearchTerm, setQty } = props;
  const classes = useStyles();

  return (
    <div className={styles.SKUsListingContainer}>
      <List className={classes.root}>
        {SKUs?.length > 0 &&
          SKUs.map((SKU, idx) => {
            return (
              <div key={SKU.id}>
                <ListItem
                  className={classes.listItem}
                  alignItems="flex-start"
                  onClick={() => {
                    setSearchTerm("");
                    setSelectedSKU(SKU);

                    if (SKU.baseUomId !== 1) {
                      setQty("1.0");
                    }
                  }}
                >
                  {SKU?.image ? (
                    <ListItemAvatar>
                      <Avatar
                        className={classes.avatar}
                        alt="SKU Item"
                        src={SKU?.image}
                      />
                    </ListItemAvatar>
                  ) : (
                    <img
                      className={classes.productImg}
                      alt="SKU Item"
                      src={DastgyrLogo}
                    />
                  )}
                  <ListItemText primary={SKU.name} />
                </ListItem>
                {idx !== SKUs.length - 1 && <Divider component="li" />}
              </div>
            );
          })}
      </List>
    </div>
  );
};

export default SKUsListings;
