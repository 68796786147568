import moment from "moment";
import { store } from "../store";
import { removeUserDetails } from "../features/userDetails/userDetailsSlice";
import { removeProfileDetails } from "../features/profileDetails/profileDetailsSlice";
import { removeFulfillmentModeDetails } from "../features/fulfillmentModes/modesSlice";

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key) {
  const item = localStorage.getItem(key);

  if (item === null) return null;
  return JSON.parse(item);
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}

export function formatDateTime(date, fm = "Do MMM YYYY") {
  return date ? moment(date).format(fm) : "--";
}

export function truncate(val, limit = 10) {
  let modifiedVal = "";

  if (val) {
    return val.length > limit ? val.substring(0, limit) + " ..." : val;
  }

  return modifiedVal;
}

export function getQuery(query) {
  const keyValuePairs = [];

  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key];
      keyValuePairs.push(`${key}=${value}`);
    }
  }

  return `?${keyValuePairs.join("&")}`;
}

export const toFixedWithRoundingOff = (number, precision = 3) => {
  //rounds up for price
  const factor = Math.pow(10, precision);
  const truncatedNumber = Math.round(number * factor) / factor;
  return truncatedNumber.toFixed(precision);
};

export const convertToUTC = (date = new Date(), endDate = false) => {
  if (endDate === true) {
    return moment(date)
      .set({ hour: 23, minute: 59, second: 59 })
      .local()
      .utc()
      .format();
  } else {
    return moment.utc(date).format();
  }
};

export const convertToLocal = (date = new Date(), includeTime = false) => {
  if (includeTime) {
    return moment(date).format("DD MMM YYYY, h:mm:ss a");
  } else {
    return moment.utc(date).local().format("DD MMM YYYY");
  }
};

export const isValidDecimal = (value, precision) => {
  const regex = new RegExp(`^\\d*\\.?\\d{0,${precision}}$`);
  return value === "" || regex.test(value);
};

export const getStartEndUTC = (date) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  const startUTCDate = new Date(date.getTime());
  const endUTCDate = new Date(date.getTime());

  startUTCDate.setUTCHours(0, 0, 0, 0);
  endUTCDate.setUTCHours(23, 59, 59, 999);

  return {
    fromDateTime: startUTCDate.toISOString(),
    toDateTime: endUTCDate.toISOString(),
  };
};

export const validateNumericWithOnePrecision = (value) => {
  const re = /^\d+(\.\d{1})?$/;
  return re.test(value);
};

export const validateNumericWithThreePrecision = (value) => {
  const re = /^\d+(\.\d{1,3})?$/;
  return re.test(value);
};

export const getPrecision = (number = 2) => {
  const precision = {
    1: "0.0",
    2: "0.00",
    3: "0.000",
  };

  return precision[number];
};

export const exportCSV = (data) => {
  const csv = convertArrayOfObjectsToCSV(data);
  const blob = new Blob([csv], { type: "text/csv" }); // Create a Blob object from the CSV string
  const url = window.URL.createObjectURL(blob); // Create a download link and trigger the download
  const a = document.createElement("a");

  a.href = url;
  a.download = "Listings.csv";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const convertArrayOfObjectsToCSV = (data) => {
  const headers = Object.keys(data[0]);
  let csv = headers.join(",") + "\n";

  data.forEach((item) => {
    const values = headers.map((header) => item[header]);
    csv += values.join(",") + "\n";
  });

  return csv;
};

export const convertDatePick = (date, text) => {
  if (moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
    return text + " Today";
  }

  if (
    moment(date).format("YYYY-MM-DD") ===
    moment().add(1, "days").format("YYYY-MM-DD")
  ) {
    return text + " Tomorrow";
  }

  return text + " On " + moment(date).format("YYYY-MM-DD");
};

export const logout = (history) => {
  store.dispatch(removeUserDetails());
  store.dispatch(removeProfileDetails());
  store.dispatch(removeFulfillmentModeDetails());

  removeLocalStorage("persist:root");
  removeLocalStorage("auth-token");
  removeLocalStorage("user-details");

  if (!history) return window.location.replace("/");

  history.push("/");
};

export const getRemainingDaysInMonth = () => {
  const currentDate = moment();
  const lastDayOfMonth = moment().endOf("month");
  const remainingDays = lastDayOfMonth.diff(currentDate, "days") + 1;

  return remainingDays;
};

export const getDateDifference = (date) => {
  const moment = require("moment");
  const currentDate = moment();
  const specifiedDate = moment(date);
  const duration = moment.duration(specifiedDate.diff(currentDate));
  const daysDiff = Math.ceil(duration.asDays());

  return daysDiff;
};
