import { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, IconButton, Divider } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PickupSKUsModal from "../../PastOrders/Modal/PickupSKUsModal";
import DastgyrLogo from "../../../../../../../assets/images/dastgyr-logo.png";
import { truncate } from "../../../../../../../Utilities";
import styles from "./PastOrderCard.module.scss";

const PastOrderCard = ({ orderItem, handleSKUsVisibility }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPickupSKUsModalOpen, setPickupSKUsModalOpen] = useState(false);
  const {
    pickupCode,
    pickupId,
    orderItems,
    showSKUItems,
    showBtn,
    enableViewMoreBtn,
  } = orderItem;

  const { profileDetails } = useSelector((state) => state.profileDetails);

  const closePickupSKUsModal = () => {
    setPickupSKUsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <>
      <div className={styles.PastOrderCard}>
        <div className={styles.Header}>
          <Typography variant="h3">Shipment ID:</Typography>
          <Typography className={styles.ShipmentID} variant="h1">
            {pickupCode ?? ""}
          </Typography>
        </div>
        <Divider></Divider>
        <div className={styles.OrderItems}>
          <Typography variant="h4">Shipment items:</Typography>
          {orderItems?.slice(0, showSKUItems)?.map((item) => {
            return (
              <div key={item.productId} className={styles.SKUItemContainer}>
                <div className={styles.PrimaryContainer}>
                  <div className={styles.ProductImgContainer}>
                    <img
                      className={styles.ProductImg}
                      alt="SKU Item"
                      src={
                        item?.productImage && JSON.parse(item?.productImage)
                          ? JSON.parse(item?.productImage)
                          : DastgyrLogo
                      }
                    />
                  </div>
                </div>
                <div className={styles.SecondaryContainer}>
                  <div className={styles.ProductDetails}>
                    <Typography
                      className={styles.ProductLabel}
                      variant="h6"
                      title={item?.productName ?? ""}
                    >
                      {truncate(item?.productName, 30)}
                    </Typography>
                    {item?.mrp && (
                      <div className={styles.Badge}>
                        MRP: {profileDetails?.currency ?? ""} {item?.mrp ?? 0}
                      </div>
                    )}
                  </div>
                  <div className={styles.QtyContainer}>
                    <Typography color="secondary" variant="h5">
                      Total Qty: {item?.quantity ?? 0}
                    </Typography>
                    <Typography className={styles.UnitCost} variant="h5">
                      {profileDetails?.currency ?? ""}:{" "}
                      {new Intl.NumberFormat("en-US").format(item?.price ?? 0)}
                    </Typography>
                  </div>
                  <IconButton
                    className={styles.ViewDetails}
                    disableRipple={true}
                    onClick={() => {
                      setSelectedOrder({
                        pickupId,
                        skuId: item?.skuId,
                        productName: item?.productName,
                        mrp: item?.mrp,
                        deliveredQuantity: item.deliveredQuantity,
                        orderedQuantity: item.orderedQuantity,
                        pickedUpQuantity: item.pickedUpQuantity,
                        returnedQuantity: item.returnedQuantity
                      });
                      setTimeout(() => setPickupSKUsModalOpen(true), 1000);
                    }}
                  >
                    Details{" "}
                    <ArrowForwardIosIcon
                      style={{ marginLeft: "5px" }}
                      fontSize="small"
                    />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
        {showBtn && (
          <div className={styles.ViewMoreContainer}>
            <Typography
              className={styles.Label}
              variant="h5"
              onClick={() => {
                if (enableViewMoreBtn) {
                  handleSKUsVisibility(
                    pickupId,
                    orderItems?.length,
                    "viewMore "
                  );
                } else {
                  handleSKUsVisibility(
                    pickupId,
                    orderItems?.length,
                    "viewLess"
                  );
                }
              }}
            >
              {enableViewMoreBtn ? "View more" : "View less"}
            </Typography>
          </div>
        )}
      </div>
      <PickupSKUsModal
        modalSize="sm"
        isOpen={isPickupSKUsModalOpen}
        onClose={closePickupSKUsModal}
        selectedOrder={selectedOrder}
      />
    </>
  );
};

export default PastOrderCard;
