import { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { setProfileDetails } from "../../../../features/profileDetails/profileDetailsSlice";
import { setFulfillmentModeDetails } from "../../../../features/fulfillmentModes/modesSlice";
import { setLocationStatus } from "../../../../features/sellerLocationStatus/locationSlice";
import { getProfileDetails } from "../../../../APIs/auth";
import {
  getFulfillmentMode,
  validateSellerLocation,
} from "../../../../APIs/listing";
import { logout } from "../../../../Utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  dastgyr: {
    borderRadius: 3,
    border: 0,
    color: "white",
    fontSize: "34px",
    fontFamily: "Nunito Sans, Roboto, sans-serif",
  },
}));

const Topbar = ({ className, onSidebarOpen, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { userDetails } = useSelector((state) => state.userDetails);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  useEffect(() => {
    const fetchProfileDetails = () => {
      getProfileDetails().then((res) => {
        if (res?.status === 200) {
          const profileDetails = res?.data?.data ?? {};
          dispatch(setProfileDetails(profileDetails));
        }
      });
    };

    const fetchFulfillmentModeDetails = () => {
      getFulfillmentMode().then((res) => {
        if (res?.status === 200) {
          const modeDetails = res?.data?.data ?? {};
          dispatch(setFulfillmentModeDetails(modeDetails));
        }
      });
    };

    const fetchSellerLocationStatus = () => {
      validateSellerLocation().then((res) => {
        if (res?.status === 200) {
          const locationStatus = {
            isValid: res?.data?.isValid ?? false,
          };

          dispatch(setLocationStatus(locationStatus));
        }
      });
    };

    if (userDetails) {
      fetchProfileDetails();
      fetchFulfillmentModeDetails();
      fetchSellerLocationStatus();
    }
  }, [dispatch, userDetails]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className={clsx(classes.dastgyr, className)}>Dastgyr Seller</div>
        <div className={classes.flexGrow} />
        {userDetails && (
          <>
            <div>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={openMenu}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/manage-profile");
                    closeMenu();
                  }}
                >
                  <Typography variant="h4">My Profile</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logout(history);
                    closeMenu();
                  }}
                >
                  <Typography variant="h4">Logout</Typography>
                </MenuItem>
              </Menu>
            </div>
            <Hidden lgUp>
              <IconButton color="inherit" onClick={onSidebarOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
