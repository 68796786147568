import { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import Loading from "../../../../../../components/Loading";
import NotFoundMsg from "../../../../../../components/NotFoundMsg";
import Pagination from "../../../../../../components/Pagination";
import PastOrderCard from "../components/PastOrderCard";
import { DEDPastOrders } from "../../../../../../APIs/order";
import { getStartEndUTC } from "../../../../../../Utilities/index";
import styles from "./PastOrders.module.scss";

const PastOrders = ({ date }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [pastOrders, setPastOrders] = useState([]);
  const SKUItemsCount = useRef(2);

  const fetchPastOrders = () => {
    const { fromDateTime, toDateTime } = getStartEndUTC(date);
    const query = {
      startDate: fromDateTime,
      endDate: toDateTime,
      pageNumber: page + 1,
      pageSize,
    };

    setLoading(true);
    setPastOrders([]);

    DEDPastOrders(query)
      .then((res) => {
        if (res.status === 200) {
          const pastOrders = res?.data?.data?.map((order) => {
            return {
              ...order,
              orderItemsLength: order.orderItems.length,
              showSKUItems: SKUItemsCount.current,
              enableViewMoreBtn:
                order.orderItems.length > SKUItemsCount.current,
              showBtn: order.orderItems.length > SKUItemsCount.current,
            };
          });

          setPastOrders(pastOrders);
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setPageSize(newRowsPerPage);
    setPage(0);
  };

  const handleSKUsVisibility = (pickupID, orderItemCount, viewType) => {
    let modifiedOrders = undefined;
    const orders = [...pastOrders];

    if (viewType === "viewLess") {
      modifiedOrders = orders.map((order) => {
        return order.pickupId === pickupID
          ? {
              ...order,
              enableViewMoreBtn: true,
              showSKUItems: 2,
            }
          : order;
      });
    } else {
      modifiedOrders = orders.map((order) => {
        return order.pickupId === pickupID
          ? {
              ...order,
              enableViewMoreBtn: !order.enableViewMoreBtn,
              showSKUItems: orderItemCount,
            }
          : order;
      });
    }

    setPastOrders(modifiedOrders);
  };

  useEffect(() => fetchPastOrders(), [date, page, pageSize]);

  return (
    <div>
      {!loading && pastOrders?.length === 0 && <NotFoundMsg />}
      {loading && <Loading />}
      {pastOrders.length > 0 && (
        <>
          <Typography variant="h4">{pastOrders[0]["pickupDue"]}</Typography>
          <div className={styles.PastOrdersContainer}>
            {pastOrders.map((orderItem) => (
              <PastOrderCard
                key={orderItem.pickupId}
                orderItem={orderItem}
                handleSKUsVisibility={handleSKUsVisibility}
              />
            ))}
          </div>
          <Pagination
            label="Orders per page"
            page={page}
            pageSize={pageSize}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            count={pastOrders?.length}
          />
        </>
      )}
    </div>
  );
};

export default PastOrders;
