import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography, IconButton } from "@material-ui/core";
import Loading from "../../../../../../../../../components/Loading";
import NotFoundMsg from "../../../../../../../../../components/NotFoundMsg";
import { formatDateTime } from "../../../../../../../../../Utilities";
import { DEDReturnReceipt } from "../../../../../../../../../APIs/order";
import OrderCard from "../../components/OrderCard";
import { downloadReceipt } from "./ViewReturnReceipt";
import styles from "./ReturnReceipt.module.scss";

const ReturnReceipt = () => {
  const [returnReceipt, setReturnReceipt] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { profileDetails } = useSelector((state) => state.profileDetails);

  const backToOrders = () => history.push("/orders/deliver-to-dastgyr");

  const fetchReturnReceipt = () => {
    const params = {
      returnId: id,
    };

    setLoading(true);
    DEDReturnReceipt(params)
      .then((res) => {
        if (res?.status === 200) {
          const receiptDetails = res.data.data;

          setLoading(false);
          setReturnReceipt({ ...receiptDetails });
        }
      })
      .catch((_err) => setLoading(false));
  };

  useEffect(() => {
    fetchReturnReceipt();
  }, [id]);

  return (
    <div className={styles.ReturnReceiptWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToOrders}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          RECEIPT
        </Typography>
        <IconButton
          color="primary"
          aria-label="Download receipt"
          onClick={() => downloadReceipt(returnReceipt)}
        >
          <GetAppIcon />
        </IconButton>
      </div>
      {loading && <Loading />}
      {!loading && returnReceipt?.orderItems?.length === 0 && <NotFoundMsg />}
      {returnReceipt && (
        <>
          <div className={styles.ShipmentInfo}>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Seller Name</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {returnReceipt?.sellerName ?? ""}
              </Typography>
            </div>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Shipment ID</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {returnReceipt?.returnCode ?? ""}
              </Typography>
            </div>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Shipment Date</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {formatDateTime(returnReceipt?.shipmentDate) ?? ""}
              </Typography>
            </div>
          </div>
          <div className={styles.AlignItems}>
            <Typography variant="h4">Returned Orders</Typography>
            <Typography className={styles.TotalAmount} variant="h4">
              Total: {profileDetails?.currency ?? ""}{" "}
              <strong>
                {new Intl.NumberFormat("en-US").format(
                  returnReceipt?.totalReturnedAmount ?? 0
                )}
              </strong>
            </Typography>
          </div>
          <div className={styles.Orders}>
            {returnReceipt?.orderItems?.map((order) => (
              <OrderCard key={order.productId} details={order} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReturnReceipt;
