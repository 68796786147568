import { useHistory } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ViewPayment from "../../assets/images/viewPayment.png";
import SubmitPayment from "../../assets/images/submitPayment.png";
import styles from "./Payments.module.scss";

const ManagePayments = () => {
  const history = useHistory();
  const goTo = (path) => history.push(path);

  return (
    <div className={styles.PaymentsWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/dashboard")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          MANAGE PAYMENTS
        </Typography>
      </div>
      <div className={styles.MainSection}>
        <div className={styles.PaymentContainer}>
          <div
            className={styles.Payments}
            onClick={() => goTo("/payments/view-payments-status")}
          >
            <div>
              <img
                alt="delivery-place"
                src={ViewPayment}
                className={styles.Img}
              />
            </div>
            <div>
              <p className={styles.LabelHeader}>
                View Pending & Completed Payments
              </p>
            </div>
            <div className={styles.LabelIcon}>
              <ArrowForwardIosIcon fontSize="large" />
            </div>
          </div>
          <div
            className={styles.Payments}
            onClick={() => goTo("/payments/submit-payments")}
          >
            <div>
              <img
                alt="time-clock"
                src={SubmitPayment}
                className={styles.Img}
              />
            </div>
            <div>
              <p className={styles.LabelHeader}>Submit Payments</p>
            </div>
            <div className={styles.LabelIcon}>
              <ArrowForwardIosIcon fontSize="large" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePayments;
