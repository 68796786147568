import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import style from "../Profile.module.scss";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const StoreDetail = (props) => {
  const {
    province,
    city,
    store,
    setStore,
    storeLocation,
    setStoreLocation,
    errors,
  } = props;

  const filteredCity = () => {
    const cities = [];

    if (city.length > 0) {
      city.forEach((value) => {
        if (value.stateId === store.province) {
          cities.push(value);
        }
      });
    }

    return cities;
  };

  const handleChange = (type, value) => {
    setStore((previousStore) => {
      return {
        ...previousStore,
        [type]: value,
      };
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  };

  function showPosition(position) {
    const coordinates = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    setStoreLocation(coordinates);
  }

  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Store Details</h1>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <FormControl
          variant="outlined"
          className={style.TextField}
          error={errors && Boolean(errors.province)}
        >
          <InputLabel required>Province</InputLabel>
          <Select
            value={store.province}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            label="Province"
            name="province"
          >
            {province?.length > 0 &&
              province.map((option) => (
                <MenuItem key={option.stateId} value={option.stateId}>
                  {option.stateName}
                </MenuItem>
              ))}
          </Select>
          {errors.province && (
            <FormHelperText>{errors.province}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          className={style.TextField}
          error={errors && Boolean(errors.city)}
        >
          <InputLabel required>City</InputLabel>
          <Select
            value={store.city || ""}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            label="City"
            name="city"
            disabled={!store.province}
          >
            {filteredCity().map((option) => (
              <MenuItem key={option.cityId} value={option.cityId}>
                {option.cityName}
              </MenuItem>
            ))}
          </Select>
          {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
        </FormControl>
      </form>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          required
          className={style.TextField}
          name="address"
          value={store.address}
          label="Address"
          variant="outlined"
          multiline
          minRows={4}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={errors && Boolean(errors.address)}
          helperText={errors && errors.address}
        />
        <Button
          className={style.UploadButton}
          variant="contained"
          color="default"
          startIcon={<AddLocationIcon />}
          component="span"
          onClick={() => getLocation()}
        >
          Add Location
        </Button>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {storeLocation.latitude !== "" && (
            <p style={{ margin: "0" }}>Latitude: {storeLocation.latitude}</p>
          )}
          {storeLocation.longitude !== "" && (
            <p style={{ margin: "0" }}>Longitude: {storeLocation.longitude}</p>
          )}
          {(storeLocation.latitude === "" || storeLocation.longitude === "") &&
            errors.latitude &&
            errors.longitude && (
              <p className={style.ErrorLabel}>Location is required</p>
            )}
        </div>
      </form>
    </div>
  );
};

export default StoreDetail;
