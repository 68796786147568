import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../../../../components/Modal";
import Loading from "../../../../../../../components/Loading";
import { DEDPickupSKUs } from "../../../../../../../APIs/order";
import styles from "./Modal.module.scss";

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

const PickupSKUsModal = ({ isOpen, onClose, modalSize, selectedOrder }) => {
  const [SKUsDetails, setSKUsDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const { profileDetails } = useSelector((state) => state.profileDetails);
  const classes = useStyles();

  const onInitialize = () => {
    if (
      'deliveredQuantity' in selectedOrder &&
      'orderedQuantity' in selectedOrder &&
      'pickedUpQuantity' in selectedOrder &&
      'returnedQuantity' in selectedOrder
    ) {
      setSKUsDetails(selectedOrder);
    } else {
    const params = {
      pickupId: selectedOrder?.pickupId,
      skuId: selectedOrder?.skuId,
    };

    setLoading(true);
    DEDPickupSKUs(params)
      .then((res) => {
        if (res?.status === 200) {
          const pickupDetails = res.data.data;

          setSKUsDetails(pickupDetails);
          setLoading(false);
        }
      })
      .catch((_err) => setLoading(false));
    }
  };

  const resetModal = () => {
    setSKUsDetails(null);
    setLoading(false);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      modalSize={modalSize}
      onExited={resetModal}
      onEnter={onInitialize}
    >
      <DialogTitle title="title">
        {selectedOrder?.productName ?? ""}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.PickupSKUsModalContainer}>
          {loading && <Loading />}
          {SKUsDetails && (
            <>
              {selectedOrder?.mrp && (
                <div className={styles.Badge}>
                  MRP: {profileDetails?.currency ?? ""}{" "}
                  {selectedOrder?.mrp ?? 0}
                </div>
              )}
              <div className={styles.TableContainer}>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography variant="h4">Quantity</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Ordered</TableCell>
                        <TableCell align="center">
                          {SKUsDetails?.orderedQuantity ?? 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Picked Up</TableCell>
                        <TableCell align="center">
                          {SKUsDetails?.pickedUpQuantity ?? 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Delivered</TableCell>
                        <TableCell align="center">
                          {SKUsDetails?.deliveredQuantity ?? 0}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Returned</TableCell>
                        <TableCell align="center">
                          {SKUsDetails?.returnedQuantity ?? 0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.ActionBtnContainer}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default PickupSKUsModal;
