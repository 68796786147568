export const homeFilter = [
  {
    id: "5",
    name: "Today",
    filter: "5",
  },
  {
    id: "1",
    name: "Last 7 days ",
    filter: "1",
  },
  {
    id: "2",
    name: "Last 30 days ",
    filter: "2",
  },
  {
    id: "3",
    name: "This week",
    filter: "3",
  },
  {
    id: "4",
    name: "This month so far",
    filter: "4",
  },
];
