import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../../../../../../../../../components/Loading";
import OrderReturnCard from "../../components/OrderReturnCard";
import ReturnsAcceptModal from "./Modals/ConfirmReturns/ReturnsAcceptModal";
import ReturnsRejectModal from "./Modals/ConfirmReturns/ReturnsRejectModal";
import { DEDReturnSKUs } from "../../../../../../../../../APIs/order";
import { returnStopReview } from "../../../../../../../../../APIs/logistics";
import styles from "./ShipmentReturnDetails.module.scss";

const ShipmentReturnDetails = () => {
  const [returnSKUs, setReturnSKUs] = useState({});
  const [isReturnAcceptModalOpen, setReturnAcceptModalOpen] = useState(false);
  const [isReturnRejectModalOpen, setReturnRejectModalOpen] = useState(false);
  const [returnReviewLoading, setReturnReviewLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();

  const goTo = () =>
    history.push("/orders/deliver-to-dastgyr", {
      currentType: 3,
      previousDate: state?.date,
    });

  const fetchReturnSKUs = () => {
    return DEDReturnSKUs({ returnId: state?.returnId }).then((res) => {
      if (res?.status === 200) return res.data.data;
    });
  };

  const fetchReturns = () => {
    setLoading(true);
    setReturnSKUs({});

    Promise.all([fetchReturnSKUs()])
      .then(([SKUs]) => {
        setReturnSKUs({ ...SKUs });
        setLoading(false);
      })
      .catch((_err) => setLoading(false));
  };

  const reviewReturns = (status) => {
    const payload = {
      returnStopId: Number(state?.returnId),
      status,
    };

    setReturnReviewLoading(true);

    returnStopReview(payload)
      .then((res) => {
        if (res?.status === 200) {
          const { returnStopReview } = res.data.data;

          setReturnReviewLoading(false);
          if (returnStopReview) {
            history.push(`/order/shipment/${state?.returnId}/return-receipt`);
          } else {
            history.push("/orders/deliver-to-dastgyr", {
              currentType: 3,
              previousDate: state?.date,
            });
          }
        }
      })
      .catch((_err) => setReturnReviewLoading(false));
  };

  const closeReturnsAcceptModal = () => setReturnAcceptModalOpen(false);
  const closeReturnsRejectModal = () => setReturnRejectModalOpen(false);

  useEffect(() => fetchReturns(), [state]);

  return (
    <>
      {loading ? (
        <div className={styles.LoadingContainer}>
          <Loading />
        </div>
      ) : (
        <div className={styles.ShipmentWrapper}>
          <div
            className={styles.ShipmentContainer}
            style={{
              ...(returnSKUs?.key === 2 && {
                overflowY: "scroll",
                height: "calc(100vh - 290px)",
              }),
            }}
          >
            <div className={styles.Header}>
              <IconButton
                color="primary"
                onClick={goTo}
                className={styles.BackBtn}
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <Typography
                variant={"h2"}
                color="primary"
                className={styles.Title}
              >
                SHIPMENT {id ?? ""}
              </Typography>
            </div>

            {returnSKUs?.key === 3 && (
              <div className={styles.ShipmentStatusContainer}>
                <Typography variant="h3" className={styles.Status}>
                  Shipment was already accepted
                </Typography>
              </div>
            )}

            {returnSKUs?.key !== 3 && (
              <>
                {returnSKUs?.key === 1 && (
                  <Typography variant="h4">Items To Be Returned</Typography>
                )}
                <div className={styles.OrderItemContainer}>
                  {returnSKUs?.returnSkusResponse?.map((orderItem) => (
                    <OrderReturnCard
                      key={orderItem.productId}
                      details={orderItem}
                      trackingStatus={returnSKUs?.key}
                    />
                  ))}
                </div>
              </>
            )}
          </div>

          {returnSKUs?.key === 2 && (
            <div className={styles.ConfirmedQtyContainer}>
              <div className={styles.InfoLabel}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  Confirm number of returned items before accepting or rejecting
                </Typography>
              </div>
              <div className={styles.ActionBtn}>
                <Button
                  color="primary"
                  className={styles.Reject}
                  variant="outlined"
                  onClick={() => setReturnRejectModalOpen(true)}
                >
                  Reject
                </Button>
                <Button
                  color="primary"
                  className={styles.Accept}
                  variant="contained"
                  onClick={() => setReturnAcceptModalOpen(true)}
                >
                  Accept
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <ReturnsAcceptModal
        modalSize="sm"
        isOpen={isReturnAcceptModalOpen}
        onClose={closeReturnsAcceptModal}
        submitReviewReturn={reviewReturns}
        loading={returnReviewLoading}
      />

      <ReturnsRejectModal
        modalSize="sm"
        isOpen={isReturnRejectModalOpen}
        onClose={closeReturnsRejectModal}
        submitReviewReturn={reviewReturns}
        loading={returnReviewLoading}
      />
    </>
  );
};

export default ShipmentReturnDetails;
