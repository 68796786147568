import {
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import styles from "./Pagination.module.scss";

const Pagination = ({
  label,
  page,
  pageSize,
  handlePreviousPage,
  handleNextPage,
  handleRowsPerPageChange,
  count,
}) => {
  return (
    <div className={styles.PaginationWrapper}>
      <div className={styles.PerPageContainer}>
        <InputLabel>{label}:</InputLabel>
        <FormControl>
          <Select value={pageSize} onChange={handleRowsPerPageChange}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Button onClick={handlePreviousPage} disabled={page === 0}>
        <KeyboardArrowLeftIcon />
      </Button>
      <Button onClick={handleNextPage} disabled={pageSize > count}>
        <KeyboardArrowRightIcon />
      </Button>
    </div>
  );
};

export default Pagination;
