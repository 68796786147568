import { Typography } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { getDateDifference } from "../../../Utilities";
import styles from "./Suspension.module.scss";

const SellerSuspension = ({ unsuspensionDate }) => {
  return (
    <div className={styles.SuspensionWrapper}>
      <Typography className={styles.Headline} variant="h2">
        Your account is temporarily on hold.
      </Typography>
      <Typography className={styles.HelperMsg} variant="h3">
        You can resume activity in{" "}
        <span className={styles.UnsuspensionDays}>
          {getDateDifference(unsuspensionDate)} days
        </span>{" "}
        or Call Dastgyr Helpline to learn more.
      </Typography>
      <ReportProblemIcon className={styles.ReportIcon} />
      <Typography className={styles.Status} variant="h3">
        Account on Hold
      </Typography>
    </div>
  );
};

export default SellerSuspension;
