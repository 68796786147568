import Rating from "@material-ui/lab/Rating";

function RatingComponent(props) {
  const {
    value,
    customStyles = {},
    defaultValue = 0,
    precision = 0.5,
    readOnly = false,
    size = "medium",
    max = 5,
  } = props;

  return (
    <Rating
      value={value}
      defaultValue={defaultValue}
      precision={precision}
      style={customStyles}
      readOnly={readOnly}
      size={size}
      max={max}
    />
  );
}

export default RatingComponent;
