import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { removeSnackbarDetails } from "../../features/snackbar/snackbarSlice";
import styles from "./Approval.module.scss";

const AwaitingApproval = () => {
  const [showProfileMsg, setShowProfileMsg] = useState(true);
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const backToHome = () => {
    dispatch(
      removeSnackbarDetails({
        open: false,
        message: "",
        hideDuration: 0,
        severity: "",
      })
    );

    history.push("/");
  };

  useEffect(() => {
    if (state?.from === "/signup") {
      const intervalID = setTimeout(() => {
        setShowProfileMsg(false);
      }, 2000);

      return () => clearInterval(intervalID);
    }
  }, [state]);

  return (
    <>
      {showProfileMsg && state?.from === "/signup" ? (
        <div className={styles.ProfileInfoContainer}>
          <p className={styles.ProfileInfoMsg}>
            Your profile has been completed
          </p>
        </div>
      ) : (
        <div className={styles.ApprovalWrapper}>
          <IconButton color="primary" onClick={backToHome}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <div className={styles.ApprovalContainer}>
            <h1 className={styles.ApprovalHeading}>Awaiting Approval</h1>
            <p className={styles.ApprovalMsg}>
              Thank you for registering with Dastgyr. We have received your
              application to become Dastgyr seller. Our team will contact you
              shortly.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AwaitingApproval;
