import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { formatDateTime } from "../../../../Utilities";
import { getApprovalPaymentStatus } from "../../../../APIs/manage-payments";
import styles from "./ApprovalStatus.module.scss";

const ApprovalStatus = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentsApprovalStatus, setPaymentsApprovalStatus] = useState([]);
  const { date } = props;

  const { profileDetails } = useSelector((state) => state.profileDetails);

  useEffect(() => {
    setLoading(true);
    const query = {
      startDate: date.startDate,
      endDate: date.endDate,
    };

    getApprovalPaymentStatus(query)
      .then((res) => {
        if (res.status === 200) {
          setPaymentsApprovalStatus(res?.data?.data ?? []);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [date]);

  const Loading = () => (
    <div className={styles.LoadingContainer}>
      <CircularProgress color="primary" />
    </div>
  );

  return (
    <div className={styles.ApprovalStatusContainer}>
      {paymentsApprovalStatus?.map((status) => {
        return (
          <div
            key={status.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div>
              <p style={{ margin: 0 }}>
                {profileDetails?.currency ?? ""}{" "}
                <strong>
                  {new Intl.NumberFormat("en-US").format(status?.amount ?? 0)}
                </strong>
              </p>
              <p style={{ margin: 0, color: "#8d8585" }}>
                Submission Date: {formatDateTime(status?.submissionDate)}
              </p>
            </div>
            <div>
              <p
                style={{
                  margin: 0,
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "#00ac99",
                }}
              >
                {status?.paymentStatus ?? ""}
              </p>
              <p style={{ margin: 0, color: "#8d8585" }}>
                {status?.paymentStatusDescription ?? ""}
              </p>
            </div>
          </div>
        );
      })}
      {loading && <Loading />}
      {!loading && paymentsApprovalStatus?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p style={{ fontSize: "18px", fontWeight: "500", margin: "0px" }}>
            No Data Found
          </p>
        </div>
      )}
    </div>
  );
};

export default ApprovalStatus;
