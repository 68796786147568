import { useState } from "react";
import {
  Button,
  IconButton,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../components/Modal";
import {
  deliveredOrderQtyDetail,
  getPartialDeliveryReasons,
  onSpotReturnItem,
} from "../../../../APIs/order";
import DastgyrLogo from "../../../../assets/images/dastgyr-logo.png";
import styles from "./Modal.module.scss";

const DeliveredOrderModal = ({
  modalTitle = "",
  isOpen,
  modalSize,
  onClose,
  orderId,
  updateOrderStatus,
  deliveredOrderLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [isLimitedOrderDelivered, setLimitedOrderDelivered] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [reasonsLoading, setReasonsLoading] = useState(false);
  const [SKUs, SKUsDetails] = useState(null);
  const [returnAmount, setReturnAmount] = useState(null);

  const incrementQty = (idx) => {
    const duplicateSKUs = [...SKUs.items];
    const maxQty = duplicateSKUs[idx]["maxQuantity"];
    let quantity = duplicateSKUs[idx]["quantity"];

    if (quantity < maxQty) {
      quantity++;
    }

    duplicateSKUs[idx] = { ...duplicateSKUs[idx], quantity };
    SKUsDetails({ ...SKUs, items: [...duplicateSKUs] });
  };

  const decrementQty = (idx) => {
    const duplicateSKUs = [...SKUs.items];
    let quantity = duplicateSKUs[idx]["quantity"];

    if (quantity > 1) {
      quantity--;
    }

    duplicateSKUs[idx] = { ...duplicateSKUs[idx], quantity };
    SKUsDetails({ ...SKUs, items: [...duplicateSKUs] });
  };

  const fetchDeliveredOrderQty = () => {
    const query = {
      orderId,
    };

    setLoading(true);
    deliveredOrderQtyDetail(query)
      .then((res) => {
        if (res?.status === 200) {
          const { items, orderId } = res?.data ?? {};
          const SKUs = [...items].map((SKU) => ({
            ...SKU,
            isChecked: false,
          }));

          setLoading(false);
          SKUsDetails({ items: SKUs, orderId });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchPartialDeliveryReasons = () => {
    setReasonsLoading(true);

    getPartialDeliveryReasons()
      .then((res) => {
        if (res?.status === 200) {
          setReasons(res?.data?.data ?? []);
          setReasonsLoading(false);
        }
      })
      .catch((err) => {
        setReasonsLoading(false);
      });
  };

  const returnItemsOnSpot = () => {
    const { items, orderId } = SKUs;
    const payload = {
      items: items.filter((item) => item.isChecked),
      partialReasonId: selectedReason,
      orderId,
    };

    setReasonsLoading(true);
    onSpotReturnItem(payload)
      .then((res) => {
        if (res?.status === 200) {
          setReasonsLoading(false);
          setReturnAmount(res?.data ?? null);
        }
      })
      .catch((err) => {
        setReasonsLoading(false);
      });
  };

  const handlePartialDeliveryReasons = (e) => {
    const reason = Number(e.target.value);
    setSelectedReason(reason);
  };

  const handleSelectedSKU = (idx) => {
    const { items } = SKUs;
    const duplicateSKUs = [...items];

    duplicateSKUs[idx] = {
      ...duplicateSKUs[idx],
      isChecked: !duplicateSKUs[idx]["isChecked"],
    };

    SKUsDetails({ ...SKUs, items: [...duplicateSKUs] });
  };

  const resetModal = () => {
    SKUsDetails(null);
    setLoading(false);
    setSelectedReason(false);
    setLimitedOrderDelivered(false);
    setSelectedReason(null);
    setReasons([]);
    setReturnAmount(null);
  };

  const isValid = () => SKUs?.items?.some(({ isChecked }) => isChecked);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => console.log("Modal closed")}
      modalSize={modalSize}
      onExited={resetModal}
      onEnter={fetchDeliveredOrderQty}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div className={styles.DeliveredOrderModalContainer}>
          {isLimitedOrderDelivered ? (
            <>
              {reasons?.length > 0 ? (
                <>
                  {!returnAmount && (
                    <>
                      <Typography variant="h4">
                        Reason for Partial Delivery?
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={selectedReason}
                          onChange={handlePartialDeliveryReasons}
                        >
                          {reasons?.map((reason) => (
                            <FormControlLabel
                              key={reason.id}
                              value={reason.id}
                              label={reason.name}
                              control={<Radio color="primary" />}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}

                  {returnAmount && (
                    <>
                      <Typography variant="h4">
                        Your Feedback Has Been Recorded
                      </Typography>
                      <div className={styles.NewOrderPayment}>
                        <p>New Order Payment Is: {returnAmount}</p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="h4">
                    Please mention the returned quantities against the items
                    delivered partially.
                  </Typography>
                  {SKUs?.items?.map((SKU, idx) => {
                    return (
                      <div
                        key={SKU.orderItemId}
                        className={styles.SKUItemContainer}
                      >
                        <div className={styles.ProductImgContainer}>
                          <img
                            className={styles.ProductImg}
                            alt="SKU Item"
                            src={
                              SKU?.productImage && JSON.parse(SKU?.productImage)
                                ? JSON.parse(SKU?.productImage)
                                : DastgyrLogo
                            }
                          />
                        </div>
                        <div className={styles.ProductDetails}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                ...(!SKU?.isChecked && {
                                  opacity: 0.5,
                                  pointerEvents: "none",
                                }),
                              }}
                              variant="h4"
                            >
                              {SKU?.productName ?? "--"}
                            </Typography>
                            <Checkbox
                              checked={SKU?.isChecked}
                              onChange={() => handleSelectedSKU(idx)}
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleRoundedIcon />}
                            />
                          </div>
                          <div
                            style={{
                              ...(!SKU?.isChecked && {
                                opacity: 0.5,
                                pointerEvents: "none",
                              }),
                            }}
                            className={styles.ProductActionBtns}
                          >
                            <div className={styles.QtyManageContainer}>
                              <IconButton
                                className={styles.DecrementBtn}
                                color="primary"
                                onClick={() => decrementQty(idx)}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <Typography variant="h5">
                                {SKU?.isChecked ? SKU?.quantity : 0}
                              </Typography>
                              <IconButton
                                className={styles.IncrementBtn}
                                color="primary"
                                onClick={() => incrementQty(idx)}
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <Typography variant="h4">
              Entire Order Has Been Delivered?
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions title="action">
        <></>
        {isLimitedOrderDelivered ? (
          <>
            {returnAmount && (
              <div className={styles.DeliveredOrderActionBtnContainer}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => onClose("success")}
                >
                  Okay
                </Button>
              </div>
            )}
            {!returnAmount && (
              <div className={styles.DeliveredOrderActionBtnContainer}>
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  disabled={
                    reasons?.length > 0
                      ? !selectedReason || reasonsLoading
                      : !isValid() || reasonsLoading
                  }
                  onClick={
                    reasons?.length === 0 && !selectedReason
                      ? fetchPartialDeliveryReasons
                      : returnItemsOnSpot
                  }
                >
                  Confirm
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={styles.DeliveredOrderActionBtnContainer}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={updateOrderStatus}
              disabled={deliveredOrderLoading}
            >
              Yes
            </Button>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={() => setLimitedOrderDelivered(true)}
            >
              No
            </Button>
          </div>
        )}
      </DialogActions>
    </ModalComponent>
  );
};

export default DeliveredOrderModal;
