import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";

const UpdateVisibilityModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  updateVisibilityResponse,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => console.log("Modal closed")}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div>
          <Typography variant="h5">
            The following status of the following SKUs could not be changed:
          </Typography>
          <div>
            <List>
              {updateVisibilityResponse?.map((visibilityResponse, idx) => (
                <ListItem key={idx} style={{ paddingLeft: "0px" }}>
                  <ListItemIcon>
                    <ArrowForwardIosIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h4">{visibilityResponse}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
          <Typography variant="h5">
            Please check the SKU's Quantity, Expiry Date, Live Date
          </Typography>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div style={{ textAlign: "center" }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{
              width: "50%",
              padding: "10px 0px",
              borderRadius: "16px",
            }}
          >
            OK
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default UpdateVisibilityModal;
