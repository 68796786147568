import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const dynamicStyles = (value, targetValue) => {
  return {
    root: {
      color: value >= targetValue ? "#52af77" : "#f34964",
      height: 8,
      padding: "5px 0px",
    },
    thumb: {
      height: 13,
      width: 13,
      border: "2px solid currentColor",
      marginTop: -2,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
      // color: targetValue === 100 && "#dddddd", //?
      color: value <= targetValue && "#dddddd", //?
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
      width: `${value}% !important`,
    },
    rail: {
      height: 7,
      borderRadius: 4,
      color: "#fff",
      border: "1px solid grey",
    },
  };
};

const CustomizedSlider = ({ value, targetValue }) => {
  const modifiedValue = parseFloat(value.replace("%", ""));
  const dynamicStyle = dynamicStyles(modifiedValue, targetValue);
  const PrettoSlider = withStyles(dynamicStyle)(Slider);

  return <PrettoSlider value={targetValue} />;
};

export default CustomizedSlider;
