import {
  CREATE_SELLER_PAYMENT,
  SELLER_PAYMENTS,
  MANAGE_PENDING_PAYMENTS,
  MANAGE_COMPLETED_PAYMENTS,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getApprovalPaymentStatus = (query = undefined) => {
  let URL = `api${SELLER_PAYMENTS}`;

  if (query) {
    const { startDate, endDate } = query;
    URL = `${URL}?startDate=${startDate}&endDate=${endDate}`;
  }

  return axiosInstance.get(URL);
};

export const managePendingPayments = (query = undefined) => {
  let URL = `api${MANAGE_PENDING_PAYMENTS}`;

  if (query) {
    const { fulfillmentModeStatus, startDate, endDate } = query;
    URL = `${URL}?fulfilment_mode_status=${fulfillmentModeStatus}&startDate=${startDate}&endDate=${endDate}`;
  }

  return axiosInstance.get(URL);
};

export const manageCompletedPayments = (query = undefined) => {
  let URL = `api${MANAGE_COMPLETED_PAYMENTS}`;

  if (query) {
    const { fulfillmentModeStatus, startDate, endDate } = query;
    URL = `${URL}?fulfilment_mode_status=${fulfillmentModeStatus}&startDate=${startDate}&endDate=${endDate}`;
  }

  return axiosInstance.get(URL);
};

export const createSellerPayment = (payload) =>
  axiosInstance.post(`api${CREATE_SELLER_PAYMENT}`, payload);
