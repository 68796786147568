import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography, IconButton } from "@material-ui/core";
import Loading from "../../../../../../../components/Loading";
import NotFoundMsg from "../../../../../../../components/NotFoundMsg";
import { formatDateTime } from "../../../../../../../Utilities";
import { DEDPickupReceipt } from "../../../../../../../APIs/order";
import OrderCard from "../../components/OrderCard";
import { downloadReceipt } from "./ViewPickupReceipt";
import styles from "./PickupReceipt.module.scss";

const PickupReceipt = () => {
  const [pickupReceipt, setPickupReceipt] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { profileDetails } = useSelector((state) => state.profileDetails);

  const backToOrders = () => history.push("/orders/deliver-to-dastgyr");

  const fetchPickupReceipt = () => {
    const params = {
      pickupId: id,
    };

    setLoading(true);
    DEDPickupReceipt(params)
      .then((res) => {
        if (res?.status === 200) {
          const receiptDetails = res.data.data;

          setLoading(false);
          setPickupReceipt({ ...receiptDetails });
        }
      })
      .catch((_err) => setLoading(false));
  };

  useEffect(() => {
    fetchPickupReceipt();
  }, [id]);

  return (
    <div className={styles.PickupReceiptWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToOrders}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          RECEIPT
        </Typography>
        <IconButton
          color="primary"
          aria-label="Download receipt"
          onClick={() => downloadReceipt(pickupReceipt)}
        >
          <GetAppIcon />
        </IconButton>
      </div>
      {loading && <Loading />}
      {!loading && pickupReceipt?.orderItems?.length === 0 && <NotFoundMsg />}
      {pickupReceipt && (
        <>
          <div className={styles.ShipmentInfo}>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Seller Name</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {pickupReceipt?.sellerName ?? ""}
              </Typography>
            </div>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Shipment ID</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {pickupReceipt?.pickupCode ?? ""}
              </Typography>
            </div>
            <div className={styles.AlignItems}>
              <Typography variant="h4">Shipment Date</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {formatDateTime(pickupReceipt?.pickupDate) ?? ""}
              </Typography>
            </div>
          </div>
          <div className={styles.AlignItems}>
            <Typography variant="h4">Picked-Up Orders</Typography>
            <Typography className={styles.TotalAmount} variant="h4">
              Total: {profileDetails?.currency ?? ""}{" "}
              <strong>
                {new Intl.NumberFormat("en-US").format(
                  pickupReceipt?.totalPickedAmount ?? 0
                )}
              </strong>
            </Typography>
          </div>
          <div className={styles.Orders}>
            {pickupReceipt?.orderItems?.map((order) => {
              return (
                <OrderCard
                  type="receipt"
                  key={order.productId}
                  details={order}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PickupReceipt;
