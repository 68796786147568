import WhiteFilter from "../../../assets/images/filterWhite.png";
import styles from "./Orders.module.scss";

const Orders = (props) => {
  const { totalOrders, openFilterModal, selectedFilter, homeFilter } = props;
  return (
    <>
      <div className={styles.OrdersWrapper}>
        <div className={styles.Header}>
          <p className={styles.Label}>You have</p>
          <div onClick={openFilterModal}>
            <img
              className={styles.FilterIcon}
              src={WhiteFilter}
              alt="filter-icon"
            />
          </div>
        </div>
        <p className={styles.OrderAmount}>{totalOrders}</p>
        <p className={styles.Label}>
          Total Orders of{" "}
          {selectedFilter
            ? homeFilter[
                homeFilter.findIndex((item) => item.id === selectedFilter)
              ]?.name
            : "Today"}
        </p>
      </div>
    </>
  );
};

export default Orders;
