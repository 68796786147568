import { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EventIcon from "@material-ui/icons/Event";
import { Typography, IconButton, Tabs, Tab } from "@material-ui/core";
import NewPayments from "./NewPayments";
import ApprovalStatus from "./ApprovalStatus";
import styles from "./SubmitPayments.module.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.TabPanelContainer}>{children}</div>
      )}
    </div>
  );
};

const SubmitPayments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [dateRange, setDateRange] = useState({});
  const [rangePickerVisibility, setRangePickerVisibility] = useState(false);
  const history = useHistory();

  const handleRangePicker = () => {
    setRangePickerVisibility(!rangePickerVisibility);
  };

  const backToOrders = () => history.push("/payments");
  const handleTabs = (_e, tabIndex) => setTabIndex(tabIndex);

  return (
    <div className={styles.SubmitPaymentsWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={backToOrders}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h3"} color="primary" className={styles.Title}>
          SUBMIT PAYMENTS
        </Typography>
        {tabIndex === 1 && (
          <div className={styles.DateContainer}>
            <div className={styles.DateContainer}>
              <IconButton onClick={handleRangePicker}>
                <EventIcon />
              </IconButton>
              <div className={rangePickerVisibility ? styles.overlay : ""}>
                <div className={styles.DateRangePicker}>
                  <DateRangePicker
                    open={rangePickerVisibility}
                    toggle={handleRangePicker}
                    onChange={(range) => {
                      setDateRange(range);
                      setRangePickerVisibility(!rangePickerVisibility);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <>
        <Tabs
          value={tabIndex}
          onChange={handleTabs}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="New Payments" />
          <Tab label="Approval Status" />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <NewPayments
            date={{
              startDate: moment(dateRange.startDate).format("YYYY/MM/DD"),
              endDate: moment(dateRange.endDate).format("YYYY/MM/DD"),
            }}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ApprovalStatus
            date={{
              startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
              endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
            }}
          />
        </TabPanel>
      </>
    </div>
  );
};

export default SubmitPayments;
