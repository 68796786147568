import ModalComponent, {
  DialogTitle,
  DialogContent,
} from "../../../components/Modal";
import styles from "./Modal.module.scss";
import Chip from "@material-ui/core/Chip";

const DeliveryAndTimeModal = ({
  isOpen,
  modalSize,
  modalTitle,
  closeModal,
  openDeliveryApprovalModal,
  setSelectedDeliveryCity,
  deliveryCities,
}) => {
  const handleClick = (deliveryId) => {
    setSelectedDeliveryCity(deliveryId);
    openDeliveryApprovalModal();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title" onClose={closeModal}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div>
          <p className={styles.HintMsg}>Tap on any of the cities to add City</p>
          {deliveryCities?.map((data, index) => (
            <Chip
              key={index}
              label={<div className={styles.ChipLabel}>{data.name}</div>}
              className={styles.ChipStyling}
              onClick={() => handleClick(data.id)}
            />
          ))}
          <p className={styles.InfoMsg}>
            * Selection required when no Delivery City is added
          </p>
        </div>
      </DialogContent>
    </ModalComponent>
  );
};

export default DeliveryAndTimeModal;
