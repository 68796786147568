import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AboutMe from "./AboutMe";
import StoreDetail from "./StoreDetail";
import TaxInformation from "./TaxInformation";
import BankDetail from "./BankDetail";
import AboutBusiness from "./AboutBusiness";
import BusinessContactPerson from "./BusinessContactPerson";
import SellerOption from "./SellerType";
import VerticalOption from "./VerticalType";
import TermsAndConditionModal from "./modal/TermsAndConditionModal";
import DeclineModal from "./modal/DeclineModal";
import {
  registerUser,
  getStates,
  getCities,
  getVerticals,
} from "../../APIs/auth";
import {
  userSchema,
  companySchema,
  contactPersonSchema,
  storeSchema,
  storeLocationSchema,
  bankSchema,
  taxSchema,
} from "./validationSchema";
import style from "./Profile.module.scss";

const Profile = () => {
  const [sellerType, setSellerType] = useState("1");
  const [verticalType, setVerticalType] = useState(1);
  const [user, setUser] = useState({
    name: "",
    storeName: "",
    phoneNo: "",
    password: "",
    cnic: "",
    cnicFrontPic: "",
    cnicBackPic: "",
    cnicFrontPicName: "",
    cnicBackPicName: "",
  });
  const [company, setCompany] = useState({
    companyName: "",
    regNo: "",
    password: "",
    certificatePic: "",
    certificatePicName: "",
  });
  const [store, setStore] = useState({
    province: "",
    address: "",
    city: "",
  });
  const [tax, setTax] = useState({
    ntnNo: "",
    taxNo: "",
  });
  const [bank, setBank] = useState({
    accountTitle: "",
    ibanNo: "",
  });
  const [storeLocation, setStoreLocation] = useState({
    longitude: "",
    latitude: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const history = useHistory();
  const { state } = useLocation();

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [vertical, setVertical] = useState([]);

  const [userErrors, setUserErrors] = useState({});
  const [storeErrors, setStoreErrors] = useState({});
  const [storeLocationErrors, setStoreLocationErrors] = useState({});
  const [companyErrors, setCompanyErrors] = useState({});
  const [bankErrors, setBankErrors] = useState({});
  const [taxErrors, setTaxErrors] = useState({});
  const [contactPersonErrors, setContactPersonErrors] = useState({});

  const fetchStates = () => {
    getStates().then((res) => {
      if (res.status === 200) {
        const province = res.data.data;
        setProvince(province);
      }
    });
  };

  const fetchCities = () => {
    getCities().then((res) => {
      if (res.status === 200) {
        const cities = res.data.data;
        setCity(cities);
      }
    });
  };

  const fetchVerticals = () => {
    getVerticals().then((res) => {
      if (res.status === 200) {
        const verticals = res.data.data;
        setVertical(verticals);
      }
    });
  };

  useEffect(() => {
    fetchStates();
    fetchCities();
    fetchVerticals();
  }, []);

  const validateAll = async () => {
    let userResponse;
    let companyResponse;
    let contactPersonResponse;
    let bankResponse;
    let storeResponse;
    let storeLocationResponse;
    let taxResponse;
    setUserErrors({});
    setCompanyErrors({});
    setContactPersonErrors({});
    setStoreErrors({});
    setStoreLocationErrors({});
    setBankErrors({});
    setTaxErrors({});

    if (sellerType === "1") {
      try {
        userResponse = await userSchema.validate(user, { abortEarly: false });
      } catch (userValidationErrors) {
        const newErrors = {};
        userValidationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setUserErrors(newErrors);
      }
    }

    if (sellerType === "2") {
      try {
        companyResponse = await companySchema.validate(company, {
          abortEarly: false,
        });
      } catch (companyValidationErrors) {
        const newErrors = {};
        companyValidationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setCompanyErrors(newErrors);
      }
      try {
        contactPersonResponse = await contactPersonSchema.validate(user, {
          abortEarly: false,
        });
      } catch (contactPersonValidationErrors) {
        const newErrors = {};
        contactPersonValidationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setContactPersonErrors(newErrors);
      }
    }

    try {
      storeResponse = await storeSchema.validate(store, { abortEarly: false });
    } catch (storeValidationErrors) {
      const newErrors = {};
      storeValidationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setStoreErrors(newErrors);
    }

    try {
      storeLocationResponse = await storeLocationSchema.validate(
        storeLocation,
        {
          abortEarly: false,
        }
      );
    } catch (storeLocationValidationErrors) {
      const newErrors = {};
      storeLocationValidationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setStoreLocationErrors(newErrors);
    }

    try {
      taxResponse = await taxSchema.validate(tax, { abortEarly: false });
    } catch (taxValidationErrors) {
      const newErrors = {};
      taxValidationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setTaxErrors(newErrors);
    }

    try {
      bankResponse = await bankSchema.validate(bank, { abortEarly: false });
    } catch (bankValidationErrors) {
      const newErrors = {};
      bankValidationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setBankErrors(newErrors);
    }

    if (sellerType === "1") {
      if (
        userResponse &&
        storeResponse &&
        storeLocationResponse &&
        taxResponse &&
        bankResponse
      ) {
        openModal();
      }
    }

    if (sellerType === "2") {
      if (
        companyResponse &&
        contactPersonResponse &&
        storeResponse &&
        storeLocationResponse &&
        taxResponse &&
        bankResponse
      ) {
        openModal();
      }
    }
  };

  const registerSeller = () => {
    const formData = new FormData();

    formData.append("mobileNumber", `+${state?.mobileNumber}`);
    formData.append("sellerTypeId", sellerType);
    formData.append("fullName", user.name);
    formData.append("cnic", user.cnic);

    formData.append("Latlng.latitude", storeLocation.latitude);
    formData.append("Latlng.longitude", storeLocation.longitude);

    formData.append("cnicFrontImage", user.cnicFrontPic);
    formData.append("cnicBackImage", user.cnicBackPic);

    formData.append("address", store.address);
    formData.append("cityId", 1);

    formData.append("ntnNumber", tax.ntnNo);
    formData.append("taxNumber", tax.taxNo);

    formData.append("accountTitle", bank.accountTitle);
    formData.append("ibanNumber", bank.ibanNo);

    formData.append("VerticalId", verticalType);

    //individual
    if (sellerType === "1") {
      formData.append("storeName", user.storeName);
      formData.append("password", user.password);
    }

    //business only properties
    if (sellerType === "2") {
      formData.append("pocMobile", user.phoneNo);
      formData.append("password", company.password);
      formData.append("companyName", company.companyName);
      formData.append("registrationNumber", company.regNo);
      formData.append(
        "certificateOfIncorporationImage",
        company.certificatePic
      );
    }

    setLoading(true);

    registerUser(formData)
      .then((res) => {
        if (res && res.status === 200) {
          history.push("/awaiting-approval", { from: "/signup" });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        // setIsAgree(false);
      });
  };

  const openModal = () => setIsModalOpen(true);

  const closeModal = (_e, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsModalOpen(false);
  };

  const openDeclineModal = () => setIsDeclineModalOpen(true);
  const closeDeclineModal = () => setIsDeclineModalOpen(false);
  const handleDeclineTermsAndConditions = () => history.push("/");

  return (
    <div>
      <div className={style.Container}>
        <SellerOption sellerType={sellerType} setSellerType={setSellerType} />
        {sellerType === "1" && (
          <AboutMe user={user} setUser={setUser} errors={userErrors} />
        )}
        {sellerType === "2" && (
          <AboutBusiness
            company={company}
            setCompany={setCompany}
            errors={companyErrors}
          />
        )}
        {sellerType === "2" && (
          <BusinessContactPerson
            user={user}
            setUser={setUser}
            errors={contactPersonErrors}
          />
        )}
        <StoreDetail
          province={province}
          city={city}
          store={store}
          setStore={setStore}
          storeLocation={storeLocation}
          setStoreLocation={setStoreLocation}
          errors={{ ...storeErrors, ...storeLocationErrors }}
        />
        <TaxInformation tax={tax} setTax={setTax} errors={taxErrors} />
        <BankDetail bank={bank} setBank={setBank} errors={bankErrors} />
        <VerticalOption
          vertical={vertical}
          verticalType={verticalType}
          setVerticalType={setVerticalType}
          // errors={errors}
        />
      </div>
      <div className={style.ButtonContainer}>
        <Button
          className={style.SubmitButton}
          variant="contained"
          color="primary"
          // onClick={openModal}
          onClick={validateAll}
        >
          Next
        </Button>
      </div>
      <TermsAndConditionModal
        isOpen={isModalOpen}
        modalSize="lg"
        modalTitle="Terms & Conditions"
        isAgree={isAgree}
        setIsAgree={setIsAgree}
        closeModal={closeModal}
        onSubmit={registerSeller}
        openDeclineModal={openDeclineModal}
        loading={loading}
      />
      <DeclineModal
        modalTitle=""
        modalSize="sm"
        isOpen={isDeclineModalOpen}
        closeDeclineModal={closeDeclineModal}
        handleDeclineTermsAndConditions={handleDeclineTermsAndConditions}
      />
    </div>
  );
};

export default Profile;
