import TextField from "@material-ui/core/TextField";
import style from "../Profile.module.scss";

const TaxInformation = (props) => {
  const { tax, setTax, errors } = props;

  const handleChange = (type, value) => {
    setTax((previousTax) => {
      return {
        ...previousTax,
        [type]: value,
      };
    });
  };
  return (
    <div className={style.SectionContainer}>
      <h1 className={style.FormHeading}>Tax Information</h1>
      <form className={style.FormContainer} noValidate autoComplete="off">
        <TextField
          className={style.TextField}
          name="ntnNo"
          value={tax.ntnNo}
          label="NTN Number"
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.ntnNo)}
          helperText={errors && errors.ntnNo}
        />
        <TextField
          required
          className={style.TextField}
          name="taxNo"
          value={tax.taxNo}
          label="Tax Number"
          variant="outlined"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          error={Boolean(errors && errors.taxNo)}
          helperText={errors && errors.taxNo}
        />
      </form>
    </div>
  );
};

export default TaxInformation;
