import {
  Typography,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../../../components/Pagination";
import NotFoundMsg from "../../../../components/NotFoundMsg";
import Loading from "../../../../components/Loading";
import SKUItemCard from "../SKUItemCard";
import styles from "./ActiveListing.module.scss";

const HiddenListing = ({
  hiddenProductSKUs,
  loading,
  selectedSKUs,
  selectedLists,
  handleSKUVisibility,
  handleMultipleSKUVisibilities,
  openSKUsVisibilityModal,
  displayListingPage,
  displayListingPerPage,
  handlePreviousPage,
  handleNextPage,
  handleRowsPerPageChange,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <>
      <div className={styles.SearchContainer}>
        <TextField
          value={searchTerm}
          placeholder={"Search SKU"}
          variant="outlined"
          className={styles.SearchField}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={styles.Header}>
        <Typography variant="h4">
          {hiddenProductSKUs?.length ?? 0} Listed SKUs
        </Typography>
        <div className={styles.ActionBtnsContainer}>
          {hiddenProductSKUs?.length > 0 && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.StyleCheckbox}
                    color="secondary"
                    checked={
                      selectedSKUs ||
                      hiddenProductSKUs.some(({ isSelected }) => isSelected)
                    }
                    onChange={(e) => handleMultipleSKUVisibilities(e)}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                  />
                }
                label="Manage SKU Visibility"
              />
            </FormGroup>
          )}
          {(selectedSKUs ||
            hiddenProductSKUs.some(({ isSelected }) => isSelected)) && (
            <Button
              color="secondary"
              variant="contained"
              className={styles.VisibilityManageBtn}
              onClick={openSKUsVisibilityModal}
            >
              Show SKU(s)
            </Button>
          )}
        </div>
      </div>
      {!loading && hiddenProductSKUs?.length === 0 && (
        <NotFoundMsg variant={"h4"} />
      )}
      {loading && <Loading />}
      <div className={styles.SKUItemCardContainer}>
        {hiddenProductSKUs?.map((SKURequest, idx) => (
          <SKUItemCard
            idx={idx}
            selectedLists={selectedLists}
            listingType="active"
            key={SKURequest.skuId}
            SKUDetails={SKURequest}
            handleSKUVisibility={handleSKUVisibility}
          />
        ))}
      </div>
      <Pagination
        label="SKUs per page"
        page={displayListingPage}
        pageSize={displayListingPerPage}
        handlePreviousPage={() => handlePreviousPage("hidden")}
        handleNextPage={() => handleNextPage("hidden")}
        handleRowsPerPageChange={(e) => handleRowsPerPageChange(e, "hidden")}
        count={hiddenProductSKUs?.length}
      />
    </>
  );
};

export default HiddenListing;
