import {
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Pagination from "../../../../components/Pagination";
import NotFoundMsg from "../../../../components/NotFoundMsg";
import Loading from "../../../../components/Loading";
import SKUItemCard from "../SKUItemCard";
import styles from "./PendingListing.module.scss";

const NewListing = ({
  SKURequests,
  selectedSKUs,
  loading,
  deleteMultipleSKUs,
  deleteSingleSKU,
  selectedLists,
  openDeleteSKUModal,
  displayListingPage,
  displayListingPerPage,
  handlePreviousPage,
  handleNextPage,
  handleRowsPerPageChange,
}) => {
  return (
    <>
      <div className={styles.Header}>
        <Typography variant="h4">
          {SKURequests?.length ?? 0} Listed SKUs
        </Typography>
        <div className={styles.ActionBtnsContainer}>
          {SKURequests?.length > 0 && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.StyleCheckbox}
                    color="secondary"
                    checked={
                      selectedSKUs ||
                      SKURequests?.some(({ isSelected }) => isSelected)
                    }
                    onChange={(e) => deleteMultipleSKUs(e)}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleRoundedIcon />}
                  />
                }
                label="Delete all SKUs"
              />
            </FormGroup>
          )}
          {(selectedSKUs ||
            SKURequests?.some(({ isSelected }) => isSelected)) && (
            <IconButton
              color="secondary"
              className={styles.DeleteSKU}
              onClick={openDeleteSKUModal}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          )}
        </div>
      </div>
      {!loading && SKURequests?.length === 0 && <NotFoundMsg variant={"h4"} />}
      {loading && <Loading />}
      <div className={styles.SKUItemCardContainer}>
        {SKURequests?.map((SKURequest, idx) => {
          return (
            <SKUItemCard
              key={SKURequest.skuId}
              idx={idx}
              listingType="pending"
              deleteSKU={deleteSingleSKU}
              SKUDetails={SKURequest}
              selectedLists={selectedLists}
            />
          );
        })}
      </div>
      <Pagination
        label="SKUs per page"
        page={displayListingPage}
        pageSize={displayListingPerPage}
        handlePreviousPage={() => handlePreviousPage("newListing")}
        handleNextPage={() => handleNextPage("newListing")}
        handleRowsPerPageChange={(e) =>
          handleRowsPerPageChange(e, "newListing")
        }
        count={SKURequests?.length}
      />
    </>
  );
};

export default NewListing;
