const local = {
  baseURL: process.env.REACT_APP_BASE_URL_LOCAL,
  tradeURL: process.env.REACT_APP_TRADE_URL_LOCAL,
  logisticURL: process.env.REACT_APP_LOGISTIC_URL_LOCAL,
  growthURL: process.env.REACT_APP_GROWTH_URL_LOCAL,
  backofficeURL: process.env.REACT_APP_BACK_OFFICE_LOCAL,
};

const qa = {
  baseURL: process.env.REACT_APP_BASE_URL_QA,
  tradeURL: process.env.REACT_APP_TRADE_URL_QA,
  logisticURL: process.env.REACT_APP_LOGISTIC_URL_QA,
  growthURL: process.env.REACT_APP_GROWTH_URL_QA,
  backofficeURL: process.env.REACT_APP_BACK_OFFICE_QA,
};

const staging = {
  baseURL: process.env.REACT_APP_BASE_URL_STAGING,
  tradeURL: process.env.REACT_APP_TRADE_URL_STAGING,
  logisticURL: process.env.REACT_APP_LOGISTIC_URL_STAGING,
  growthURL: process.env.REACT_APP_GROWTH_URL_STAGING,
  backofficeURL: process.env.REACT_APP_BACK_OFFICE_STAGING,
};

const prod = {
  baseURL: process.env.REACT_APP_BASE_URL_PROD,
  tradeURL: process.env.REACT_APP_TRADE_URL_PROD,
  logisticURL: process.env.REACT_APP_LOGISTIC_URL_PROD,
  growthURL: process.env.REACT_APP_GROWTH_URL_PROD,
  backofficeURL: process.env.REACT_APP_BACK_OFFICE_PROD,
};

let environment = local;

switch (process.env.REACT_APP_STAGE) {
  case "local":
    environment = local;
    break;
  case "qa":
    environment = qa;
    break;
  case "staging":
    environment = staging;
    break;
  case "prod":
    environment = prod;
    break;
  default:
    environment = local;
}

const configurations = { ...environment };

export default configurations;
