import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import styles from "./Modal.module.scss";

const DeliveryCityRemovalModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  addDeliveryCity,
  removalLoading,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => console.log("Modal closed")}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title" onClose={onClose}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.HideSKUsModalContainer}>
          <p className={styles.HideSKUsAskingMsg}>
            The selected city will be removed. Do you want to continue?
          </p>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.HideSKUsActionBtnContainer}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={removalLoading}
            onClick={addDeliveryCity}
          >
            Yes
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default DeliveryCityRemovalModal;
