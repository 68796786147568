import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: {
    open: false,
    message: "",
    hideDuration: 0,
    severity: "",
  },
};

export const snackbarSlice = createSlice({
  name: "snackbarDetails",
  initialState,
  reducers: {
    setSnackbarDetails: (state, action) => {
      state.details = action.payload;
    },
    removeSnackbarDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const { setSnackbarDetails, removeSnackbarDetails } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
