import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/styles";
import { createBrowserHistory } from "history";

import App from "./App";
import theme from "./theme";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const persistor = persistStore(store);
const browserHistory = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <StrictMode>
            <App />
          </StrictMode>
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
