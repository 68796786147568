import { Typography, IconButton, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DastgyrLogo from "../../../../assets/images/dastgyr-logo.png";
import { validateNumericWithOnePrecision } from "../../../../Utilities";
import styles from "./SKUItem.module.scss";

const SKUItem = (props) => {
  const {
    qty,
    setQty,
    selectedSKU,
    setSelectedSKU,
    setSKUPrice,
    setSKUMinPrice,
    resetVariablePricings,
  } = props;

  const incrementQty = () => {
    if (selectedSKU?.baseUomId === 1) {
      setQty((qty) => Number(qty) + 1);
    } else {
      setQty((qty) => Number(Number(qty) + 1).toFixed(1));
    }
  };

  const decrementQty = () => {
    if (qty > 1) {
      if (selectedSKU?.baseUomId === 1) {
        setQty((qty) => qty - 1);
      } else {
        setQty((qty) => Number(qty - 1).toFixed(1));
      }
    }
  };

  const handleChange = (value) => {
    if (selectedSKU?.baseUomId === 1) {
      const intValue = value.replace(/[^0-9]/g, "");
      setQty(intValue);
    } else {
      if (!value || validateNumericWithOnePrecision(value)) {
        setQty(value);
      }
    }
  };

  return (
    <div className={styles.SKUItemContainer}>
      <div className={styles.ProductImgContainer}>
        <img
          className={styles.ProductImg}
          alt="SKU Item"
          src={selectedSKU?.image ? selectedSKU?.image : DastgyrLogo}
        />
      </div>
      <div className={styles.ProductDetails}>
        <Typography variant="h2">{selectedSKU?.name ?? ""}</Typography>
        <div className={styles.ProductActionBtns}>
          <div className={styles.QtyManageContainer}>
            <IconButton
              className={styles.DecrementBtn}
              color="primary"
              onClick={decrementQty}
            >
              <RemoveIcon />
            </IconButton>
            <TextField
              required
              className={styles.QtyTextField}
              data-sku-qty-field
              type="number"
              name="qty"
              value={qty}
              variant="outlined"
              inputProps={{ maxLength: 5 }}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={() => {
                if (!qty) setQty(1);
              }}
            />
            <IconButton
              className={styles.IncrementBtn}
              color="primary"
              onClick={incrementQty}
            >
              <AddIcon />
            </IconButton>
          </div>
          <IconButton
            className={styles.RemoveSKU}
            onClick={() => {
              setQty(1);
              setSelectedSKU(null);
              setSKUPrice("");
              setSKUMinPrice(null);
              resetVariablePricings();
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default SKUItem;
