import styles from "./Complaints.module.scss";

const Complaints = () => {
  return (
    <div className={styles.ComplaintsWrapper}>
      <p className={styles.Headline}>Complaints</p>
      <div className={styles.Section}>
        <p className={styles.Returns}>0 Returns,</p>
        <p className={styles.Claims}>0 Payment Claim</p>
      </div>
    </div>
  );
};

export default Complaints;
