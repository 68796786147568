import { useHistory } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./Shipment.module.scss";

const ShipmentCard = ({ date, shipmentDetails }) => {
  const { returnId, returnCode, driverName, handshakeInProcess } =
    shipmentDetails;
  const history = useHistory();

  const goTo = () => {
    const path = `/order/shipment/${returnCode}/return-skus`;
    history.push(path, { returnId, date });
  };

  return (
    <div className={styles.ShipmentCard}>
      <div className={styles.Header}>
        <div>
          <Typography variant="h3">Shipment ID:</Typography>
          <Typography className={styles.ShipmentID} variant="h1">
            {returnCode ?? ""}
          </Typography>
        </div>
        <IconButton aria-label="Close" onClick={goTo}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      {handshakeInProcess ? (
        <div className={styles.ActivedHandshake}>
          <Typography variant="h5" className={styles.Active}>
            <span style={{ color: "#ff6e43" }}>
              Return process has started.
            </span>
            <span>Tap the arrow to confirm the returned quantities.</span>
          </Typography>
        </div>
      ) : (
        <div className={styles.InactiveHandshake}>
          <Typography variant="h5" className={styles.Inactive}>
            Please share this code with Driver
          </Typography>
          <Typography className={styles.DriverName} variant="h5">
            {driverName}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ShipmentCard;
