import {
  GET_PRODUCTS,
  GET_SKU_MIN_PRICE,
  ADD_PRODUCT_SKU_USER,
  GET_ALL_PRODUCT_SKU_USERS,
  GET_SKU_REQUEST,
  GET_SKU_CHANGE_REQUEST,
  UPDATE_SKU_VISIBILITY,
  GET_DELIVERY_CITIES,
  SELLER_DELIVERY_CITIES,
  SELLER_CHANGE_REQUEST,
  GET_APPLICATION_VERSION,
  GET_FULFILLMENT_MODE,
  VALIDATE_SKU_LISTING,
  CREATE_BULK_TEMPLATE,
  GET_SELLER_DELETE_SKUs,
  GET_SELLER_EDIT_SKUs,
  VALIDATE_LOCATION,
} from "../Utilities/endpoints";
import { getQuery } from "../Utilities/index";
import axiosInstance from "../app-interceptor/interceptor";

export const getProducts = (query = undefined) => {
  let URL = `api${GET_PRODUCTS}`;

  if (query) {
    URL = `${URL}?productName=${query}`;
  }

  return axiosInstance.get(URL);
};

export const getSKUMinPrice = (params = undefined) =>
  axiosInstance.get(`api${GET_SKU_MIN_PRICE}`, { params });

export const validateSellerLocation = () =>
  axiosInstance.get(`api${VALIDATE_LOCATION}`);

export const addProductSKU = (payload) =>
  axiosInstance.post(`api${ADD_PRODUCT_SKU_USER}`, payload);

export const getActiveProductSKUUsers = (query = undefined) => {
  let URL = `api${GET_ALL_PRODUCT_SKU_USERS}`;

  if (query) {
    const queryParams = getQuery(query);
    URL = `${URL}${queryParams}`;
  }

  return axiosInstance.get(URL);
};

export const getInactiveProductSKUUsers = (query = undefined) => {
  let URL = `api${GET_ALL_PRODUCT_SKU_USERS}`;

  if (query) {
    const queryParams = getQuery(query);
    URL = `${URL}${queryParams}`;
  }

  return axiosInstance.get(URL);
};

export const getSKURequest = (query = undefined) => {
  let URL = `api${GET_SKU_REQUEST}`;

  if (query) {
    const queryParams = getQuery(query);
    URL = `${URL}${queryParams}`;
  }

  return axiosInstance.get(URL);
};

export const getSKUChangeRequest = (query = undefined) => {
  let URL = `api${GET_SKU_CHANGE_REQUEST}`;

  if (query) {
    const queryParams = getQuery(query);
    URL = `${URL}${queryParams}`;
  }

  return axiosInstance.get(URL);
};

export const updateSKUVisibility = (payload) =>
  axiosInstance.put(`api${UPDATE_SKU_VISIBILITY}`, payload);

export const deleteSKU = (URL, payload) => axiosInstance.put(URL, payload);

export const getDeliveryCities = () =>
  axiosInstance.get(`api${GET_DELIVERY_CITIES}`);

export const getSellerDeliveryCities = () =>
  axiosInstance.get(`api${SELLER_DELIVERY_CITIES}`);

export const addSellerDeliveryCities = (payload) =>
  axiosInstance.post(`api${SELLER_DELIVERY_CITIES}`, payload);

export const sellerChangeRequest = (payload) =>
  axiosInstance.put(`api${SELLER_CHANGE_REQUEST}`, payload);

export const getApplicationVersion = () =>
  axiosInstance.get(`api${GET_APPLICATION_VERSION}`);

export const getFulfillmentMode = () =>
  axiosInstance.get(`api${GET_FULFILLMENT_MODE}`);

export const createBulkTemplate = () =>
  axiosInstance.get(`api${CREATE_BULK_TEMPLATE}`);

export const getSellerDeleteSKUs = () =>
  axiosInstance.get(`api${GET_SELLER_DELETE_SKUs}`);

export const getSellerEditSKUs = () =>
  axiosInstance.get(`api${GET_SELLER_EDIT_SKUs}`);
