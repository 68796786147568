import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import styles from "./modal.module.scss";

const TermsAndConditionModal = ({
  isOpen,
  modalSize,
  isAgree,
  setIsAgree,
  closeModal,
  openDeclineModal,
  modalTitle,
  loading,
  onSubmit,
}) => {
  const handleAgreement = (e) => setIsAgree(e.target.checked);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={modalSize}
      onExited={() => console.log("Reset Form")}
      onEnter={() => console.log("Initialize State")}
    >
      <DialogTitle title="title" onClose={closeModal}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body"></DialogContent>
      <DialogActions title="action">
        <div className={styles.TermsAndConditionsModalContainer}>
          <div className={styles.TermsAndConditions}>
            <iframe
              className={styles.Iframe}
              src={
                "https://dastgyr-terms-conditions.s3.ap-southeast-1.amazonaws.com/SupplierTermsFinalRevised.docx.html"
              }
              title="Terms and Conditions"
            />
          </div>
          <div className={styles.HandleAgreementContainer}>
            <Checkbox checked={isAgree} onChange={handleAgreement} />
            <p className={styles.AgreementLabel}>
              I agree to all the terms and conditions
            </p>
          </div>
          <div className={styles.AgreementActionBtnContainer}>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={openDeclineModal}
            >
              Decline
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              disabled={!isAgree || loading}
              onClick={onSubmit}
            >
              Accept
            </Button>
          </div>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default TermsAndConditionModal;
