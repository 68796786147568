import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { createBrowserHistory } from "history";
import { Topbar } from "./layouts/Main/components";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import FraudDetection from "./pages/FraudDetection";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import ManageSellerProfile from "./pages/ManageSellerProfile";
import PerformanceBadges from "./pages/PerformanceBadges";
import Listing from "./pages/Listing";
import ManageListing from "./pages/Listing/ManageListing";
import EditListing from "./pages/Listing/ManageListing/EditListing";
import Orders from "./pages/Orders";
import ManagePayments from "./pages/ManagePayments";
import ViewPayments from "./pages/ManagePayments/ViewPayments";
import SubmitPayments from "./pages/ManagePayments/SubmitPayments";
import SellerInvoices from "./pages/SellerInvoices";
import NotFoundPage from "./pages/NotFoundPage";
import AwaitingApproval from "./pages/AwaitingApproval";
import DeliverToCustomer from "./pages/Orders/ManageOrders/DeliveredTo/Customer";
import DeliverToDastgyr from "./pages/Orders/ManageOrders/DeliveredTo/Dastgyr";
import ShipmentDetails from "./pages/Orders/ManageOrders/DeliveredTo/Dastgyr/ReceivedOrders/ShipmentDetails";
import ShipmentReturnDetails from "./pages/Orders/ManageOrders/DeliveredTo/Dastgyr/ReceivedOrders/ReturnOrders/pages/ShipmentReturnDetails";
import PickupReceipt from "./pages/Orders/ManageOrders/DeliveredTo/Dastgyr/ReceivedOrders/PickupReceipt";
import ReturnReceipt from "./pages/Orders/ManageOrders/DeliveredTo/Dastgyr/ReceivedOrders/ReturnOrders/pages/ReturnReceipt";
import DeliveryAreasAndTimes from "./pages/DeliveryAreas&Times";
import DeliveryTimes from "./pages/DeliveryAreas&Times/DeliveryTimes";
import DeliveryCities from "./pages/DeliveryAreas&Times/DeliveryCities";
import DeliveryAreas from "./pages/DeliveryAreas&Times/DeliveryAreas";
import Returns from "./pages/Returns";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import LayoutWithDrawer from "./LayoutWithDrawer";

const browserHistory = createBrowserHistory();
const queryClient = new QueryClient();

function App() {
  const [isSidebarOpen, setOpenSidebar] = useState(false);

  const openSidebar = () => setOpenSidebar(true);
  const closeSidebar = () => setOpenSidebar(false);

  return (
    <QueryClientProvider client={queryClient}>
      <Topbar history={browserHistory} onSidebarOpen={openSidebar} />
      <Switch>
        <PublicRoutes exact path="/" component={Home} />
        <PublicRoutes exact path="/login" component={Login} />
        <PublicRoutes exact path="/signup" component={Signup} />
        <PublicRoutes exact path="/profile" component={Profile} />
        <PublicRoutes
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <PublicRoutes exact path="/new-password" component={NewPassword} />
        <PublicRoutes
          exact
          path="/account-suspension"
          component={FraudDetection}
        />
        <PublicRoutes
          exact
          path="/awaiting-approval"
          component={AwaitingApproval}
        />

        {/* Protected Routes */}
        <LayoutWithDrawer onClose={closeSidebar} isSidebarOpen={isSidebarOpen}>
          <Switch>
            <ProtectedRoutes path="/dashboard" component={Dashboard} />
            <ProtectedRoutes
              path="/manage-profile"
              component={ManageSellerProfile}
            />
            <ProtectedRoutes
              path="/seller-performance-badges"
              component={PerformanceBadges}
            />
            <ProtectedRoutes exact path="/listing" component={Listing} />
            <ProtectedRoutes
              path="/listing/manage-listing"
              component={ManageListing}
            />
            <ProtectedRoutes
              path="/listing/edit-listing"
              component={EditListing}
            />
            <ProtectedRoutes exact path="/orders" component={Orders} />
            <ProtectedRoutes
              path="/orders/deliver-to-customer"
              component={DeliverToCustomer}
            />
            <ProtectedRoutes
              path="/orders/deliver-to-dastgyr"
              component={DeliverToDastgyr}
            />
            <ProtectedRoutes
              path="/order/shipment/:id/details"
              component={ShipmentDetails}
            />
            <ProtectedRoutes
              path="/order/shipment/:id/return-skus"
              component={ShipmentReturnDetails}
            />
            <ProtectedRoutes
              path="/order/shipment/:id/receipt"
              component={PickupReceipt}
            />
            <ProtectedRoutes
              path="/order/shipment/:id/return-receipt"
              component={ReturnReceipt}
            />
            <ProtectedRoutes
              exact
              path="/payments"
              component={ManagePayments}
            />
            <ProtectedRoutes
              path="/payments/view-payments-status"
              component={ViewPayments}
            />
            <ProtectedRoutes
              path="/payments/submit-payments"
              component={SubmitPayments}
            />
            <ProtectedRoutes
              path="/seller-invoices"
              component={SellerInvoices}
            />
            <ProtectedRoutes
              path="/delivery/areas-times"
              component={DeliveryAreasAndTimes}
            />
            <ProtectedRoutes
              path="/delivery/delivery-cities"
              component={DeliveryCities}
            />
            <ProtectedRoutes
              path="/delivery/city/:cityId/areas"
              component={DeliveryAreas}
            />
            <ProtectedRoutes
              path="/delivery/delivery-times"
              component={DeliveryTimes}
            />
            <ProtectedRoutes
              path="/returns/i-will-deliver"
              component={Returns}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </LayoutWithDrawer>
      </Switch>
    </QueryClientProvider>
  );
}

export default App;
