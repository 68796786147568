import { useSelector } from "react-redux";
import { Divider, Typography, Button } from "@material-ui/core";
import DastgyrLogo from "../../../../../../../assets/images/dastgyr-logo.png";
import { truncate } from "../../../../../../../Utilities";
import styles from "./OrderPickedUpCard.module.scss";

const OrderPickedUpCard = ({ trackingStatus, details }) => {
  const { profileDetails } = useSelector((state) => state.profileDetails);

  return (
    <div className={styles.SKUItemContainer}>
      <div className={styles.SKUItem}>
        <div className={styles.PrimaryContainer}>
          <div className={styles.ProductImgContainer}>
            <img
              className={styles.ProductImg}
              alt="SKU Item"
              src={
                details?.productImage && JSON.parse(details?.productImage)
                  ? JSON.parse(details?.productImage)
                  : DastgyrLogo
              }
            />
          </div>
        </div>

        <div className={styles.SecondaryContainer}>
          <div className={styles.ProductDetails}>
            <Typography
              className={styles.ProductLabel}
              variant="h6"
              title={details?.productName ?? ""}
            >
              {truncate(details?.productName, 30)}
            </Typography>
            {details.mrp && (
              <div className={styles.Badge}>
                MRP: {profileDetails?.currency ?? ""}. {details?.mrp ?? 0}
              </div>
            )}
          </div>
          <div className={styles.QtyContainer}>
            <div>
              <Typography color="secondary" variant="h5">
                Ordered Qty: {details?.quantity ?? 0}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {[2, 3].includes(trackingStatus) && (
        <>
          <Divider />
          <div className={styles.Footer}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              PICKED UP QUANTITY
            </Typography>
            <Button
              className={styles.PickupBtn}
              variant="outlined"
              onClick={() => {}}
            >
              {trackingStatus === 2 && "PICKUP IN PROGRESS"}
              {trackingStatus === 3 && details?.pickedQuantity}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderPickedUpCard;
