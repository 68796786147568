import {
  TOGGLE_HEXAGONS,
  GET_AREA_LEAD_TIMES,
  ADD_SELLER_LEAD_TIMES_AREA,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getAreaLeadTimes = (params = undefined) =>
  axiosInstance.get(`api${GET_AREA_LEAD_TIMES}`, { params });

export const addSellerLeadTimesArea = (payload) =>
  axiosInstance.post(`api${ADD_SELLER_LEAD_TIMES_AREA}`, payload);

export const toggleHexagons = (payload) =>
  axiosInstance.post(`api${TOGGLE_HEXAGONS}`, payload);
