import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: null,
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    removeUserDetails: (state) => {
      state.userDetails = null;
    },
  },
});

export const { setUserDetails, removeUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
