import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Typography,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loading from "../../../components/Loading";
import Places from "../../../assets/images/places.png";
import {
  getSellerDeliveryCities,
  getDeliveryCities,
  addSellerDeliveryCities,
  removeSellerDeliveryCities,
} from "../../../APIs/delivery-city-time";
import DeliveryCityModal from "../Modal/DeliveryCityModal";
import DeliveryCityApprovalModal from "../Modal/DeliveryCityApprovalModal";
import DeliveryCityRemovalModal from "../Modal/DeliveryCityRemovalModal";
import styles from "../Areas&Times.module.scss";

const DeliveryCities = () => {
  const [selectedDeliveryCity, setSelectedDeliveryCity] = useState();
  const [isDeliveryApprovalModalOpen, setIsDeliveryApprovalModalOpen] =
    useState(false);
  const [isDeliveryRemovalModalOpen, setIsDeliveryRemovalModalOpen] =
    useState(false);
  const [isDeliveryCityModalOpen, setIsDeliveryCityModalOpen] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();

  const goTo = (path) => history.push(path);

  const openDeliveryApprovalModal = () => setIsDeliveryApprovalModalOpen(true);
  const closeDeliveryApprovalModal = () =>
    setIsDeliveryApprovalModalOpen(false);

  const openDeliveryRemovalModal = () => setIsDeliveryRemovalModalOpen(true);
  const closeDeliveryRemovalModal = () => setIsDeliveryRemovalModalOpen(false);

  const openDeliveryCityModal = () => setIsDeliveryCityModalOpen(true);
  const closeDeliveryCityModal = () => setIsDeliveryCityModalOpen(false);

  // Fetch cities
  const fetchCities = async () => await getDeliveryCities();
  const { data: deliveryCities } = useQuery("delivery-cities", fetchCities, {
    select: (res) => res.data,
  });

  // Fetch delivery cities
  const fetchDeliveryCities = async () => await getSellerDeliveryCities();
  const { data: sellerDeliveryCities, isLoading: loading } = useQuery(
    "seller-delivery-cities",
    fetchDeliveryCities
  );

  // Add delivery cities
  const { mutate: addCity, isLoading: approvalLoading } = useMutation(
    addSellerDeliveryCities,
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          closeDeliveryApprovalModal();
          closeDeliveryCityModal();
          queryClient.invalidateQueries("seller-delivery-cities");
        }
      },
      onError: (error) => {
        closeDeliveryApprovalModal();
        closeDeliveryCityModal();
      },
    }
  );

  const manageDeliveryCityAddition = () => {
    const payload = { cityId: selectedDeliveryCity };
    addCity(payload);
  };

  // Remove delivery cities
  const { mutate: removeCity, isLoading: removalLoading } = useMutation(
    removeSellerDeliveryCities,
    {
      onSuccess: (res) => {
        if (res.status === 204) {
          closeDeliveryRemovalModal();
          closeDeliveryCityModal();
          queryClient.invalidateQueries("seller-delivery-cities");
        }
      },
      onError: (error) => {
        closeDeliveryRemovalModal();
        closeDeliveryCityModal();
      },
    }
  );

  const manageDeliveryCityRemoval = () => {
    const query = `${encodeURIComponent(selectedDeliveryCity)}`;
    removeCity(query);
  };

  // Add areas
  const addAreas = (id, cityId, days, latLng) => {
    const path = `/delivery/city/${cityId}/areas`;
    const sendDetails = { days, latLng, leadTimeId: id };

    history.push(path, sendDetails);
  };

  return (
    <div className={styles.DeliveryAreaAndTimeWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/delivery/areas-times")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          DELIVERY AREAS
        </Typography>
      </div>
      <div className={styles.Section}>
        <div className={styles.InnerSection}>
          <div className={styles.UpperSection}>
            <p className={styles.SectionHeading}>
              {sellerDeliveryCities?.data?.length > 0
                ? "Cities where you will Deliver"
                : "Choose cities and areas where you will Deliver"}
            </p>
            {loading && <Loading />}
            {!loading && !sellerDeliveryCities?.data?.length > 0 && (
              <div className={styles.MiddleSection}>
                <div className={styles.NoCitySection}>
                  <div>
                    <img
                      alt="delivery-place"
                      src={Places}
                      className={styles.SectionImg}
                    />
                  </div>
                  <p className={styles.SectionHeading}>No Cities Added</p>
                  <p className={styles.SectionText}>
                    Please add the cities you will deliver your product to
                  </p>
                </div>
              </div>
            )}
            <List>
              {sellerDeliveryCities?.data?.map(
                ({ id, cityId, name, days, latLng }) => (
                  <div className={styles.CityListItem} key={cityId}>
                    <ListItem>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h4" color="primary">
                          {name}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          onClick={() => addAreas(id, cityId, days, latLng)}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Edit Areas on Map
                        </Typography>
                      </div>
                    </ListItem>
                    <Divider />
                  </div>
                )
              )}
            </List>
            <div style={{ textAlign: "right" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                onClick={openDeliveryCityModal}
              >
                Manage Cities
              </Button>
            </div>
          </div>
          <div className={styles.ShadedSection}>
            <div className={styles.InstructionSection}>
              <p className={styles.SectionHeading}>How to Add Cities</p>
              <p className={styles.SectionText}>
                1. Tap on the <strong>Add City</strong> and select cities you
                will deliver to
              </p>
              <p className={styles.SectionText}>
                2. Tap on the <strong>Add Area</strong> button in each city to
                select areas you will deliver to
              </p>
            </div>
            {sellerDeliveryCities?.data?.length > 0 && (
              <div className={styles.DeliveryTimeBtn}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => goTo("/delivery/delivery-times")}
                  className={styles.Button}
                >
                  <div className={styles.ButtonText}>
                    <div>
                      Set Delivery Times - {sellerDeliveryCities?.data?.length}{" "}
                      City Added
                    </div>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeliveryCityModal
        isOpen={isDeliveryCityModalOpen}
        modalSize="md"
        modalTitle="Select Delivery City"
        deliveryCities={deliveryCities}
        setSelectedDeliveryCity={setSelectedDeliveryCity}
        sellerDeliveryCities={sellerDeliveryCities?.data ?? []}
        closeModal={closeDeliveryCityModal}
        openDeliveryApprovalModal={openDeliveryApprovalModal}
        openDeliveryRemovalModal={openDeliveryRemovalModal}
      />
      <DeliveryCityApprovalModal
        modalSize="sm"
        modalTitle="Add City"
        addDeliveryCity={manageDeliveryCityAddition}
        selectedDeliveryCity={selectedDeliveryCity}
        onClose={closeDeliveryApprovalModal}
        isOpen={isDeliveryApprovalModalOpen}
        approvalLoading={approvalLoading}
      />
      <DeliveryCityRemovalModal
        modalSize="sm"
        modalTitle="Remove City"
        addDeliveryCity={manageDeliveryCityRemoval}
        selectedDeliveryCity={selectedDeliveryCity}
        onClose={closeDeliveryRemovalModal}
        isOpen={isDeliveryRemovalModalOpen}
        removalLoading={removalLoading}
      />
    </div>
  );
};

export default DeliveryCities;
