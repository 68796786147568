import {
  GET_ORDER_STATISTIC,
  GET_RECEIVED_ORDERS,
  GET_PAST_ORDERS,
  IS_ON_SPOT_ENABLE,
  UPDATE_ORDER_STATUS,
  CANCELLATION_REASONS,
  CANCEL_ORDER,
  Delivered_ORDER_QUANTITY_DETAIL,
  PARTIAL_DELIVERY_REASONS,
  ON_SPOT_RETURN_ITEM,
  DED_NEW_ORDERS,
  DED_PICKUP_ORDERS,
  DED_PAST_ORDERS,
  DED_RETURN_ORDERS,
  DED_PICKED_UP_SKUs,
  DED_PICKUP_RECEIPT,
  DED_RETURN_SKUs,
  DED_RETURN_RECEIPT,
  DED_PICKUP_SKUs,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getOrderStatistic = () =>
  axiosInstance.get(`api${GET_ORDER_STATISTIC}`);

export const getReceivedOrders = (params = undefined) =>
  axiosInstance.get(`api${GET_RECEIVED_ORDERS}`, { params });

export const getPastOrders = (params = undefined) =>
  axiosInstance.get(`api${GET_PAST_ORDERS}`, { params });

export const isOnSpotEnable = () =>
  axiosInstance.get(`api${IS_ON_SPOT_ENABLE}`);

export const updateOrderStatus = (query = undefined) => {
  let URL = `api${UPDATE_ORDER_STATUS}`;

  if (query) {
    const { status, orderId } = query;
    URL = `${URL}?status=${status}&orderId=${orderId}`;
  }

  return axiosInstance.put(URL);
};

export const cancellationReasons = (query = undefined) => {
  let URL = `api${CANCELLATION_REASONS}`;

  if (query) {
    const { reportReason } = query;
    URL = `${URL}?reportReason=${reportReason}`;
  }

  return axiosInstance.get(URL);
};

export const cancelOrder = (query = undefined) => {
  let URL = `api${CANCEL_ORDER}`;

  if (query) {
    const { orderId, reasonId } = query;
    URL = `${URL}?orderId=${orderId}&reasonId=${reasonId}`;
  }

  return axiosInstance.put(URL);
};

export const deliveredOrderQtyDetail = (query = undefined) => {
  let URL = `api${Delivered_ORDER_QUANTITY_DETAIL}`;

  if (query) {
    const { orderId } = query;
    URL = `${URL}?OrderId=${orderId}`;
  }

  return axiosInstance.get(URL);
};

export const getPartialDeliveryReasons = () =>
  axiosInstance.get(`api${PARTIAL_DELIVERY_REASONS}`);

export const onSpotReturnItem = (payload) =>
  axiosInstance.post(`api${ON_SPOT_RETURN_ITEM}`, payload);

// Seller DEDs
export const DEDNewOrders = (params = undefined) =>
  axiosInstance.get(`api${DED_NEW_ORDERS}`, { params });

export const DEDPickupOrders = (params = undefined) =>
  axiosInstance.get(`api${DED_PICKUP_ORDERS}`, { params });

export const DEDPickedupSKUs = (params = undefined) =>
  axiosInstance.get(`api${DED_PICKED_UP_SKUs}`, { params });

export const DEDReturnOrders = (params = undefined) =>
  axiosInstance.get(`api${DED_RETURN_ORDERS}`, { params });

export const DEDReturnSKUs = (params = undefined) =>
  axiosInstance.get(`api${DED_RETURN_SKUs}`, { params });

export const DEDReturnReceipt = (params = undefined) =>
  axiosInstance.get(`api${DED_RETURN_RECEIPT}`, { params });

export const DEDPastOrders = (params = undefined) =>
  axiosInstance.get(`api${DED_PAST_ORDERS}`, { params });

export const DEDPickupSKUs = (params = undefined) =>
  axiosInstance.get(`api${DED_PICKUP_SKUs}`, { params });

export const DEDPickupReceipt = (params = undefined) =>
  axiosInstance.get(`api${DED_PICKUP_RECEIPT}`, { params });
