import { useSelector } from "react-redux";
import CustomizedSlider from "../PrettoSlider";
import { fetchAssignedBadge, fetchBadgeSpecificColors } from "../../constants";
import { getRemainingDaysInMonth } from "../../../../Utilities";
import styles from "./BadgeCard.module.scss";

const BadgeCard = ({ badgeDetails, idx }) => {
  const { badge_id, badge_name, metric, reward, totalNMV, percentageReward } =
    badgeDetails;
  const { profileDetails } = useSelector((state) => state.profileDetails);
  const badgeTheme = fetchBadgeSpecificColors(badge_id);

  const Text = ({ text, modifiedValue, target_value }) => (
    <p
      // style={{
      //   color: modifiedValue > target_value ? "#52af77" : "#f34964", // ?
      // }}
      style={{
        color: modifiedValue >= target_value ? "#52af77" : "#f34964", //?
      }}
    >
      {text}
    </p>
  );

  return (
    <div className={styles.BadgeContainer}>
      <div
        className={styles.Header}
        style={{
          backgroundColor: idx === 0 ? badgeTheme.backgroundColor : "#ededed",
        }}
      >
        <img
          alt="badge"
          src={fetchAssignedBadge(badge_id)}
          className={styles.BadgeIcon}
        />
        <div
          className={styles.Body}
          style={{
            ...(idx !== 0 && { padding: "0rem 1rem 2rem 1rem" }),
          }}
        >
          <div className={styles.Section}>
            {idx === 0 ? (
              <p style={{ fontWeight: "500", color: badgeTheme.color }}>
                PERFORMANCE FOR OCTOBER
              </p>
            ) : (
              <p style={{ color: "#848181", fontSize: "20px" }}>
                POSSIBLE PERFORMANCE
              </p>
            )}
            <p
              className={styles.BadgeTitle}
              style={{
                color: idx === 0 ? badgeTheme.color : "#102947",
                margin: "0px 0px 10px 0px",
              }}
            >
              {badge_name ?? ""} Badge
            </p>
            {idx === 0 && (
              <>
                <p style={{ fontWeight: "500", color: badgeTheme.color }}>
                  Your reward will be fixed in
                </p>
                <p className={styles.RemainingDays}>
                  {getRemainingDaysInMonth()} Days
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.Body}>
        {[4, 5].includes(badge_id) && (
          <div style={{ marginBottom: "1rem" }}>
            <p className={styles.Label}>What Will You Get?</p>
            <p className={styles.Reward}>
              Rs. <strong>{reward}*</strong>
            </p>
            <p className={styles.PercentageReward}>
              <strong className={styles.Percentage}>
                *{percentageReward}%
              </strong>{" "}
              of {profileDetails?.currency ?? ""} <strong>{totalNMV}</strong>{" "}
              (delivered order value)
            </p>
          </div>
        )}
        <p className={styles.Label}>WHAT TO DO?</p>
        {metric?.length > 0 &&
          metric.map(({ metric_name, message, value, target_value }) => {
            const modifiedValue = parseFloat(value.replace("%", ""));

            return (
              <div key={metric_name} className={styles.PerformanceMetrics}>
                <div className={styles.MetricsHeader}>
                  <Text
                    text={metric_name}
                    modifiedValue={modifiedValue}
                    target_value={target_value}
                  />
                  <Text
                    text={value}
                    modifiedValue={modifiedValue}
                    target_value={target_value}
                  />
                </div>
                <CustomizedSlider value={value} targetValue={target_value} />
                <p className={styles.MetricsMessage}>{message}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BadgeCard;
