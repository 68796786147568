import { Typography } from "@material-ui/core";
import styles from "./NotFound.module.scss";

const NotFoundMsg = ({ msg = "Nothing to show", variant = "h5" }) => (
  <div className={styles.NotFoundMsg}>
    <Typography color="primary" variant={variant}>
      {msg}
    </Typography>
  </div>
);

export default NotFoundMsg;
