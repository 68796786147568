import { useState, useEffect, useRef } from "react";
import { cellToLatLng } from "h3-js";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import H3Mapped from "../../../components/H3Mapped";
import { setSnackbarDetails } from "../../../features/snackbar/snackbarSlice";
import {
  getAreaLeadTimes,
  addSellerLeadTimesArea,
  toggleHexagons,
} from "../../../APIs/area-lead-time";
import styles from "../Areas&Times.module.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DeliveryAreas = () => {
  const [layer, setLayers] = useState([]);
  const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState(false);
  // const [coordinates, setCoordinates] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedAreas = useRef([]);
  const unselectedAreas = useRef([]);
  let initialCords = useRef(undefined);
  const isAllAreaSelected = useRef(false);
  const classes = useStyles();

  const history = useHistory();
  const { state } = useLocation();
  const { cityId } = useParams();
  const dispatch = useDispatch();

  const goTo = (path) => history.push(path);

  if (state?.latLng) {
    const { lat, lng } = JSON.parse(state.latLng);
    initialCords.current = [lat, lng];
  }

  const onHexClick = (isSelected, _H3index, _initialState, id) => {
    const areaDetails = {
      id,
      h3IndexId: id,
      days: state?.days,
      leadTimeId: state?.leadTimeId,
    };

    if (isSelected) {
      areaDetails["isSelected"] = isSelected;
      selectedAreas.current.push(areaDetails);
    } else {
      areaDetails["isSelected"] = isSelected;
      unselectedAreas.current.push(areaDetails);
    }
  };

  const fetchLeadTimes = () => {
    const params = { cityId };

    setOpenLoadingBackdrop(true);
    getAreaLeadTimes(params)
      .then(async (res) => {
        if (res?.status === 200) {
          const { areaLeadTimeResponse, allSelected } = res?.data?.data ?? {};
          const SelectedArr = [];

          await Promise.all(
            areaLeadTimeResponse?.map(
              ({ index, _h3IndexId, id, isSelected, _isActive }) => {
                SelectedArr.push({
                  id,
                  H3index: index,
                  selected: isSelected,
                  //   clickable: !isSelected,
                });
              }
            )
          );

          isAllAreaSelected.current = allSelected ?? false;
          setLayers(SelectedArr);
          setOpenLoadingBackdrop(false);
        }
      })
      .catch((err) => {
        setOpenLoadingBackdrop(false);
      });
  };

  const submitAreas = () => {
    const payload = [...unselectedAreas.current, ...selectedAreas.current];

    setLoading(true);
    addSellerLeadTimesArea(payload)
      .then((res) => {
        if (res?.status === 201) {
          setLoading(false);

          dispatch(
            setSnackbarDetails({
              open: true,
              message: "Areas Added successfully",
              severity: "success",
              hideDuration: 1000,
            })
          );

          setTimeout(() => history.push(`/delivery/delivery-times`), 1000);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleAllAreas = () => {
    const payload = {
      selectionAction: isAllAreaSelected.current ? 1 : 0,
      leadTimeId: state?.leadTimeId,
      resolution: 8,
    };

    toggleHexagons(payload).then((res) => {
      if (res?.status === 201) {
        if (!isAllAreaSelected.current) {
          history.push("/delivery/delivery-times");
        } else {
          fetchLeadTimes();
        }

        dispatch(
          setSnackbarDetails({
            open: true,
            message: "Updated Successfully",
            severity: "success",
            hideDuration: 1000,
          })
        );
      }
    });
  };

  // const handleCurrentLocation = () => {
  //   navigator.geolocation.getCurrentPosition(({ coords }) => {
  //     const { latitude, longitude } = coords;
  //     setCoordinates([latitude, longitude]);
  //   });
  // };

  useEffect(() => {
    fetchLeadTimes();
  }, []);

  // console.log("coords", coordinates);

  return (
    <div className={styles.DeliveryAreaAndTimeWrapper}>
      <div className={styles.Header}>
        <IconButton
          color="primary"
          onClick={() => goTo("/delivery/delivery-cities")}
          className={styles.BackBtn}
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant={"h2"} color="primary" className={styles.Title}>
          CHOOSE AREAS
        </Typography>
      </div>
      <div style={{ margin: "10px 0px" }}>
        <Typography variant="h4" color="primary">
          Choose areas you will deliver to
        </Typography>
      </div>
      <Backdrop className={classes.backdrop} open={openLoadingBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!openLoadingBackdrop && layer.length > 0 && (
        <>
          <div>
            <div
              style={{
                textAlign: "right",
                marginBottom: "10px",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={handleAllAreas}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {isAllAreaSelected.current
                  ? "Unselect All Areas"
                  : "Select All Areas"}
              </Button>
            </div>
            <H3Mapped
              hexArray={layer}
              onHexClick={onHexClick}
              intialCoordinates={initialCords.current}
              initalZoom={initialCords.current ? 13 : 12}
              // intialCoordinates={coordinates ?? initialCords.current}
              // initalZoom={coordinates ? 15 : initialCords.current ? 13 : 12}
            />
            {/* <div>
              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleAllAreas}
              >
                {isAllAreaSelected.current ? "Unselect All" : "Select All"}
              </Button>
            </div> */}
            {/* <div>
              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleCurrentLocation}
              >
                Pin location
              </Button>
            </div> */}
          </div>
          <div style={{ textAlign: "right", marginTop: "15px" }}>
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={submitAreas}
            >
              Confirm Areas
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryAreas;
