import {
  GET_RETURN_MODES,
  ADD_RETURN_MODE_LOCATION,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const getReturnModes = () => axiosInstance.get(`api${GET_RETURN_MODES}`);

export const addReturnModeLocation = (payload) =>
  axiosInstance.post(`api${ADD_RETURN_MODE_LOCATION}`, payload);
