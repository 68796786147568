import { Typography, Switch, Checkbox, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DastgyrLogo from "../../../../assets/images/dastgyr-logo.png";
import styles from "./SKUItemCard.module.scss";
import { truncate } from "../../../../Utilities";

const SKUItemCard = (props) => {
  const {
    idx,
    selectedLists,
    handleSKUVisibility,
    deleteSKU,
    listingType,
    SKUDetails,
  } = props;
  const history = useHistory();

  const editList = (details) => {
    history.push("/listing/edit-listing", { details });
  };

  return (
    <div className={styles.SKUItemContainer}>
      <div className={styles.PrimaryContainer}>
        <div className={styles.ProductImgContainer}>
          <img
            className={styles.ProductImg}
            alt="SKU Item"
            src={
              SKUDetails?.image && JSON.parse(SKUDetails?.image)
                ? JSON.parse(SKUDetails?.image)
                : DastgyrLogo
            }
          />
        </div>
        {listingType === "active" && (
          <div className={styles.RankBadgeContainer}>
            <Typography color="primary" className={styles.RankBadge}>
              Rank: {SKUDetails?.rank ?? "--"}
            </Typography>
          </div>
        )}
      </div>
      <div className={styles.SecondaryContainer}>
        <div className={styles.ProductDetails}>
          <Typography
            className={styles.ProductLabel}
            variant="h6"
            title={SKUDetails?.name ?? ""}
          >
            {truncate(SKUDetails?.name, 25)}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="secondary"
              checked={SKUDetails?.isSelected ?? false}
              onChange={() => selectedLists(idx)}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleRoundedIcon />}
            />
            {listingType === "active" && (
              <IconButton
                color="secondary"
                onClick={() => editList(SKUDetails)}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={styles.QtyContainer}>
          <div>
            {(listingType === "active" || listingType === "inactive") && (
              <Typography color="secondary" variant="h5">
                Available Qty:{" "}
                {new Intl.NumberFormat("en-US").format(
                  SKUDetails?.remainingInventory ?? 0
                )}
              </Typography>
            )}
            <Typography color="secondary" variant="h5">
              Listed Qty:{" "}
              {new Intl.NumberFormat("en-US").format(SKUDetails?.quantity ?? 0)}
            </Typography>
          </div>
          <div className={styles.Badge}>
            {SKUDetails?.fulfilmentModeId === 4 ? "Dastgyr" : "Store"}
          </div>
        </div>
        <div className={styles.CostContainer}>
          <Typography className={styles.UnitCost} variant="h5">
            Unit Cost:{" "}
            {new Intl.NumberFormat("en-US").format(SKUDetails?.price ?? 0)}
          </Typography>
          {listingType === "active" && (
            <Switch
              checked={SKUDetails?.isVisible ?? false}
              onChange={() => handleSKUVisibility(idx)}
              color="secondary"
            />
          )}
          {(listingType === "inactive" || listingType === "pending") && (
            <IconButton
              className={styles.DeleteSKU}
              onClick={() => deleteSKU(idx)}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default SKUItemCard;
