import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2pdf from "html2pdf.js";
import { formatDateTime } from "../../../Utilities";
import { getSellerInvoice } from "../../../APIs/seller-invoices";
import styles from "./Invoices.module.scss";

const listData = (reciept) => {
  let returndata = "";

  reciept.items
    ? reciept.items.map(
        ({ orderId, skuName, quantity, unitCost, amount, commissionValue }) => {
          returndata += `<tr>
      <th style="padding:5px;border:1px solid #a3a3a3;">${orderId}</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">${skuName}</th>
         <th style="padding:5px;border:1px solid #a3a3a3;">${
           quantity ? quantity : "-"
         }</th>
        <th style="padding:5px;border:1px solid #a3a3a3;">${
          unitCost ? unitCost : "-"
        }</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${
        amount ? amount : "-"
      }</th>
      <th style="padding:5px;border:1px solid #a3a3a3;">${
        commissionValue ? commissionValue : "-"
      }</th>
      </tr>`;
        }
      )
    : (returndata += `<h1></h1>`);

  return returndata;
};

const Invoices = ({ invoice }) => {
  const downloadInvoice = (id) => {
    const query = {
      invoiceId: id,
    };

    getSellerInvoice(query).then((res) => {
      if (res.status === 200) {
        const invoiceDetails = res?.data?.data ?? {};
        const htmlContent = `
  <div style="border:1px solid #FF6C2F;padding: 20px">
  <h1 style="color:#FF6C2F;font-size:30px">Invoices</h1>
    <div>
    <div style="display: flex;width:"100%">
      <div style="color:#a3a3a3;width:"50%">Invoice ID</div>
      <div style="margin-left:40px;width:"50%">${invoiceDetails.id}</div>
      </div>
    <div style="display:flex;margin-top:5px">
      <div style="color:#a3a3a3">Invoice Date</div>
      <div style="margin-left:20px;color:black">${formatDateTime(
        invoiceDetails.deliveredDate
      )}</div>
      </div>
    </div>
<div style="display:flex;justify-content:space-between">
<div style="width:40%">
    <div style="display:flex;margin-top:10px">
    <div style="font-size:12px;width:50%; background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Seller Name</div>
    <div style="width:50%;font-size:12px;padding:10px;border:0.1px solid #c3c3c3">${
      invoiceDetails?.sellerName ?? "-"
    }</div>
    </div>

    <div style="display:flex">
    <div style="font-size:12px;width:50%;background-color:#c3c3c3;justify-content:center;align-items:center;display:flex;border:0.1px solid #ffffff">Seller Mobile</div>
    <div style="font-size:12px;width:50%;padding:10px;border:0.1px solid #c3c3c3">${
      invoiceDetails?.sellerMobile ?? "-"
    }</div>
    </div>
    </div>

   </div> 

  <div style="display: flex; margin-top: 1rem">
    <div style="width: 100%;">
       <table style="width:100%;border-spacing:0;">
        <tr style="background-color: #c3c3c3;border:1px solid #a3a3a3;">
        <th style="padding:5px;border:1px solid #a3a3a3;">Order ID</th>
          <th style="padding:5px;border:1px solid #a3a3a3;">SKU NAME</th>
          <th style="padding:5px;border:1px solid #a3a3a3;">Delivered Qty</th>
          <th style="padding:5px;border:1px solid #a3a3a3;">Order Price</th>
          <th style="padding:5px;border:1px solid #a3a3a3;">Total Value</th>
          <th style="padding:5px;border:1px solid #a3a3a3;">Dastgyr Commission</th>
        </tr>
        ${listData(invoiceDetails)}
        </table>
        <div style="width:100%;display:flex;border:1px solid #a3a3a3">
        <div style="font-weight:bold;margin-left:5px; width:88%;padding:5px;">Total Amount</div>
        <h4 style="padding:5px;text-align:right;margin:0">${
          invoiceDetails?.deliveredAmount ?? "0"
        }</h4>
        </div>
         <div style="width:100%;display:flex;border:1px solid #a3a3a3">
        <div style="font-weight:bold;margin-left:5px; width:88%;padding:5px;">Total Discounts</div>
        <h4 style="padding:5px;text-align:right;margin:0">${
          invoiceDetails?.dastgyrDiscount ?? "0"
        }</h4>
        </div>
        <div style="width:100%;display:flex;border:1px solid #a3a3a3">
        <div style="font-weight:bold;margin-left:5px; width:88%;padding:5px;">Total Commision</div>
        <h4 style="padding:5px;text-align:right;margin:0">${
          invoiceDetails?.commissionAmount ?? "0"
        }</h4>
        </div>
        <div style="width:100%;display:flex;border:1px solid #a3a3a3">
        <div style="font-weight:bold;margin-left:5px; width:88%;padding:5px;">Seller Amount</div>
        <h4 style="padding:5px;text-align:right;margin:0">${
          invoiceDetails?.sellerPaymentAmount ?? "0"
        }</h4>
        </div>
        <h1>Terms & Conditions</h1>
        <div style="display:flex;margin-top:100px;justify-content:space-around">
        <div style="width:40%;border:1px solid black">
        <p style="background-color:#E4E7EB;text-align:center;margin:0;padding:0">Creator Signature</p>
        <p style="text-align:center;font-size:12px;color:#000000">${
          invoiceDetails?.sellerName ?? "-"
        }</p>
        </div>
        <div style="width:40%;border:1px solid black">
        <p style="background-color:#E4E7EB;text-align:center;margin:0;padding:0">Receiver Signature</p>
        <p style="text-align:center;font-size:12px;color:#E4E7EB">Please sign here</p>
        </div>
        </div>
    </div>
  </div>
</div>
  `;

        const element = document.createElement("div");
        element.innerHTML = htmlContent;

        const options = {
          margin: [10, 10, 10, 10],
          filename: "generated_pdf.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, logging: true },
          jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        };

        html2pdf().set(options).from(element).save("Dastgyr-Invoice.pdf");
      }
    });
  };

  return (
    <div className={styles.InvoiceTile}>
      <div className={styles.InvoiceInfo}>
        <p className={styles.Date}>{formatDateTime(invoice.invoiceDate)}</p>
        <p className={styles.Id}>Id: {invoice.id}</p>
      </div>
      <IconButton
        color="primary"
        aria-label="Download invoice"
        onClick={() => downloadInvoice(invoice.id)}
      >
        <GetAppIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default Invoices;
