import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: "0.4rem",
    paddingBottom: "0.4rem",
    paddingLeft: "0.6rem",
    "&:hover": {
      backgroundColor: "rgb(198 182 182 / 10%)",
    },
  },
  button: {
    color: colors.blueGrey[800],
    margin: "7px 0px",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: "none",
    fontSize: "18px",
  },
  icon: {
    color: theme.palette.icon,
    width: "100%",
    height: 24,
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  subComponent: {
    paddingLeft: "2rem",
  },
  SidebarLabel: {
    fontSize: "16px",
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    margin: "7px 0px",
    textTransform: "capitalize",
    "& $icon": {
      color: theme.palette.primary.main,
    },
    width: "100%",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    textDecoration: "none",
    fontSize: "18px",
  },
}));

function SidebarNav(props) {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const location = useLocation();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        return (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Link
              to={page.href}
              className={
                location.pathname.includes(page.href)
                  ? classes.active
                  : classes.button
              }
            >
              <div className={classes.icon}>
                {page.icon}
                <p
                  className={
                    page.parent ? classes.subComponent : classes.SidebarLabel
                  }
                >
                  {page.title}
                </p>
              </div>
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
