import { useState } from "react";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../components/Modal";
import { cancellationReasons } from "../../../../APIs/order";
import styles from "./Modal.module.scss";

const OrderCancellationModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle = "",
  message,
  loading,
  type,
  cancelOrder,
}) => {
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [reasonsLoading, setReasonsLoading] = useState(false);

  const fetchCancellationReasons = () => {
    const query = {};

    if (type === "cancel") {
      query["reportReason"] = false;
    } else if (type === "report") {
      query["reportReason"] = true;
    }

    setReasonsLoading(true);

    cancellationReasons(query)
      .then((res) => {
        if (res?.status === 200) {
          setReasonsLoading(false);
          setReasons(res?.data?.data ?? []);
        }
      })
      .catch((err) => setReasonsLoading(false));
  };

  const handleCancellationReasons = (e) => {
    const reason = Number(e.target.value);
    setSelectedReason(reason);
  };

  const resetModal = () => {
    setReasons([]);
    setSelectedReason(null);
    setReasonsLoading(false);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={() => console.log("Modal closed")}
      modalSize={modalSize}
      onExited={resetModal}
      onEnter={fetchCancellationReasons}
    >
      <DialogTitle title="title">{modalTitle}</DialogTitle>
      <DialogContent title="body">
        <div className={styles.MarkAsModalContainer}>
          <p className={styles.MarkAsAskingMsg}>{message}</p>
          <div className={styles.CancellationReasonContainer}>
            {!reasonsLoading && reasons?.length === 0 && (
              <div style={{ textAlign: "center" }}>
                <Typography color="primary" variant="h6">
                  Nothing to show
                </Typography>
              </div>
            )}
            {reasonsLoading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="primary" />
              </div>
            )}
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedReason}
                onChange={handleCancellationReasons}
              >
                {reasons?.map((reason) => (
                  <FormControlLabel
                    key={reason.id}
                    value={reason.id}
                    label={reason.name}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.MarkAsActionBtnContainer}>
          <Button
            disableElevation
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={onClose}
          >
            No
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={!selectedReason || loading}
            onClick={() => cancelOrder(selectedReason)}
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default OrderCancellationModal;
