import {
  USER_BY_MOBILE,
  GENERATE_OTP,
  VERIFY_OTP,
  REGISTER,
  FORGOT_PASSWORD,
  TERMS_CONDITIONS,
  STATES,
  CITIES,
  ACCOUNT_LOGIN,
  VERTICALS,
  GET_PROFILE_DETAILS,
} from "../Utilities/endpoints";
import axiosInstance from "../app-interceptor/interceptor";

export const userByMobile = (query = undefined) =>
  axiosInstance.get(`api${USER_BY_MOBILE}?username=${query}`);

export const generateOTP = (payload) =>
  axiosInstance.post(`api${GENERATE_OTP}`, payload);

export const verifyOTP = (payload) =>
  axiosInstance.post(`api${VERIFY_OTP}`, payload);

export const accountLogin = (payload) =>
  axiosInstance.post(`api${ACCOUNT_LOGIN}`, payload);

export const registerUser = (payload) =>
  axiosInstance.post(`api${REGISTER}`, payload);

export const forgotPassword = (payload) =>
  axiosInstance.put(`api${FORGOT_PASSWORD}`, payload);

export const getContract = () => axiosInstance.get(`api${TERMS_CONDITIONS}`);

export const getStates = () => axiosInstance.get(`api${STATES}`);

export const getCities = () => axiosInstance.get(`api${CITIES}`);

export const getVerticals = () => axiosInstance.get(`api${VERTICALS}`);

export const getProfileDetails = () =>
  axiosInstance.get(`api${GET_PROFILE_DETAILS}`);
