import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Errors from "./components/Errors";
import { removeSnackbarDetails } from "./features/snackbar/snackbarSlice";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: "100%",
    backgroundColor: "#f4f5f7",
    minHeight: "calc(100vh - 64px)",
  },
}));

const PublicRoutes = ({ component: Component, ...rest }) => {
  const {
    details: { open, message, severity, hideDuration },
  } = useSelector((state) => state.snackbarDetails);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = (_e, reason) => {
    if (reason === "clickaway") return;

    dispatch(
      removeSnackbarDetails({
        open: false,
        message: "",
        hideDuration: 0,
        severity: "",
      })
    );
  };

  return (
    <div className={classes.root}>
      <Route {...rest} render={(props) => <Component {...props} />} />
      <Errors
        open={open}
        message={message}
        close={handleClose}
        hideDuration={hideDuration}
        severity={severity}
      />
    </div>
  );
};

export default PublicRoutes;
