import BadgeCard from "../BadgeCard";
import styles from "./CurrentPerformance.module.scss";

const CurrentPerformance = ({ performanceBadges }) => {
  return (
    <div className={styles.PerformanceBadgesContainer}>
      {performanceBadges?.length > 0 &&
        performanceBadges.map((badge, idx) => (
          <BadgeCard key={idx} badgeDetails={badge} idx={idx} />
        ))}
    </div>
  );
};

export default CurrentPerformance;
