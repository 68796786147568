import { Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import Rating from "../../../components/Rating";
import LinearProgressBar from "../../../components/LinearProgressBar";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/Modal";
import styles from "./Modal.module.scss";

const RatingDetails = ({ title, ratings }) => {
  return (
    <div className={styles.RatingDetails}>
      <Typography variant="h5">{title}</Typography>
      <div className={styles.Ratings}>
        <LinearProgressBar value={(ratings / 5) * 100} />
        <div className={styles.RatingLabel}>
          <Typography variant="h4">{ratings}</Typography>
          <StarIcon style={{ color: "#2A7EBD" }} fontSize="medium" />
        </div>
      </div>
    </div>
  );
};

const SellerRatingModal = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  name,
  ratingDetails,
}) => {
  const {
    orderQualityRate = 0,
    fulfilmentRate = 0,
    overallRating = 0,
  } = ratingDetails;

  return (
    <ModalComponent isOpen={isOpen} handleClose={onClose} modalSize={modalSize}>
      <DialogTitle title="title" onClose={onClose}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.SellerRatingModalContainer}>
          <div className={styles.Header}>
            <Typography variant="h1">{name}</Typography>
            <div className={styles.Ratings}>
              <Rating
                customStyles={{ fontSize: "42px", color: "#2A7EBD" }}
                value={overallRating}
                precision={0.5}
                readOnly={true}
                size="medium"
                max={5}
              />
              <p>
                <strong className={styles.RatingScore}>{overallRating}</strong>
                <span className={styles.TotalRating}>/5</span>
              </p>
            </div>
          </div>
          <RatingDetails title="Order Quality" ratings={orderQualityRate} />
          <RatingDetails title="Fulfillment" ratings={fulfilmentRate} />
        </div>
      </DialogContent>
      <DialogActions title="action"></DialogActions>
    </ModalComponent>
  );
};

export default SellerRatingModal;
