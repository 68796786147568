import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import styles from "./Modal.module.scss";

function DialogTitle({ children, onClose }) {
  return (
    <MuiDialogTitle disableTypography className={styles.dialogTitleContainer}>
      <Typography variant="h6" className={styles.dialogTitle}>
        {children}
      </Typography>

      {onClose && (
        <IconButton
          aria-label="Close"
          className={styles.closeIconBtn}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
}

function DialogContent({ children }) {
  return (
    <MuiDialogContent className={styles.dialogContentContainer}>
      {children}
    </MuiDialogContent>
  );
}

function DialogActions({ children }) {
  return (
    <MuiDialogActions className={styles.dialogActionsContainer}>
      {children}
    </MuiDialogActions>
  );
}

function ModalComponent({
  children,
  isOpen,
  handleClose,
  modalSize,
  onExited,
  onEnter,
}) {
  return (
    <Dialog
      className={styles.modalContainer}
      BackdropProps={{ id: "dialog-backdrop" }}
      PaperProps={{ id: "dialog-border-radius" }}
      onClose={handleClose}
      maxWidth={modalSize}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      TransitionProps={{
        onExited: () => onExited && onExited(),
        onEntered: () => onEnter && onEnter(),
      }}
    >
      {children}
    </Dialog>
  );
}

export { ModalComponent as default, DialogTitle, DialogContent, DialogActions };

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalSize: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  onEnter: PropTypes.func,
};

ModalComponent.defaultProps = {
  modalSize: "md",
  onExited: null,
  onEnter: null,
};
