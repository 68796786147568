import axiosInstance from "../app-interceptor/interceptor";
import { getLocalStorage } from "../Utilities";
import CONSTANT from "../constants";
import {
  PICKUP_SELLER,
  PICKUP_ETA,
  PICKUP_REVIEW,
  RETURN_STOP_REVIEW,
} from "../Utilities/endpoints";

const baseURL = CONSTANT.logisticURL;

const getHeaders = () => {
  const userDetails = getLocalStorage("user-details");

  if (userDetails) {
    return {
      ...axiosInstance.defaults.headers,
      sellerId: userDetails.id,
    };
  }
};

export const pickupSeller = () =>
  axiosInstance.get(`${baseURL}${PICKUP_SELLER}`, {
    headers: getHeaders(),
  });

export const pickupETA = (pickupID) =>
  axiosInstance.get(`${baseURL}${PICKUP_ETA}/${pickupID}`, {
    headers: getHeaders(),
  });

export const pickupReview = (payload) =>
  axiosInstance.post(`${baseURL}${PICKUP_REVIEW}`, payload, {
    headers: getHeaders(),
  });

export const returnStopReview = (payload) =>
  axiosInstance.post(`${baseURL}${RETURN_STOP_REVIEW}`, payload, {
    headers: getHeaders(),
  });
