import { Button } from "@material-ui/core";
import ModalComponent, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../../components/Modal";
import NotFoundMsg from "../../../../components/NotFoundMsg";
import styles from "./PerformanceInfo.module.scss";

const PerformanceInfo = ({
  isOpen,
  onClose,
  modalSize,
  modalTitle,
  performanceDetails,
}) => {
  const Info = ({ info: { name, description } }) => (
    <div className={styles.Info}>
      <p className={styles.Label}>{name}</p>
      <p className={styles.Description}>{description}</p>
    </div>
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      modalSize={modalSize}
      onExited={() => {}}
      onEnter={() => {}}
    >
      <DialogTitle title="title" onClose={onClose}>
        {modalTitle}
      </DialogTitle>
      <DialogContent title="body">
        <div className={styles.PerformanceInfoContainer}>
          {performanceDetails && Object.keys(performanceDetails).length > 0 ? (
            <>
              {performanceDetails.metric_details?.length > 0 &&
                performanceDetails.metric_details.map((metric, idx) => (
                  <Info key={idx} info={metric} />
                ))}
              <p className={styles.FAQs}>FAQs</p>
              {performanceDetails.faqs?.length > 0 &&
                performanceDetails.faqs.map((faq, idx) => (
                  <Info key={idx} info={faq} />
                ))}
            </>
          ) : (
            <NotFoundMsg msg="No data available" variant="h4" />
          )}
        </div>
      </DialogContent>
      <DialogActions title="action">
        <div className={styles.PerformanceInfoActionBtn}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </ModalComponent>
  );
};

export default PerformanceInfo;
